define("shared/mixins/product-variation-selection", ["exports", "shared/utils/array-intersect", "ember-concurrency", "ember-data"], function (_exports, _arrayIntersect, _emberConcurrency, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.available_variations_task_computed_property = void 0;
  _exports.product_variation_filter = product_variation_filter;

  function product_variation_filter(variations, selected_variation_id, allow_disabled) {
    return variations.filter(function (variation) {
      return allow_disabled || variation.get('enabled') || variation.get('id') === selected_variation_id;
    });
  }

  const available_variations_task_computed_property = (0, _emberConcurrency.task)(function* (order_selection, selected_variant_values) {
    let allow_disabled = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    order_selection = yield order_selection;

    if (Ember.isBlank(order_selection)) {
      return Ember.A();
    }

    let product = yield order_selection.get('product');

    if (Ember.isBlank(product)) {
      return Ember.A();
    }

    selected_variant_values = yield selected_variant_values;
    let product_variation_id = yield order_selection.get('product_variation.id'); // 1st - If we don't have any current selections, then get all available variations

    if (selected_variant_values.length === 0) {
      let product_variations = yield product.get('variations');
      return product_variation_filter(product_variations, product_variation_id, allow_disabled);
    } // 2nd - If we do have selected variant values, then we need to get based on those selections
    else {
      let variations_by_variant_value = yield product.get('variations_by_variant_value');
      let variation_arrays = selected_variant_values.map(variant_value => {
        let variant_value_variations = variations_by_variant_value.findBy('id', variant_value.get('id')) || {
          variations: Ember.A()
        };
        return product_variation_filter(variant_value_variations.variations, product_variation_id, allow_disabled);
      }).compact(); // If we only have one selection, return the first collection

      if (selected_variant_values.length === 1) {
        return variation_arrays[0];
      } else {
        // If we have multiple arrays, then we need to intersect.
        if (variation_arrays.length > 0) {
          return (0, _arrayIntersect.default)(...variation_arrays);
        } // otherwise we had multiple selections but not not multiple results.
        // Something didn't add up, return an empty array


        return Ember.A();
      }
    }
  });
  _exports.available_variations_task_computed_property = available_variations_task_computed_property;

  var _default = Ember.Mixin.create({
    init() {
      this._super(...arguments);

      this.set('selected_variant_values', Ember.A());

      if (Ember.isPresent(this.get('order_selection.product.id')) && Ember.isPresent(this.get('order_selection.product_variation.id')) && this.get('order_selection.product.id') === this.get('order_selection.product_variation.product.id')) {
        this.set('selected_variant_values', this.get('order_selection.product_variation.details').mapBy('variant_value'));
      }
    },

    resolveAvailableVariations: available_variations_task_computed_property.restartable(),
    // computed names in order of use. Some of these properties are used in the product_variation_filter
    available_variations: Ember.computed('selected_variant_values.[]', 'order_selection.product.id', 'order_selection.product.variations_by_variant_value.[]', 'order_selection.product.variations.@each.{enabled,id}', 'order_selection.product_variation.id', function () {
      // No product yet, just return
      return _emberData.default.PromiseArray.create({
        promise: this.get('resolveAvailableVariations').perform(this.get('order_selection'), this.get('selected_variant_values'))
      });
    }),
    // order product configuration
    has_selected_variant_values: Ember.computed.gt('selected_variant_values.length', 0),
    // Order Product Configuration
    first_available_variation: Ember.computed('available_variations.[]', function () {
      let available_variations = this.get('available_variations').toArray();

      if (available_variations.length > 0) {
        return available_variations.objectAt(0);
      }

      return null;
    }),
    actions: {
      // When we are dealing with the product, we need to call this action to perform the correct operations
      set_product() {
        Ember.RSVP.resolve(this.get('order_selection.product')).then(product => {
          // 1st - check if the product exist, if not, clear all the selected variant values
          if (Ember.isBlank(product)) {
            this.get('selected_variant_values').clear();
            return;
          } // 2nd - if we have a product, remove any variant values that don't match product


          let to_remove = Ember.A();
          this.get('selected_variant_values').forEach(variant_value => {
            if (variant_value.get('product.id') !== product.get('id')) {
              to_remove.push(variant_value);
            }
          });
          this.get('selected_variant_values').removeObjects(to_remove);
        });
      },

      // We we are dealing with the variant values, we need to call this action to perform the correct operations
      set_variant_value(variant_value) {
        // 1st - check if they selected a different value for the same variant, if so remove the old one
        let existing = this.get('selected_variant_values').findBy('variant.id', variant_value.get('variant.id')); // Remove if exists

        if (existing) {
          this.get('selected_variant_values').removeObject(existing);
        } // 2nd - Add new value to the collection


        this.get('selected_variant_values').addObject(variant_value); // 4th - Check if we have a product variation to select

        this.get('set_product_variation').perform();
      }

    },
    // Private methods
    set_product_variation: (0, _emberConcurrency.task)(function* () {
      let matched_variations = Ember.A(); // 1st - if we don't have any current selections, just get all product variations

      if (this.get('selected_variant_values').length === 0) {
        matched_variations = yield this.get('order_selection.product.variations');
        matched_variations = matched_variations.toArray();
      } // 2nd - get all the variations for the current selected_variant_values
      else {
        let variations_by_variant_value = yield this.get('order_selection.product.variations_by_variant_value');
        let variation_arrays = this.get('selected_variant_values').map(variant_value => {
          return (variations_by_variant_value.findBy('id', variant_value.get('id')) || {
            variations: Ember.A()
          }).variations;
        }).compact(); // If we only have one variant_value

        if (this.get('selected_variant_values').length === 1) {
          matched_variations = variation_arrays[0];
        } else {
          matched_variations = (0, _arrayIntersect.default)(...variation_arrays);
        }
      } // If we only have one variation left, then return the first object


      if (matched_variations.length === 1) {
        this.set('order_selection.product_variation', matched_variations.objectAt(0));
      } else {
        this.set('order_selection.product_variation', null);
      }
    }).restartable()
  });

  _exports.default = _default;
});