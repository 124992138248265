define("shared/mixins/route-css", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let routePaths = Ember.A();

  var _default = Ember.Mixin.create({
    activate() {
      this._super(...arguments);

      let root = this.root();
      let cssClass = this.toCssClass();

      if (cssClass.name !== 'application') {
        let existing = routePaths.findBy('key', cssClass.key);

        if (!existing) {
          routePaths.addObject(cssClass);
        }

        root.addClass(this.generateRoutePath());
      }

      root.removeClass('loading-full');
    },

    deactivate() {
      this._super(...arguments);

      let root = this.root();
      let cssClass = this.toCssClass();

      if (cssClass.name !== 'application') {
        root.removeClass(this.generateRoutePath());
        routePaths = routePaths.rejectBy('key', cssClass.key);
      }
    },

    root() {
      let element = (0, _jquery.default)('html');

      if (!element.hasClass('root')) {
        element.addClass('root');
      }

      return element;
    },

    toCssClass() {
      let names = this.routeName.split('.');
      let length = names.length;
      let name = Ember.String.dasherize(names.slice(-1)[0]).replace('/', '-').toLowerCase();
      return {
        name: name,
        position: length,
        key: `${name}:${length}`
      };
    },

    generateRoutePath() {
      return routePaths.sortBy('position').mapBy('name').join('-');
    },

    actions: {
      loading() {
        let root = this.root();
        root.addClass('loading-full');
        return true;
      },

      didTransition() {
        let root = this.root();
        root.removeClass('loading-full');
        return true;
      },

      error() {
        let root = this.root();
        root.removeClass('loading-full');
        return true;
      }

    },

    afterModel() {
      this._super(...arguments);

      let root = this.root();
      root.removeClass('loading-full');
    }

  });

  _exports.default = _default;
});