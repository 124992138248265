define("shared/components/theme-message/component", ["exports", "shared/components/theme-message/template", "ember-concurrency"], function (_exports, _template, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['ui', 'step', 'info', 'message'],
    // Passed in
    template: null,
    order: null,

    init() {
      this._super(...arguments);

      this.get('renderTask').perform();
    },

    renderTask: (0, _emberConcurrency.task)(function* () {
      let order = yield this.get('order');
      let template = yield this.get('template');
      let content = template;

      if (order.get('is_test') !== true) {
        content = yield order.renderTemplate(template);
      }

      this.set('content', content);
    }),

    didReceiveAttrs() {
      this._super(...arguments);

      this.get('renderTask').perform();
    }

  });

  _exports.default = _default;
});