define("shared/components/product-configuration-view/component", ["exports", "shared/components/product-configuration-view/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    // Need to move the tag into the component because the styling is a little crazy
    tagName: ""
  });

  _exports.default = _default;
});