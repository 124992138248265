define("shared/components/product-configuration-variant/component", ["exports", "shared/mixins/product-variation-selection", "shared/components/product-configuration-variant/template", "ember-data"], function (_exports, _productVariationSelection, _template, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: "",
    // Required parameters
    variant: null,
    // Product variations variant in loop
    order_selection: null,
    selected_variant_values: null,
    submitted: false,
    disabled: false,
    allow_disabled: false,

    onChange() {},

    is_submitted_and_invalid: Ember.computed('submitted', 'selected_variant_values.[]', 'variant.id', 'order_selection.validations.isInvalid', 'order_selection.validations.attrs.product_variation.isInvalid', function () {
      if (!this.get('submitted')) {
        return false;
      }

      if (this.get('order_selection.validations.isInvalid')) {
        if (this.get('order_selection.validations.attrs.product_variation.isInvalid')) {
          if (!this.get('selected_variant_values').any(variant_value => variant_value.get('variant.id') === this.get('variant.id'))) {
            return true;
          }
        }
      }

      return false;
    }),
    resolveAvailableVariations: _productVariationSelection.available_variations_task_computed_property.restartable(),
    // Get the variant values that don't match this variant
    available_variations: Ember.computed('selected_variant_values.[]', 'variant.id', 'allow_disabled', 'order_selection.product.id', 'order_selection.product.variations_by_variant_value.[]', 'order_selection.product.variations.@each.{enabled,id}', 'order_selection.product_variation.id', function () {
      let other_selected_variant_values = this.get('selected_variant_values').filter(variant_value => {
        return variant_value.get('variant.id') !== this.get('variant.id');
      });
      return _emberData.default.PromiseArray.create({
        promise: this.get('resolveAvailableVariations').perform(this.get('order_selection'), other_selected_variant_values, this.get('allow_disabled'))
      });
    }),
    changed: Ember._action(function (event) {
      let id = event.target.value;
      let variant_value = this.get('variant.values').findBy('id', id);
      this.onChange(variant_value);
    })
  });

  _exports.default = _default;
});