define("shared/components/quantity-selector/component", ["exports", "shared/components/quantity-selector/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['quantity-selection', 'component'],
    // Passed in attributes
    label: false,
    can_increment: true,
    can_decrement: true,
    actions: {
      decrement() {
        if (!this.get('can_decrement')) {
          return;
        }

        this.get('onDecrement')();
      },

      increment() {
        if (!this.get('can_increment')) {
          return;
        }

        this.get('onIncrement')();
      }

    }
  });

  _exports.default = _default;
});