define("shared/components/quantity-selector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bgWC8RwJ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\",true],[10,\"class\",\"label\"],[8],[0,\"Quantity\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"span\",true],[11,\"class\",[29,[\"decrease \",[28,\"unless\",[[24,[\"can_decrement\"]],\"disabled\"],null]]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"decrement\"],null]],[8],[0,\"\\n  \"],[1,[28,\"inline-svg\",[\"svgs/icon-minus\"],[[\"class\"],[\"svg icon\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"value\"],[8],[1,[22,\"quantity\"],false],[9],[0,\"\\n\"],[7,\"span\",true],[11,\"class\",[29,[\"increase \",[28,\"unless\",[[24,[\"can_increment\"]],\"disabled\"],null]]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"increment\"],null]],[8],[0,\"\\n  \"],[1,[28,\"inline-svg\",[\"svgs/icon-plus\"],[[\"class\"],[\"svg icon\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/quantity-selector/template.hbs"
    }
  });

  _exports.default = _default;
});