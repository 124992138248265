define('ember-prop-types/utils/validators/shape', ['exports', 'ember-prop-types/utils/logger'], function (exports, _logger) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (validators, ctx, name, value, def, logErrors, throwErrors) {
    const typeDefs = def.typeDefs;
    let msg = `Expected property ${name} to match given shape`;
    let shape;
    try {
      shape = JSON.stringify(value, null, ' ');
      msg = `${msg}, but instead got value ${shape}`;
    } catch (e) {}

    if (Ember.typeOf(typeDefs) !== 'object') {
      _logger.default.warn(ctx, 'PropTypes.shape() requires a plain object to be be passed in as an argument', throwErrors);
      return false;
    }

    if (Ember.typeOf(value) !== 'object') {
      _logger.default.warn(ctx, msg, throwErrors);
      return false;
    }

    let valid = Object.keys(typeDefs).every(key => {
      const typeDef = typeDefs[key];

      const objectValue = Ember.get(value, key);
      if (objectValue === undefined) {
        if (!typeDef.required) {
          return true;
        } else {
          if (logErrors) {
            _logger.default.warn(ctx, `Property ${name} is missing required property ${key}`, throwErrors);
          }
          return false;
        }
      }

      return validators[typeDef.type](ctx, `${name}.${key}`, objectValue, typeDef, logErrors, throwErrors);
    });

    valid = valid && Object.keys(value).every(key => {
      const keyIsKnown = key in typeDefs;
      if (!keyIsKnown && logErrors) {
        _logger.default.warn(ctx, `Property ${name} has an unknown key: ${key}`, throwErrors);
      }
      return keyIsKnown;
    });

    if (!valid && logErrors) {
      _logger.default.warn(ctx, msg, throwErrors);
    }

    return valid;
  };
});