define("shipment-tracking/components/bulk-ship-orders-modal/component", ["exports", "manage/mixins/ss-modal", "shipment-tracking/components/bulk-ship-orders-modal/template", "manage/config/environment", "ember-concurrency", "manage/utils/write-html", "jquery"], function (_exports, _ssModal, _template, _environment, _emberConcurrency, _writeHtml, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    layout: _template.default,
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    api_url: _environment.default.urls.api,
    importPreviewUrl: Ember.computed('overwriteLocks', 'sendEmails', function () {
      return `/api/v2/orders/file/shipment_tracking_orders?preview=true&overwrite_lock=${this.get('overwriteLocks')}&send_emails=${this.get('sendEmails')}`;
    }),
    importUrl: Ember.computed('overwriteLocks', 'sendEmails', 'fileId', 'project.id', 'fileName', function () {
      return `/api/v2/orders/file/shipment_tracking_orders?preview=false&overwrite_lock=${this.get('overwriteLocks')}&send_emails=${this.get('sendEmails')}&file_id=${this.get('fileId')}&file_name=${this.get('fileName')}&project_id=${this.get('project.id')}`;
    }),
    current_user: Ember.inject.service('user'),
    mode: 'preview',
    overwriteLocks: false,
    sendEmails: false,
    projectEmailTemplate: null,
    sampleOrderId: null,
    sampleShipment: null,

    init() {
      this._super(...arguments);

      this.get('fetchProjectEmailTask').perform();
    },

    fetchProjectEmailTask: (0, _emberConcurrency.task)(function* () {
      let projectEmail = yield this.get('store').query('project-email', {
        filter: {
          project: this.get('project.id'),
          template: 'shipment-tracking-confirmation'
        }
      });
      this.set('projectEmailTemplate', projectEmail.get('firstObject'));
    }),

    processJobResult(result) {
      this.set('error', null);
      this.set('jobId', result.data.id);

      if (result.meta) {
        this.set('fileId', result.meta.file_id);
        this.set('fileName', result.meta.file_name);
      }
    },

    processError(xhr) {
      this.set('jobId', null);

      if (xhr.payload && xhr.payload.errors) {
        this.set('error', xhr.payload.errors.map(error => error.detail || error.title).join(', '));
      } else {
        this.set('error', xhr.message || 'The upload failed');
      }
    },

    previewTask: (0, _emberConcurrency.task)(function* () {
      let email = yield this.get('projectEmailTemplate');
      let orderId = yield this.get('sampleOrderId');
      let trackingData = yield this.get('sampleShipment');
      let data = yield this.get('ajax').postModel(this.get('project'), 'preview_email', {
        data: {
          order_id: orderId,
          project_email_id: email.get('id'),
          subject: email.get('subject'),
          body: email.get('body'),
          shipment: trackingData
        }
      });
      this.set('mode', 'preview-email');
      Ember.run.scheduleOnce('afterRender', this, function () {
        _writeHtml.default.call(this, 'html', data.html);

        _writeHtml.default.call(this, 'text', data.text);
      });
    }),
    actions: {
      cancel() {
        this.closeModal();
      },

      uploadSuccess(result) {
        this.processJobResult(result);
        this.set('mode', 'preview');
      },

      uploadError(xhr) {
        this.processError(xhr);
      },

      jobFailed(error) {
        this.processError({
          message: error.message
        });
      },

      jobComplete(job) {
        if (job.get('extra.order_id')) {
          this.set('sampleOrderId', job.get('extra.order_id'));
        }

        if (job.get('extra.shipment')) {
          this.set('sampleShipment', job.get('extra.shipment'));
        }
      },

      switch_tab(tab) {
        (0, _jquery.default)(`[data-tab]`, this.element).removeClass('active');
        (0, _jquery.default)(`[data-tab=${tab}]`, this.element).addClass('active');
      },

      processNow() {
        this.set('jobId', null);
        this.get('ajax').post(this.get('importUrl')).then(result => {
          this.processJobResult({
            data: {
              id: result.data.id
            }
          });
          this.set('mode', 'live');
        }).catch(xhr => {
          this.processError(xhr);
        });
      }

    }
  });

  _exports.default = _default;
});