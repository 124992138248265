define("shared/components/product-configuration-image/component", ["exports", "shared/components/product-configuration-image/template", "shared/utils/computed-default-properties"], function (_exports, _template, _computedDefaultProperties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // This component assumes that the product_mapping is to a product or question. Not an option
  // We want to minimize extra work checking every single option, but narrowing down focus
  var _default = Ember.Component.extend({
    // Component class names are in template
    tagName: "",
    layout: _template.default,
    image_size: 200,
    mobile_image_size: 230,
    image_disabled: false,
    show_image: Ember.computed('image_disabled', 'order_selection.product.image_path', 'order_selection.product.variations.@each.image_path', function () {
      if (this.get('image_disabled')) {
        return false;
      } // Product Image


      if (Ember.isPresent(this.get('order_selection.product.image_path'))) {
        return true;
      } // Product Variation Image


      if ((this.get('order_selection.product.variations') || []).any(item => Ember.isPresent(item.get('image_path')))) {
        return true;
      } // TODO: Add product question images, and product question choices images


      return false;
    }),
    style: Ember.computed('image_size', function () {
      return Ember.String.htmlSafe(`width: ${this.get('image_size')}px;`);
    }),
    default_product_image_path: (0, _computedDefaultProperties.default)('order_selection.product_variation.image_path', 'order_selection.product.image_path'),
    image_path: Ember.computed('has_selected_variant_values', 'first_available_variation.image_path', 'default_product_image_path', 'order_selection.product_variation.id', function () {
      // If we have an image url, then use that
      if (Ember.isPresent(this.get('order_selection.product_variation.id'))) {
        return this.get('default_product_image_path');
      }

      if (this.get('has_selected_variant_values') && Ember.isPresent(this.get('first_available_variation.image_path'))) {
        return this.get('first_available_variation.image_path');
      } // TODO: Add product question images, and product question choices images


      return this.get('default_product_image_path');
    })
  });

  _exports.default = _default;
});