define("shared/tests/helpers/create-company", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    var company = server.create('company', {
      name: 'The Company'
    });
    return company;
  }
});