define("shared/components/product-configuration-variant/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tsaRzgh0",
    "block": "{\"symbols\":[\"variant_value\"],\"statements\":[[7,\"select\",false],[12,\"class\",[29,[\"ui dropdown product-configuration-variant component \",[28,\"if\",[[24,[\"is_submitted_and_invalid\"]],\"error\"],null]]]],[3,\"on\",[\"change\",[28,\"action\",[[23,0,[]],\"changed\"],null]]],[8],[0,\"\\n\\n  \"],[7,\"option\",true],[10,\"selected\",\"selected\"],[10,\"value\",\"\"],[10,\"disabled\",\"disabled\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"variant\",\"use_inline_name\"]]],null,{\"statements\":[[0,\"      Select \"],[1,[28,\"a-or-an\",[[24,[\"variant\",\"name\"]]],null],false],[0,\" \"],[1,[24,[\"variant\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      Select an option\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"variant\",\"values_sorted\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"product-configuration-variant-item\",null,[[\"variant_value\",\"order_selection\",\"available_variations\",\"selected_variant_values\"],[[23,1,[]],[24,[\"order_selection\"]],[24,[\"available_variations\"]],[24,[\"selected_variant_values\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/product-configuration-variant/template.hbs"
    }
  });

  _exports.default = _default;
});