define("shared/mixins/address-state-selection", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // Entity can be project address or order address
    verifyStateTask: (0, _emberConcurrency.task)(function* (entity) {
      entity = yield entity; // If we don't have a country return, they have to have a country to continue
      // Without a country, no state dropdown is present

      let country = yield entity.get('country');

      if (Ember.isBlank(country)) {
        return;
      } // Use multiple places, resolve here


      let state = yield entity.get('state'); ////////////////////////////////////
      // Country WITHOUT states
      ////////////////////////////////////

      if (country.get('has_states') !== true) {
        // The country doesn't have states, if we have a state, we need to clear it and set the text
        // We are assumming is we have both, the relationship was more valid
        if (Ember.isPresent(state)) {
          entity.set('state_text', state.get('name'));
          entity.set('state', null);
        }

        return;
      } ////////////////////////////////////
      // Country WITH states, try to match it up
      ////////////////////////////////////


      let states = yield country.get('states');

      if (Ember.isPresent(state)) {
        // Try to match existing state within country.
        if (states.any(s => s.get("id") === state.get("id")) === false) {
          // state doesn't belong to country, clear (i.e. going from Canada to USA)
          entity.set('state', null);
        } // reset the state_text, country has state and shouldn't have text


        if (Ember.isPresent(entity.get('state_text'))) {
          entity.set('state_text', null);
        }

        return;
      } ////////////////////////////////////
      // We didn't have a state relationship, try to get state from state_text
      ////////////////////////////////////


      let state_text = entity.get('state_text'); // Clear the state to null, ensure nothing is in response

      if (Ember.isBlank(state_text)) {
        return;
      } // Find the name by name or abbr


      let matched_state = states.find(s => {
        return s.get('name').trim().localeCompare(state_text.trim(), 'en', {
          sensitivity: 'base'
        }) === 0 || s.get('abbr').trim().localeCompare(state_text.trim(), 'en', {
          sensitivity: 'base'
        }) === 0;
      }); // Either way, reset fields for country that has states

      entity.set('state', matched_state); // null or found

      entity.set('state_text', null);
    })
  });

  _exports.default = _default;
});