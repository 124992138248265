define("shared/components/order-shipping-total/component", ["exports", "shared/components/order-shipping-total/template", "shared/utils/computed-coalesce"], function (_exports, _template, _computedCoalesce) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: "",
    shipping_expanded: false,
    can_expand: true,
    forced_calculated_shipping_costs: null,
    calculated_shipping_costs: (0, _computedCoalesce.default)('forced_calculated_shipping_costs', 'calculated.all_shipping_costs', 'order.all_shipping_costs'),
    order_lines_with_shipping: Ember.computed.filter('order.lines_not_deleted.@each.prices_shipping_amount_cents', function (order_line) {
      return order_line.get('prices.shipping.amount_cents') !== 0;
    }),
    show: Ember.computed('calculated_shipping_costs', 'order_lines_with_shipping.[]', function () {
      if (Ember.isPresent(this.get('calculated_shipping_costs')) && this.get('calculated_shipping_costs') !== 0) {
        return true;
      }

      if (this.get('order_lines_with_shipping.length') > 0) {
        return true;
      }

      return false;
    }),
    actions: {
      toggle_shipping() {
        if (this.get('can_expand') === false) {
          return;
        }

        this.toggleProperty('shipping_expanded');
      }

    }
  });

  _exports.default = _default;
});