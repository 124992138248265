define("shared/mixins/prices", ["exports", "shared/utils/monetize"], function (_exports, _monetize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Price = Ember.Object.extend({
    amount: (0, _monetize.default)('amount_cents', 'currency'),
    amount_per_quantity: (0, _monetize.default)('amount_per_quantity_cents', 'currency')
  });

  const computedPriceData = function (type, currency_field) {
    return Ember.computed('price_data.prices.@each.type', currency_field, function () {
      let price = (this.get('price_data.prices') || Ember.A()).findBy('type', type);

      if (Ember.isBlank(price)) {
        let currency = this.get(currency_field);
        return Price.create({
          type: type,
          amount_cents: 0,
          amount_per_quantity_cents: 0,
          currency: currency // Ignore reference, not really relevant

        });
      }

      if (Ember.isBlank(price.get('currency'))) {
        price.set('currency', this.get(currency_field));
      }

      return price;
    });
  };

  const computedLiveData = function (type, currency_field) {
    let live_amount = `live_${type}_amount_cents`;
    let live_amount_per_quantity = `live_${type}_amount_per_quantity_cents`;
    return Ember.computed(live_amount, live_amount_per_quantity, currency_field, function () {
      let currency = this.get(currency_field);
      return Price.create({
        type: type,
        amount_cents: this.get(live_amount) || 0,
        amount_per_quantity_cents: this.get(live_amount_per_quantity) || 0,
        currency: currency // Ignore reference, not really relevant

      });
    });
  };

  const computedData = function (type) {
    let saved = `saved_${type}`;
    let live = `live_${type}`;
    return Ember.computed('price_data.{is_generated,is_fixed,is_empty,live}', 'price_data.settings.{product,additional,shipping}', 'order.is_completed', saved, live, function () {
      // If its a generated line, it will always be saved
      if (this.get('price_data.is_generated')) {
        if (this.get('price_data.live') === true) {
          return this.get(live);
        }

        return this.get(saved);
      } // Next look at fixed


      if (this.get('price_data.is_fixed')) {
        if (this.get(`price_data.settings.${type}`) === 'live') {
          return this.get(live);
        }

        return this.get(saved);
      } // Finally look at default check


      if (this.get('order.is_completed')) {
        // We have completed great, also verify we have price data
        if (this.get('price_data.is_empty') === false) {
          return this.get(saved);
        }
      }

      return this.get(live);
    });
  }; // Properties should match up to api property names, and in order if possible


  const Prices = Ember.ObjectProxy.extend({
    order: Ember.computed('content.order.id', function () {
      // If the order is a relationship, then return it
      if (Ember.isPresent(this.get('content.order.id'))) {
        return this.get('content.order');
      } // Otherwise this is the order


      return this.get('content');
    }),
    currency: Ember.computed('order.currency', 'content.project.currency', function () {
      if (Ember.isPresent(this.get('order.currency'))) {
        return this.get('order.currency');
      }

      if (Ember.isPresent(this.get('content.project.currency'))) {
        return this.get('content.project.currency');
      }

      return null;
    }),
    // TODO test if we need to track all properties or just type
    // Saved calculations
    saved_product: computedPriceData('product', 'currency'),
    saved_product_vat: computedPriceData('product_vat', 'currency'),
    saved_additional: computedPriceData('additional', 'currency'),
    saved_shipping: computedPriceData('shipping', 'currency'),
    saved_tax: computedPriceData('tax', 'currency'),
    // Live calculations
    live_product: computedLiveData('product', 'currency'),
    live_product_vat: computedLiveData('product_vat', 'currency'),
    live_additional: computedLiveData('additional', 'currency'),
    live_shipping: computedLiveData('shipping', 'currency'),
    live_tax: computedLiveData('tax', 'currency'),
    // Computed
    // Takes the two and decides to show saved or live based on approved on
    product: computedData('product'),
    product_vat: computedData('product_vat'),
    product_vat_category: computedData('product_vat_category'),
    additional: computedData('additional'),
    shipping: computedData('shipping'),
    tax: computedData('tax')
  });

  var _default = Ember.Mixin.create({
    init() {
      this._super(...arguments);

      this.set('prices', Prices.create({
        content: this
      }));
    }

  });

  _exports.default = _default;
});