define("shared/tests/helpers/authenticate-customer", ["exports", "shared/tests/helpers/create-customer", "ember-simple-auth/test-support"], function (_exports, _createCustomer, _testSupport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(project) {
    const customer = (0, _createCustomer.default)(project, 'person@example.com');
    (0, _testSupport.authenticateSession)({
      customer_id: customer.id
    });
    return customer;
  }
});