define("shipment-tracking/components/ship-modal/component", ["exports", "manage/mixins/ss-modal", "shipment-tracking/components/ship-modal/template"], function (_exports, _ssModal, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    layout: _template.default,
    actions: {
      approve() {
        this.set('error', null);
        this.get('order').lock('ship').catch(error => this.set('error', error)).then(() => this.closeModal());
      }

    }
  });

  _exports.default = _default;
});