define("order-printing/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.create({
    name: 'order-printing',

    order_actions(session) {
      return Ember.computed('order.{id,approved_on}', function () {
        const config = Ember.getOwner(this).resolveRegistration('config:environment');
        let actions = [];

        if (Ember.isPresent(this.get('order.approved_on'))) {
          actions.push(Ember.Object.create({
            title: 'Invoice',
            description: 'Generate a PDF invoice for this order. Best for an easy display of everything in the order for you or the customer.',
            icon: 'file pdf outline',
            click: function
              /* callback */
            () {
              window.location = `${config.urls.api}/export/order-invoice/${this.get('order.id')}?token=${session.get('data.authenticated.token')}`;
            }
          }));
          actions.push(Ember.Object.create({
            title: 'Picklist',
            description: 'Generate a PDF picklist for this order. Best for easy picking and packing of selections.',
            icon: 'file pdf outline',
            click: function
              /* callback */
            () {
              window.location = `${config.urls.api}/export/order-picklist/${this.get('order.id')}?token=${session.get('data.authenticated.token')}`;
            }
          }));
        }

        return actions;
      });
    },

    segment_actions() {
      return Ember.computed('segment.{id,project}', function () {
        const config = Ember.getOwner(this).resolveRegistration('config:environment');
        let actions = [];
        actions.push(Ember.Object.create({
          title: 'Invoice',
          icon: 'file pdf outline',
          click: function (callback) {
            this.send('open_modal', 'companies/company/projects/project/segments/generate-export-modal', {
              project: this.get('segment.project'),
              export: {
                name: 'Order Invoice',
                export_type: 'order_invoice',
                format_type: 'pdf'
              },
              base_url: `${config.urls.api}/export/email`,
              filters: {
                project_segment_id: this.get('segment.id')
              },
              closing: function () {
                if (callback && typeof callback === 'function') {
                  callback();
                }
              }
            });
          }
        }));
        actions.push(Ember.Object.create({
          title: 'Picklist',
          icon: 'file pdf outline',
          click: function (callback) {
            this.send('open_modal', 'companies/company/projects/project/segments/generate-export-modal', {
              project: this.get('segment.project'),
              export: {
                name: 'Order Picklist',
                export_type: 'order_picklist',
                format_type: 'pdf'
              },
              base_url: `${config.urls.api}/export/email`,
              filters: {
                project_segment_id: this.get('segment.id')
              },
              closing: function () {
                if (callback && typeof callback === 'function') {
                  callback();
                }
              }
            });
          }
        }));
        return actions;
      });
    }

  });

  _exports.default = _default;
});