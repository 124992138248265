define("shared/components/sized-image/component", ["exports", "shared/mixins/mobile-detection", "shared/components/sized-image/template", "shared/utils/image", "shared/utils/logger", "jquery"], function (_exports, _mobileDetection, _template, _image, _logger, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_mobileDetection.default, {
    layout: _template.default,
    tagName: "a",
    width: null,
    height: null,
    mobile_width: null,
    mobile_height: null,
    // Show default no product image
    show_default: true,
    classNames: ['sized-image', 'component'],
    classNameBindings: ['showing_default'],
    attributeBindings: ['href', 'title', // Passed in
    'style'],

    get_size(property) {
      var value = this.get(property);

      if (Ember.isBlank(value)) {
        return 0;
      } else {
        value = parseInt(value, 10);

        if (isNaN(value)) {
          return 0;
        } else {
          return value;
        }
      }
    },

    init() {
      this._super(...arguments);

      if (this.get_size('width') === 0) {
        (0, _logger.logger_error)('Sized image width cannot be blank');
      }

      if (this.get_size('height') === 0) {
        (0, _logger.logger_error)('Sized image height cannot be blank');
      }
    },

    image_src: (0, _image.default)('image_path'),
    show_minimal: Ember.computed('width', 'height', 'mobile_width', 'mobile_height', 'is_mobile', function () {
      if (this.get('is_mobile') && this.get_size('mobile_width') && this.get_size('mobile_height')) {
        return this.get('mobile_width') <= 70 && this.get('mobile_height') <= 70;
      }

      return this.get('width') <= 70 && this.get('height') <= 70;
    }),
    disable_mobile_detection: Ember.computed('width', 'height', 'mobile_width', 'mobile_height', function () {
      if (this.get_size('mobile_width') === 0 || this.get_size('mobile_height') === 0) {
        return true;
      }

      if (this.get_size('width') === this.get_size('mobile_width') && this.get_size('height') === this.get_size('mobile_height')) {
        return true;
      }

      return false;
    }),
    showing_default: Ember.computed('show_default', 'image_path', function () {
      if (!this.get('show_default')) {
        return Ember.String.htmlSafe();
      }

      if (Ember.isPresent(this.get('image_path'))) {
        return Ember.String.htmlSafe();
      }

      return Ember.String.htmlSafe("default");
    }),
    href: Ember.computed('image_src', function () {
      if (Ember.isBlank(this.get('image_src'))) {
        return Ember.String.htmlSafe("javascript:void(0)"); // jshint ignore:line
      }

      return Ember.String.htmlSafe(this.get('image_src'));
    }),
    style: Ember.computed('image_path', 'show_default', 'width', 'height', 'mobile_width', 'mobile_height', 'is_mobile', function () {
      // If we  aren't showing the default, then hide
      if (Ember.isBlank(this.get('image_path'))) {
        if (!this.get('show_default')) {
          return Ember.String.htmlSafe('display: none');
        }
      } // determine size based on mobile size


      if (this.get('is_mobile') && this.get_size('mobile_width') && this.get_size('mobile_height')) {
        return Ember.String.htmlSafe(`width: ${this.get_size('mobile_width')}px; height: ${this.get_size('mobile_height')}px; min-width: ${this.get_size('mobile_width')}px; min-height: ${this.get_size('mobile_height')}px;`);
      } else {
        return Ember.String.htmlSafe(`width: ${this.get_size('width')}px; height: ${this.get_size('height')}px; min-width: ${this.get_size('width')}px; min-height: ${this.get_size('height')}px;`);
      }
    }),

    thumbnail(image_path) {
      let image_width = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 100;
      let image_height = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 100;

      if (Ember.isBlank(image_path)) {
        return null;
      }

      let config = Ember.getOwner(this).resolveRegistration('config:environment');
      let host = config.imgix.host;
      let image_src = (0, _image.generateImageSrc)(host, image_path, {
        w: image_width,
        h: image_height,
        auto: 'compress,format',
        fit: 'crop',
        crop: 'top'
      });
      return image_src;
    },

    wrapper_style: Ember.computed('image_path', 'width', 'height', 'mobile_width', 'mobile_height', function () {
      let image_path = this.get('image_path');

      if (Ember.isBlank(image_path)) {
        return Ember.String.htmlSafe();
      }

      var width = this.get_size('width');
      var height = this.get_size('height');
      var mobile_width = this.get_size('mobile_width');
      var mobile_height = this.get_size('mobile_height');
      var image_width = Math.max(width, mobile_width);
      var image_height = Math.max(height, mobile_height);
      let image_src = this.thumbnail(image_path, image_width, image_height);
      return Ember.String.htmlSafe('background-image: url(' + image_src + ');');
    }),

    click(event) {
      event.stopPropagation();
      event.preventDefault();
      let image_path = this.get('image_path');

      if (Ember.isBlank(image_path)) {
        return;
      }

      let gallery_image_paths = this.get('gallery_image_paths');

      if (gallery_image_paths == null) {
        gallery_image_paths = [];
      }

      if (!Array.isArray(gallery_image_paths)) {
        if (gallery_image_paths.toArray != null) {
          gallery_image_paths = gallery_image_paths.toArray();
        }
      }

      let images = [];
      images.push({
        src: this.get('image_src'),
        type: 'image',
        opts: {
          thumb: this.thumbnail(image_path)
        }
      });
      let config = Ember.getOwner(this).resolveRegistration('config:environment');
      let host = config.imgix.host;

      for (let i = 0; i < gallery_image_paths.length; i++) {
        let gallery_image_path = gallery_image_paths[i];
        images.push({
          src: (0, _image.generateImageSrc)(host, gallery_image_path, {
            auto: 'compress,format'
          }),
          type: 'image',
          opts: {
            thumb: this.thumbnail(gallery_image_path)
          }
        });
      }

      _jquery.default.fancybox.open(images, {
        loop: true,
        // arrows: false,
        // infobar: false,
        buttons: ['zoom', 'close'],
        thumbs: {
          autoStart: true,
          axis: 'x'
        }
      });
    }

  });

  _exports.default = _default;
});