define('ember-prop-types/utils/validators/instance-of', ['exports', 'ember-prop-types/utils/logger'], function (exports, _logger) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (ctx, name, value, def, logErrors, throwErrors) {
    const type = def.typeDef;
    const valid = value instanceof type;

    if (!valid && logErrors) {
      const typeString = type.toString();
      const nameOfTypeMatch = typeString.match(/function (\w*)/);
      const nameOfType = nameOfTypeMatch ? nameOfTypeMatch[1] : typeString;
      _logger.default.warn(ctx, `Expected property ${name} to be an instance of ${nameOfType} but instead got: ${Ember.typeOf(value)}`, throwErrors);
    }

    return valid;
  };
});