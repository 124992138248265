define("shared/mirage/fixtures/countries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'United States',
    iso3: 'USA',
    iso2: 'US',
    sort_order: 1,
    kickstarter_id: '23424977',
    currency_code: 'USD',
    currency_symbol: '$',
    continent: 'North America',
    city_required: true,
    state_required: true,
    street_required: true,
    postal_code_required: true,
    postal_code_format: "^\\d{5}([ \\-]\\d{4})?$"
  }, {
    id: 2,
    name: 'Canada',
    iso3: 'CAN',
    iso2: 'CA',
    sort_order: 2,
    kickstarter_id: '23424775',
    currency_code: 'CAD',
    currency_symbol: '$',
    continent: 'North America',
    city_required: true,
    state_required: true,
    street_required: true,
    postal_code_required: true,
    postal_code_format: "^[ABCEGHJKLMNPRSTVXY]\\d[ABCEGHJ-NPRSTV-Z][ ]?\\d[ABCEGHJ-NPRSTV-Z]\\d$"
  }, {
    id: 3,
    name: 'United Kingdom',
    iso3: 'GBR',
    iso2: 'GB',
    sort_order: 3,
    kickstarter_id: '23424975',
    currency_code: 'GBP',
    currency_symbol: '£',
    continent: 'Europe'
  }, {
    id: 144,
    name: 'Mexico',
    iso3: 'MEX',
    iso2: 'MX',
    sort_order: 100,
    kickstarter_id: '23424900',
    currency_code: 'MXN',
    currency_symbol: '$',
    continent: 'North America'
  }];
  _exports.default = _default;
});