define("shared/mirage/factories/order-line", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    reward: (0, _emberCliMirage.trait)({
      type: 'reward'
    }),
    extra: (0, _emberCliMirage.trait)({
      type: 'extra'
    }),
    manual: (0, _emberCliMirage.trait)({
      type: 'manual'
    }),
    external: (0, _emberCliMirage.trait)({
      type: 'external'
    })
  });

  _exports.default = _default;
});