define("ember-cli-uncharted-describe-models/utils/generated-model", ["exports", "ember-cli-uncharted-describe-models/utils/model-definitions"], function (_exports, _modelDefinitions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = generatedModel;

  function generatedModel(name) {
    return _modelDefinitions.default[name];
  }
});