define("shared/components/product-configuration-variant-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "klawhi5Q",
    "block": "{\"symbols\":[],\"statements\":[[7,\"option\",true],[11,\"class\",[29,[\"item product-configuration-variant-item component \",[28,\"if\",[[24,[\"disabled\"]],\"disabled\"],null]]]],[11,\"value\",[24,[\"variant_value\",\"id\"]]],[11,\"disabled\",[28,\"if\",[[24,[\"disabled\"]],\"disabled\"],null]],[11,\"selected\",[28,\"contains\",[[24,[\"variant_value\"]],[24,[\"selected_variant_values\"]]],null]],[11,\"data-test-product-variant-value\",[24,[\"variant_value\",\"id\"]]],[8],[0,\"\\n  \"],[1,[24,[\"variant_value\",\"name\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/product-configuration-variant-item/template.hbs"
    }
  });

  _exports.default = _default;
});