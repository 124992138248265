define('ember-prop-types/mixins/prop-types', ['exports', 'ember-get-config', 'ember-prop-types/utils/prop-types'], function (exports, _emberGetConfig, _propTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.PropTypes = exports.helpers = exports.settings = undefined;


  const objectAssign = Object.assign || Ember.assign || Ember.merge;

  const settings = exports.settings = {
    requireComponentPropTypes: Ember.getWithDefault(_emberGetConfig.default, 'ember-prop-types.requireComponentPropTypes', false),
    spreadProperty: Ember.get(_emberGetConfig.default, 'ember-prop-types.spreadProperty'),
    throwErrors: Ember.getWithDefault(_emberGetConfig.default, 'ember-prop-types.throwErrors', false),
    validate: Ember.get(_emberGetConfig.default, 'ember-prop-types.validate'),
    validateOnUpdate: Ember.getWithDefault(_emberGetConfig.default, 'ember-prop-types.validateOnUpdate', false)
  };

  const helpers = exports.helpers = {
    handleError(ctx, message) {
      _propTypes.logger.warn(ctx, message, settings.throwErrors);
    },

    /* eslint-disable complexity */
    validateProperty(ctx, name, def) {
      let value = Ember.get(ctx, name);

      if (value === undefined && settings.spreadProperty) {
        value = Ember.get(ctx, `${settings.spreadProperty}.${name}`);
      }

      if (value === undefined) {
        if (!def.required) {
          return;
        }

        helpers.handleError(ctx, `Missing required property ${name}`);

        return;
      }

      if (def.type in _propTypes.validators) {
        _propTypes.validators[def.type](ctx, name, value, def, true, settings.throwErrors);
      } else {
        helpers.handleError(ctx, `Unknown propType ${def.type}`);
      }
    },
    /* eslint-enable complexity */

    validatePropTypes(ctx) {
      const disabledForEnv = settings.validate === false;
      const isProduction = !_emberGetConfig.default || _emberGetConfig.default.environment === 'production';
      const settingMissing = settings.validate === undefined;

      if (disabledForEnv || settingMissing && isProduction) {
        return;
      }

      const propTypesArray = [].concat(ctx.get('propTypes'));
      propTypesArray.forEach(propType => {
        if (!propType) {
          return;
        }

        Object.keys(propType).forEach(name => {
          const def = (0, _propTypes.getDef)(propType[name]);

          if (def === undefined) {
            helpers.handleError(ctx, `propType for ${name} is unknown`);
            return;
          }

          if (settings.validateOnUpdate) {
            ctx.addObserver(name, ctx, function () {
              if (def.updatable === false) {
                helpers.handleError(ctx, `${name} should not be updated`);
                return;
              }

              helpers.validateProperty(this, name, def);
            });
          }

          helpers.validateProperty(ctx, name, def);
        });
      });
    }
  };

  exports.default = Ember.Mixin.create({
    concatenatedProperties: ['propTypes', 'getDefaultProps'],

    getDefaultProps() {
      // Maintain compatibility for 2.x users calling this._super
      return {};
    },

    init() {
      helpers.validatePropTypes(this);

      // Note defaults is a concatenated property so this is actually an array
      // of getDefaultProps() methods all the way up the inheritance chain
      const defaults = this.getDefaultProps;

      // Keep a record of any properties that came from a getDefaultProps call
      // - let child getDefaultProps functions take precedence over parents
      // - let child getDefaultProps have _access_ to parent values set by getDefaultProps
      // - don't apply defaults for properties set explicitly on the target object
      const defaultedProps = {};

      defaults.forEach(propsFunction => {
        // If for some reason getDefaultProps() isn't a function we'll simply
        // ignore it. In the future we might want to actually assert an error
        // here.
        if (Ember.typeOf(propsFunction) !== 'function') return;

        // Now let's actually call the getDefaultProps() method
        const defaultProps = propsFunction.apply(this);

        // Now iterate defaults and remove any properties that already have
        // values set on this instance _that didn't come from a previous
        // getDefaultProps_
        Object.keys(defaultProps).forEach(key => {
          if (this.get(key) !== undefined && Ember.get(defaultedProps, key) === undefined) {
            delete defaultProps[key];
          }
        });

        // Record the properties that were defaulted
        objectAssign(defaultedProps, defaultProps);

        // Apply the defaults for this layer of the hierarchy immediately
        // @sglanzer 2017-05-29 PR #118 delayed the execution of the setProperties
        // until the end of all the getDefaultProps() calls, which meant that parent
        // layers in the getDefaultProps() hierarchy weren't available for use in
        // child functions.  This broke backwards compatibility
        // concatenated defaults with the results of this method call.
        this.setProperties(defaultProps);
      });

      this._super(...arguments);
    }
  });
  exports.PropTypes = _propTypes.default;
});