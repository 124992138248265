define("shipment-tracking/components/pick-list-modal/component", ["exports", "manage/mixins/ss-modal", "shipment-tracking/components/pick-list-modal/template"], function (_exports, _ssModal, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    layout: _template.default,
    classNames: ['component', 'pick-list-modal', 'fullscreen'],
    closable: true,
    lines_sorted_by: ['sort_order:asc'],
    lines_sorted: Ember.computed.sort('order.lines', 'lines_sorted_by')
  });

  _exports.default = _default;
});