define("shared/components/product-configuration/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s9rWOpb8",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[28,\"product-configuration-image\",null,[[\"order_selection\",\"has_selected_variant_values\",\"first_available_variation\",\"image_disabled\",\"image_size\",\"mobile_image_size\"],[[24,[\"order_selection\"]],[24,[\"has_selected_variant_values\"]],[24,[\"first_available_variation\"]],[24,[\"image_disabled\"]],[24,[\"image_size\"]],[24,[\"mobile_image_size\"]]]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui items\"],[8],[0,\"\\n    \"],[1,[28,\"product-configuration-variations\",null,[[\"order_selection\",\"selected_variant_values\",\"submitted\",\"set_variant_value\"],[[24,[\"order_selection\"]],[24,[\"selected_variant_values\"]],[24,[\"submitted\"]],[28,\"action\",[[23,0,[]],\"set_variant_value\"],null]]]],false],[0,\"\\n\\n    \"],[1,[28,\"product-configuration-questions\",null,[[\"order_selection\",\"submitted\"],[[24,[\"order_selection\"]],[24,[\"submitted\"]]]]],false],[0,\"\\n\\n    \"],[14,1],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/product-configuration/template.hbs"
    }
  });

  _exports.default = _default;
});