define("ember-promise-tools/utils/get-promise-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  // It's assumed if you call this method, it was previously checked that it was a promise
  // and is fulfilled
  function _default(promise) {
    if (Ember.PromiseProxyMixin.detect(promise)) {
      return promise.get('content');
    }

    if (promise instanceof Ember.RSVP.Promise) {
      return promise._result;
    } // Only can get the content for one of the two above


    return null;
  }
});