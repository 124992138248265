define("shared/utils/has-own-property", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(object, property) {
    if (object) {
      if (object.hasOwnProperty && typeof object.hasOwnProperty === "function") {
        return object.hasOwnProperty(property);
      } // Ember 2.9 returns an EmptyObject, which doesn't have hasOwnProperty


      return Object.prototype.hasOwnProperty.call(object, property);
    }

    return false;
  }
});