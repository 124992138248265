define("shared/components/product-configuration-variant-item/component", ["exports", "shared/utils/array-intersect", "shared/components/product-configuration-variant-item/template"], function (_exports, _arrayIntersect, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    // Required arguments
    variant_value: null,
    // The current variant value from the variant loop
    order_selection: null,
    available_variations: null,
    selected_variant_values: null,
    disabled: Ember.computed('order_selection.product.variations_by_variant_value.[]', 'variant_value.id', 'available_variations.[]', function () {
      let variations_by_variant_value = this.get('order_selection.product.variations_by_variant_value');

      if (variations_by_variant_value.get('length') === 0) {
        return "disabled";
      }

      let variant_values = variations_by_variant_value.findBy('id', this.get('variant_value.id'));

      if (Ember.isBlank(variant_values)) {
        return "disabled";
      }

      let variations = variant_values.variations;
      let available_variations = this.get('available_variations').toArray();

      if ((0, _arrayIntersect.default)(variations, available_variations).length === 0) {
        return "disabled";
      }

      return null;
    })
  });

  _exports.default = _default;
});