define("external-sources/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.create({
    name: 'external-sources',

    order_filters() {
      return {
        groupName: 'External',
        options: [Ember.Object.create({
          id: 'external_sources_failed_payment',
          title: 'Failed Payment'
        }), Ember.Object.create({
          id: 'external_sources_successful_payment',
          title: 'Successful Payment'
        })]
      };
    }

  });

  _exports.default = _default;
});