define("segmentation/components/resync-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BhMgyTbi",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  \"],[7,\"span\",true],[10,\"data-test-header\",\"\"],[8],[1,[28,\"t-component\",[[23,0,[]],\"header\"],[[\"app_name\"],[[24,[\"app\",\"name\"]]]]],false],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"sub header\"],[8],[0,\"\\n    \"],[1,[28,\"t-component\",[[23,0,[]],\"sub_header\"],[[\"app_name\"],[[24,[\"app\",\"name\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"ui close button\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"ui form basic \",[28,\"if\",[[24,[\"resyncTask\",\"isRunning\"]],\"loading\"],null],\" segment\"]]],[8],[0,\"\\n  \"],[1,[28,\"t-component\",[[23,0,[]],\"content.description\"],[[\"htmlSafe\",\"app_name\"],[true,[24,[\"app\",\"name\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",[29,[\"ui left floated basic button \",[28,\"if\",[[24,[\"resyncTask\",\"isRunning\"]],\"disabled\"],null]]]],[12,\"data-test-close\",\"\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"phrase.cancel\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",[29,[\"ui small red button \",[28,\"if\",[[24,[\"resyncTask\",\"isRunning\"]],\"disabled\"],null]]]],[3,\"action\",[[23,0,[]],\"approve\"]],[8],[0,\"\\n    \"],[1,[28,\"t-component\",[[23,0,[]],\"actions.approve\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "segmentation/components/resync-modal/template.hbs"
    }
  });

  _exports.default = _default;
});