define("shipment-tracking/components/bulk-ship-order-selections-modal/component", ["exports", "shipment-tracking/components/bulk-ship-order-selections-modal/template", "shipment-tracking/components/bulk-ship-orders-modal/component"], function (_exports, _template, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _template.default,
    importPreviewUrl: Ember.computed('overwriteLocks', 'sendEmails', function () {
      return `/api/v2/orders/file/shipment_tracking_order_selections?preview=true&overwrite_lock=${this.get('overwriteLocks')}&send_emails=${this.get('sendEmails')}`;
    }),
    importUrl: Ember.computed('overwriteLocks', 'sendEmails', 'fileId', 'project.id', 'fileName', function () {
      return `/api/v2/orders/file/shipment_tracking_order_selections?preview=false&overwrite_lock=${this.get('overwriteLocks')}&send_emails=${this.get('sendEmails')}&file_id=${this.get('fileId')}&file_name=${this.get('fileName')}&project_id=${this.get('project.id')}`;
    })
  });

  _exports.default = _default;
});