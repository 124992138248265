define("authentication/services/user", ["exports", "ember-concurrency", "authentication/mixins/session-refresh"], function (_exports, _emberConcurrency, _sessionRefresh) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ServiceProxy = Ember.ObjectProxy.extend();
  ServiceProxy.reopenClass({
    isServiceFactory: true
  });

  var _default = ServiceProxy.extend(Ember.Evented, _sessionRefresh.default, {
    store: Ember.inject.service(),
    session: Ember.inject.service(),

    init() {
      var _this = this;

      this._super(...arguments);

      this.get('session').on('sessionInvalidated', function () {
        return _this.sessionInvalidated(...arguments);
      });
    },

    restore(user_id) {
      return this.get('restoreTask').perform(user_id).then(user => this.set('content', user));
    },

    restoreTask: (0, _emberConcurrency.task)(function* (user_id) {
      let user;

      if (Ember.isBlank(user_id)) {
        return user;
      }

      user = this.get('store').peekRecord('user', user_id);

      if (Ember.isPresent(user)) {
        return user;
      }

      user = yield this.get('store').findRecord('user', user_id, {
        include: 'companies'
      });
      return user;
    }),

    load() {
      if (!this.get('session.isAuthenticated')) {
        return Ember.RSVP.Promise.resolve();
      }

      const user_id = this.get('session.data.authenticated.id') || this.get('session.data.authenticated.user_id');

      if (Ember.isBlank(user_id)) {
        return Ember.RSVP.Promise.resolve();
      }

      return this.get('loadTask').perform(user_id);
    },

    loadTask: (0, _emberConcurrency.task)(function* (user_id) {
      try {
        yield this.restore(user_id);
        this.userLoaded();
      } catch (error) {
        this.get('session').invalidate();
        throw error;
      }
    }),

    // Once the user is loaded we can start refreshing the token automatically
    userLoaded() {
      if (this.get('session.data.authenticated.type') === 'token' && this.get('session.data.authenticated.disableRefresh') !== true) {
        this.maintainTokenSession();
      }

      this.trigger('userLoaded');
    },

    // Once the session gets invalidated let's stop refreshing the token
    sessionInvalidated() {
      this.set('maintainSession', true);
    }

  });

  _exports.default = _default;
});