define("ember-promise-tools/utils/is-fulfilled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  // It's assumed if you call this method, it was previously checked that it is a promise
  function _default(promise) {
    if (Ember.PromiseProxyMixin.detect(promise)) {
      if (promise.get('isFulfilled')) {
        return true;
      }

      return false;
    }

    if (promise instanceof Ember.RSVP.Promise) {
      if (promise._state === 1) {
        // Fulfilled
        return true;
      }

      return false;
    } // Can't detect it if its not one of the two kinds above


    return false;
  }
});