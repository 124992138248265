define("shared/mixins/project-total", ["exports", "shared/utils/computed-sum-by", "shared/utils/monetize"], function (_exports, _computedSumBy, _monetize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.find_zone_location = find_zone_location;

  const find_row_by_location = function (rows, country, state) {
    if (Ember.isBlank(rows)) {
      return;
    }

    var filtered_zone_locations = rows.filter(function (item) {
      // Country Check
      return (item.get('country.id') == null || item.get('country.id') === country.get('id')) && ( // State Check
      item.get('state.id') == null || state != null && item.get('state.id') === state.get('id'));
    });
    return filtered_zone_locations.sortBy('sort_order').objectAt(0);
  };

  function find_zone_location(entity, relation_name, country, state) {
    if (entity == null || entity.get('id') == null) {
      return null;
    }

    if (country == null || country.get('id') == null) {
      return null;
    }

    return find_row_by_location(entity.get(relation_name), country, state);
  }

  const computed_product_cost_cents = function (line_type) {
    return Ember.computed(`${line_type}.@each.prices_product_amount_cents`, function () {
      return this.get(line_type).reduce(function (value, order_line) {
        return value + (order_line.get('prices.product.amount_cents') || 0);
      }, 0);
    });
  };

  const computed_additional_cost_cents = function (line_type) {
    return Ember.computed(`${line_type}.@each.prices_additional_amount_cents`, function () {
      return this.get(line_type).reduce(function (value, order_line) {
        return value + (order_line.get('prices.additional.amount_cents') || 0);
      }, 0);
    });
  };

  const computed_shipping_cost_cents = function (line_type) {
    return Ember.computed(`${line_type}.@each.prices_shipping_amount_cents`, function () {
      return this.get(line_type).reduce(function (value, order_line) {
        return value + (order_line.get('prices.shipping.amount_cents') || 0);
      }, 0);
    });
  };

  const computed_tax_cost_cents = function (tax_type) {
    return Ember.computed('tax_lines.@each.{prices_tax_amount_cents,price_data_settings_symbol}', function () {
      return this.get('tax_lines').filterBy('price_data_settings_symbol', tax_type).reduce(function (value, order_line) {
        return value + (order_line.get('prices.tax.amount_cents') || 0);
      }, 0);
    });
  }; // Properties should match up to api property names, and in order if possible


  var _default = Ember.Mixin.create({
    // Reward
    reward_costs: (0, _monetize.default)('reward_costs_cents', 'currency'),
    reward_shipping_costs: (0, _monetize.default)('reward_shipping_costs_cents', 'currency'),
    reward_upgrade_costs: (0, _monetize.default)('reward_upgrade_costs_cents', 'currency'),
    total_reward_costs: (0, _monetize.default)('total_reward_costs_cents', 'currency'),
    total_reward_costs_without_shipping: (0, _monetize.default)('total_reward_costs_without_shipping_cents', 'currency'),
    // Extras
    extra_costs: (0, _monetize.default)('extra_costs_cents', 'currency'),
    extra_shipping_costs: (0, _monetize.default)('extra_shipping_costs_cents', 'currency'),
    extra_upgrade_costs: (0, _monetize.default)('extra_upgrade_costs_cents', 'currency'),
    total_extra_costs: (0, _monetize.default)('total_extra_costs_cents', 'currency'),
    total_extra_costs_without_shipping: (0, _monetize.default)('total_extra_costs_without_shipping_cents', 'currency'),
    // External
    external_costs: (0, _monetize.default)('external_costs_cents', 'currency'),
    external_shipping_costs: (0, _monetize.default)('external_shipping_costs_cents', 'currency'),
    external_upgrade_costs: (0, _monetize.default)('external_upgrade_costs_cents', 'currency'),
    total_external_costs: (0, _monetize.default)('total_external_costs_cents', 'currency'),
    total_external_costs_without_shipping: (0, _monetize.default)('total_external_costs_without_shipping_cents', 'currency'),
    // Manual
    manual_costs: (0, _monetize.default)('manual_costs_cents', 'currency'),
    manual_shipping_costs: (0, _monetize.default)('manual_shipping_costs_cents', 'currency'),
    manual_upgrade_costs: (0, _monetize.default)('manual_upgrade_costs_cents', 'currency'),
    total_manual_costs: (0, _monetize.default)('total_manual_costs_cents', 'currency'),
    total_manual_costs_without_shipping: (0, _monetize.default)('total_manual_costs_without_shipping_cents', 'currency'),
    // Shipping
    // Shipping only is shipping order lines
    shipping_shipping_costs: (0, _monetize.default)('shipping_shipping_costs_cents', 'currency'),
    total_shipping_costs: (0, _monetize.default)('total_shipping_costs_cents', 'currency'),
    // Shipping is all shipping including reward, extras, external, and shipping only
    all_shipping_costs: (0, _monetize.default)('all_shipping_costs_cents', 'currency'),
    // Taxes
    sales_tax_costs: (0, _monetize.default)('sales_tax_costs_cents', 'currency'),
    vat_costs: (0, _monetize.default)('vat_costs_cents', 'currency'),
    customs_costs: (0, _monetize.default)('customs_costs_cents', 'currency'),
    total_tax_costs: (0, _monetize.default)('total_tax_costs_cents', 'currency'),
    // Payments
    direct_payments: (0, _monetize.default)('direct_payments_cents', 'currency'),
    external_payments: (0, _monetize.default)('external_payments_cents', 'currency'),
    manual_payments: (0, _monetize.default)('manual_payments_cents', 'currency'),
    total_payments: (0, _monetize.default)('total_payments_cents', 'currency'),
    total: (0, _monetize.default)('total_cents', 'currency'),
    paid: (0, _monetize.default)('paid_cents', 'currency'),
    balance: (0, _monetize.default)('balance_cents', 'currency'),
    reward_costs_cents: computed_product_cost_cents('reward_lines'),
    reward_shipping_costs_cents: computed_shipping_cost_cents('reward_lines'),
    reward_upgrade_costs_cents: computed_additional_cost_cents('reward_lines'),
    total_reward_costs_cents: Ember.computed('reward_costs_cents', 'reward_shipping_costs_cents', 'reward_upgrade_costs_cents', function () {
      return this.get('reward_costs_cents') + this.get('reward_shipping_costs_cents') + this.get('reward_upgrade_costs_cents');
    }),
    // Helper, not used in final calculations
    total_reward_costs_without_shipping_cents: Ember.computed('reward_costs_cents', 'reward_upgrade_costs_cents', function () {
      return this.get('reward_costs_cents') + this.get('reward_upgrade_costs_cents');
    }),
    // External
    external_costs_cents: computed_product_cost_cents('external_lines'),
    external_upgrade_costs_cents: computed_additional_cost_cents('external_lines'),
    external_shipping_costs_cents: computed_shipping_cost_cents('external_lines'),
    total_external_costs_cents: Ember.computed('external_costs_cents', 'external_shipping_costs_cents', 'external_upgrade_costs_cents', function () {
      return this.get('external_costs_cents') + this.get('external_shipping_costs_cents') + this.get('external_upgrade_costs_cents');
    }),
    // Helper, not used in final calculations
    total_external_costs_without_shipping_cents: Ember.computed('external_costs_cents', 'external_upgrade_costs_cents', function () {
      return this.get('external_costs_cents') + this.get('external_upgrade_costs_cents');
    }),
    // Manual
    manual_costs_cents: computed_product_cost_cents('manual_lines'),
    manual_upgrade_costs_cents: computed_additional_cost_cents('manual_lines'),
    manual_shipping_costs_cents: computed_shipping_cost_cents('manual_lines'),
    total_manual_costs_cents: Ember.computed('manual_costs_cents', 'manual_shipping_costs_cents', 'manual_upgrade_costs_cents', function () {
      return this.get('manual_costs_cents') + this.get('manual_shipping_costs_cents') + this.get('manual_upgrade_costs_cents');
    }),
    // Helper, not used in final calculations
    total_manual_costs_without_shipping_cents: Ember.computed('manual_costs_cents', 'manual_upgrade_costs_cents', function () {
      return this.get('manual_costs_cents') + this.get('manual_upgrade_costs_cents');
    }),
    // Extras
    extra_costs_cents: computed_product_cost_cents('extra_lines'),
    extra_shipping_costs_cents: computed_shipping_cost_cents('extra_lines'),
    extra_upgrade_costs_cents: computed_additional_cost_cents('extra_lines'),
    total_extra_costs_cents: Ember.computed('extra_costs_cents', 'extra_shipping_costs_cents', 'extra_upgrade_costs_cents', function () {
      return this.get('extra_costs_cents') + this.get('extra_shipping_costs_cents') + this.get('extra_upgrade_costs_cents');
    }),
    // Helper, not used in final calculations
    total_extra_costs_without_shipping_cents: Ember.computed('extra_costs_cents', 'extra_upgrade_costs_cents', function () {
      return this.get('extra_costs_cents') + this.get('extra_upgrade_costs_cents');
    }),
    // Shipping
    shipping_shipping_costs_cents: computed_shipping_cost_cents('shipping_lines'),
    total_shipping_costs_cents: Ember.computed('shipping_shipping_costs_cents', function () {
      return this.get('shipping_shipping_costs_cents');
    }),
    all_shipping_costs_cents: Ember.computed('reward_shipping_costs_cents', 'extra_shipping_costs_cents', 'external_shipping_costs_cents', 'manual_shipping_costs_cents', 'shipping_shipping_costs_cents', function () {
      return this.get('reward_shipping_costs_cents') + this.get('extra_shipping_costs_cents') + this.get('external_shipping_costs_cents') + this.get('manual_shipping_costs_cents') + this.get('shipping_shipping_costs_cents');
    }),
    // Taxes
    sales_tax_costs_cents: computed_tax_cost_cents('sales_tax'),
    vat_costs_cents: computed_tax_cost_cents('vat'),
    customs_costs_cents: computed_tax_cost_cents('customs'),
    total_tax_costs_cents: Ember.computed('sales_tax_costs_cents', 'vat_costs_cents', 'customs_costs_cents', function () {
      return this.get('sales_tax_costs_cents') + this.get('vat_costs_cents') + this.get('customs_costs_cents');
    }),
    // Payments
    direct_transactions: Ember.computed.filter('transactions_not_deleted.@each.{provider,confirmed}', function (order_transaction) {
      return ['Stripe', 'PayPal'].indexOf(order_transaction.get('provider')) >= 0 && order_transaction.get('confirmed') === true;
    }),
    direct_payments_cents: (0, _computedSumBy.default)('direct_transactions', 'amount_cents'),
    external_transactions: Ember.computed.filter('transactions_not_deleted.@each.{provider,confirmed}', function (order_transaction) {
      return (order_transaction.get('provider') || '').toLowerCase() === this.get('source.site') && order_transaction.get('confirmed') === true;
    }),
    external_payments_cents: (0, _computedSumBy.default)('external_transactions', 'amount_cents'),
    manual_transactions: Ember.computed.filter('transactions_not_deleted.@each.{provider,confirmed}', function (order_transaction) {
      return order_transaction.get('provider') === 'Manual' && order_transaction.get('confirmed') === true;
    }),
    manual_payments_cents: (0, _computedSumBy.default)('manual_transactions', 'amount_cents'),
    confirmed_transactions: Ember.computed.filterBy('transactions_not_deleted', 'confirmed', true),
    total_payments_cents: (0, _computedSumBy.default)('confirmed_transactions', 'amount_cents'),
    total_cents: Ember.computed('total_reward_costs_cents', 'total_extra_costs_cents', 'total_external_costs_cents', 'total_manual_costs_cents', 'total_shipping_costs_cents', 'total_tax_costs_cents', function () {
      return this.get('total_reward_costs_cents') + this.get('total_extra_costs_cents') + this.get('total_external_costs_cents') + this.get('total_manual_costs_cents') + this.get('total_shipping_costs_cents') + this.get('total_tax_costs_cents');
    }),
    paid_cents: Ember.computed('total_payments_cents', function () {
      return this.get('total_payments_cents');
    }),
    // Negative total, they have extra funds
    // Positive total, they owe money
    balance_cents: Ember.computed('total_cents', 'paid_cents', function () {
      return this.get('total_cents') - this.get('paid_cents');
    }),
    all_transactions_use_project_currency: Ember.computed('confirmed_transactions.@each.currency', 'project.currency', function () {
      let projectCurrency = this.get('project.currency');
      return this.get('confirmed_transactions').every(transaction => transaction.currency === projectCurrency);
    })
  });

  _exports.default = _default;
});