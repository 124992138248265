define("shared/helpers/locked-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.lockedBy = lockedBy;

  function lockedBy(_ref) {
    let [locked] = _ref;
    return locked.map(function (item) {
      return item.get('friendly_process_name');
    }).join(', ');
  }

  var _default = Ember.Helper.helper(lockedBy);

  _exports.default = _default;
});