define("shared/mirage/factories/project-source", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name: 'test',
    url: 'https://kickstarter.com/project',
    image_url: 'https://kickstarter.com/project.png',
    start_date: '2017-01-01',
    end_date: '2017-01-20',
    location: 'x',
    category: 'x',
    order_count: '0',
    total_ordered_cents: '0',
    status: 'live',
    goal_cents: '0'
  });

  _exports.default = _default;
});