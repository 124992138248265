define("shared/components/product-configuration-variations/component", ["exports", "shared/components/product-configuration-variations/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Component class names in template
    tagName: "",
    layout: _template.default
  });

  _exports.default = _default;
});