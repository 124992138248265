define("shared/helpers/set-select-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Doesn't compute correctly unless we use the full helper version
  var _default = Ember.Helper.extend({
    compute(_ref, options) {
      let [collection, key, property] = _ref;
      return function (event) {
        let target = options.target || this;
        let value = event.target.value;
        let valueEntity = null;

        if (Ember.isPresent(value)) {
          valueEntity = collection.findBy(key, value);
        }

        if (Ember.isPresent(valueEntity)) {
          target.set(property, valueEntity);
        } else {
          let default_value = null;

          if (options.default != null) {
            default_value = options.default;
          }

          target.set(property, default_value);
        }
      };
    }

  });

  _exports.default = _default;
});