define("shared/initializers/ember-ajax", ["exports", "ember-ajax/services/ajax", "shared/utils/logger"], function (_exports, _ajax, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize() {
    _ajax.default.reopen({
      store: Ember.inject.service(),
      session: Ember.inject.service(),
      headers: Ember.computed('session.isAuthenticated', 'session.data.authenticated.{type,email,token,order_id}', {
        get() {
          let headers = {};
          let {
            token,
            type,
            order_id
          } = this.get('session.data.authenticated') || {};

          if (Ember.isPresent(token)) {
            if (type === 'authentication_token' && Ember.isPresent(order_id)) {
              headers['Authorization'] = `Token token="${token}", order_id="${order_id}"`;
            } else if (type === 'token') {
              headers['Authorization'] = `Bearer ${token}`;
            }
          }

          return headers;
        }

      }),

      init() {
        let config = Ember.getOwner(this).resolveRegistration('config:environment');

        if (!config.urls.api) {
          (0, _logger.logger_error)("missing required url for api");
        }

        this.set('host', config.urls.api);

        this._super(...arguments);
      },

      buildModelUrl(model, action) {
        let modelName = Ember.get(model, '_internalModel.modelName');
        let adapter = this.get('store').adapterFor(modelName);
        return adapter.buildURL(modelName, Ember.get(model, 'id')) + '/' + action;
      },

      processOptions(options) {
        if (Ember.isBlank(options)) {
          return {};
        }

        if (Ember.isPresent(options.data)) {
          options = Ember.assign({
            contentType: 'application/json; charset=UTF-8',
            dataType: 'json'
          }, options);
        }

        return options;
      },

      requestModel(model, action, options) {
        let url = this.buildModelUrl(model, action);
        options = this.processOptions(options);
        return this.request(url, options);
      },

      postModel(model, action, options) {
        let url = this.buildModelUrl(model, action);
        options = this.processOptions(options);
        return this.post(url, options);
      },

      putModel(model, action, options) {
        let url = this.buildModelUrl(model, action);
        options = this.processOptions(options);
        return this.put(url, options);
      },

      patchModel(model, action, options) {
        let url = this.buildModelUrl(model, action);
        options = this.processOptions(options);
        return this.patch(url, options);
      },

      delModel(model, action, options) {
        let url = this.buildModelUrl(model, action);
        options = this.processOptions(options);
        return this.del(url, options);
      },

      deleteModel() {
        return this.delModel(...arguments);
      }

    });
  }

  var _default = {
    name: 'ember-ajax',
    initialize
  };
  _exports.default = _default;
});