define('ember-prop-types/utils/validators/array-of', ['exports', 'ember-prop-types/utils/logger'], function (exports, _logger) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (validators, ctx, name, value, def, logErrors, throwErrors) {
    const typeDef = def.typeDef;

    const valid = isArray(value) && value.every((item, index) => {
      return validators[typeDef.type](ctx, `${name}[${index}]`, item, typeDef, logErrors, throwErrors);
    });

    if (!valid && logErrors) {
      _logger.default.warn(ctx, `Expected property ${name} to be an array of type ${typeDef.type} but instead got: ${Ember.typeOf(value)}`, throwErrors);
    }

    return valid;
  };

  const isArray = Array.isArray;
});