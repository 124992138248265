define("order-exporting/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.create({
    name: 'order-exporting',

    order_filters() {
      return {
        groupName: 'Export',
        options: [Ember.Object.create({
          id: 'exported',
          title: 'Exported'
        }), Ember.Object.create({
          id: 'not_exported',
          title: 'Not Exported'
        })]
      };
    }

  });

  _exports.default = _default;
});