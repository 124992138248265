define("ember-cli-flash/services/flash-messages", ["exports", "ember-cli-flash/flash/object", "ember-cli-flash/utils/object-without", "ember-cli-flash/utils/flash-message-options"], function (_exports, _object, _objectWithout, _flashMessageOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    isEmpty: Ember.computed.equal('queue.length', 0).readOnly(),
    _guids: Ember.computed.mapBy('queue', '_guid').readOnly(),
    arrangedQueue: Ember.computed.sort('queue', function (a, b) {
      if (a.priority < b.priority) {
        return 1;
      } else if (a.priority > b.priority) {
        return -1;
      }

      return 0;
    }).readOnly(),

    init() {
      this._super(...arguments);

      this._setDefaults();

      this.queue = Ember.A();
    },

    willDestroy() {
      this._super(...arguments);

      this.clearMessages();
    },

    add() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      this._enqueue(this._newFlashMessage(options));

      return this;
    },

    clearMessages() {
      const flashes = Ember.get(this, 'queue');

      if (Ember.isNone(flashes)) {
        return;
      }

      flashes.forEach(flash => flash.destroyMessage());
      flashes.clear();
      return this;
    },

    registerTypes() {
      let types = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : Ember.A();
      types.forEach(type => this._registerType(type));
      return this;
    },

    peekFirst() {
      return Ember.get(this, 'queue.firstObject');
    },

    peekLast() {
      return Ember.get(this, 'queue.lastObject');
    },

    getFlashObject() {
      const errorText = 'A flash message must be added before it can be returned';
      (false && !(Ember.get(this, 'queue').length) && Ember.assert(errorText, Ember.get(this, 'queue').length));
      return this.peekLast();
    },

    _newFlashMessage() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      (false && !(Ember.get(this, 'defaultPreventDuplicates') ? options.message : true) && Ember.assert('The flash message cannot be empty when preventDuplicates is enabled.', Ember.get(this, 'defaultPreventDuplicates') ? options.message : true));
      (false && !(Ember.get(options, 'preventDuplicates') ? options.message : true) && Ember.assert('The flash message cannot be empty when preventDuplicates is enabled.', Ember.get(options, 'preventDuplicates') ? options.message : true));
      const flashService = this;
      const allDefaults = Ember.getWithDefault(this, 'flashMessageDefaults', {});
      const defaults = (0, _objectWithout.default)(allDefaults, ['types', 'injectionFactories', 'preventDuplicates']);
      const flashMessageOptions = Ember.assign({}, defaults, {
        flashService
      });

      for (let key in options) {
        const value = Ember.get(options, key);

        const option = this._getOptionOrDefault(key, value);

        Ember.set(flashMessageOptions, key, option);
      }

      return _object.default.create(flashMessageOptions);
    },

    _getOptionOrDefault(key, value) {
      const defaults = Ember.getWithDefault(this, 'flashMessageDefaults', {});
      const defaultOption = Ember.get(defaults, key);

      if (Ember.typeOf(value) === 'undefined') {
        return defaultOption;
      }

      return value;
    },

    flashMessageDefaults: Ember.computed(function () {
      const config = Ember.getOwner(this).resolveRegistration('config:environment');
      const overrides = Ember.getWithDefault(config, 'flashMessageDefaults', {});
      return (0, _flashMessageOptions.default)(overrides);
    }),

    _setDefaults() {
      const defaults = Ember.getWithDefault(this, 'flashMessageDefaults', {});

      for (let key in defaults) {
        const classifiedKey = Ember.String.classify(key);
        const defaultKey = `default${classifiedKey}`;
        Ember.set(this, defaultKey, defaults[key]);
      }

      this.registerTypes(Ember.getWithDefault(this, 'defaultTypes', Ember.A()));
    },

    _registerType(type) {
      var _this = this;

      (false && !(type) && Ember.assert('The flash type cannot be undefined', type));

      this[type] = function (message) {
        let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        const flashMessageOptions = Ember.assign({}, options);
        Ember.setProperties(flashMessageOptions, {
          message,
          type
        });
        return _this.add(flashMessageOptions);
      };
    },

    _hasDuplicate(guid) {
      return Ember.get(this, '_guids').includes(guid);
    },

    _enqueue(flashInstance) {
      const instancePreventDuplicates = Ember.get(flashInstance, 'preventDuplicates');
      const preventDuplicates = typeof instancePreventDuplicates === 'boolean' ? // always prefer instance option over global option
      instancePreventDuplicates : Ember.get(this, 'defaultPreventDuplicates');

      if (preventDuplicates) {
        const guid = Ember.get(flashInstance, '_guid');

        if (this._hasDuplicate(guid)) {
          (false && Ember.warn('Attempting to add a duplicate message to the Flash Messages Service', false, {
            id: 'ember-cli-flash.duplicate-message'
          }));
          return;
        }
      }

      return Ember.get(this, 'queue').pushObject(flashInstance);
    }

  });

  _exports.default = _default;
});