define("ember-promise-tools/utils/is-promise", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(maybePromise) {
    if (maybePromise != null && typeof maybePromise.then === 'function') {
      return true;
    }

    return false;
  }
});