define("ember-froala-editor/helpers/merged-hash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.mergedHash = mergedHash;

  function mergedHash(params, hash) {
    // Assumes all params are objects
    if (params.length > 0) {
      return Ember.assign({}, ...params, hash);
    } else {
      return hash;
    }
  } // mergedHash()


  var _default = Ember.Helper.helper(mergedHash);

  _exports.default = _default;
});