define("shared/utils/computed-sum-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(dependentKey, property) {
    return Ember.computed(`${dependentKey}.@each.${property}`, function () {
      return (this.get(dependentKey) || Ember.A()).reduce(function (previousValue, item) {
        return previousValue + item.get(property);
      }, 0);
    });
  }
});