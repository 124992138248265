define("order-disputes/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.create({
    name: 'order-disputes',

    order_status() {
      return Ember.computed('order.extra.disputed_at', function () {
        if (!Ember.isBlank(this.get('order.extra.disputed_at'))) {
          return 'Disputed';
        }

        return null;
      });
    },

    order_filters() {
      return {
        groupName: 'Disputes',
        options: [Ember.Object.create({
          id: 'disputed',
          title: 'Disputed'
        }), Ember.Object.create({
          id: 'disputed_pending',
          title: 'Dispute: Pending'
        }), Ember.Object.create({
          id: 'disputed_won',
          title: 'Dispute: Won'
        }), Ember.Object.create({
          id: 'disputed_lost',
          title: 'Dispute: Lost'
        })]
      };
    }

  });

  _exports.default = _default;
});