define("shared/utils/image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  _exports.generateImageSrc = void 0;
  _exports.htmlSafeImageSrc = htmlSafeImageSrc;

  const generateImageSrc = function (host, image_path, params) {
    let client = new window.ImgixClient({
      domain: host,
      //secureURLToken: '<SECURE TOKEN>',
      includeLibraryParam: false
    });
    return client.buildURL(image_path, params);
  };

  _exports.generateImageSrc = generateImageSrc;

  function htmlSafeImageSrc(scope, image_path, params_property_or_object) {
    if (Ember.isBlank(image_path)) {
      return null;
    }

    let config = Ember.getOwner(scope).resolveRegistration('config:environment');
    let host = config.imgix.host; // Default compression params

    let params = {
      auto: 'compress,format'
    };

    if (Ember.isPresent(params_property_or_object)) {
      if (typeof params_property_or_object === 'string') {
        params = Ember.getWithDefault(scope, params_property_or_object, {});
      } else {
        params = params_property_or_object;
      }
    }

    return Ember.String.htmlSafe(generateImageSrc(host, image_path, params));
  }

  function _default(image_path_property, params_property_or_object) {
    let params = [image_path_property];

    if (Ember.isPresent(params_property_or_object) && typeof params_property_or_object === 'string') {
      params.push(params_property_or_object);
    }

    params.push(function () {
      let image_path = this.get(image_path_property);
      return htmlSafeImageSrc(this, image_path, params_property_or_object);
    });
    return Ember.computed(...params);
  }
});