define("shared/components/order-review-totals/component", ["exports", "shared/utils/computed-coalesce", "shared/components/order-review-totals/template"], function (_exports, _computedCoalesce, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['ui', 'vertically', 'divided', 'grid', 'order-review-totals', 'component', 'attached'],
    total_text: "Balance",
    zero_balance: false,
    // show balance as zero
    calculated: null,
    // internal flags
    calculated_balance: (0, _computedCoalesce.default)('calculated.balance', 'order.balance'),
    extra_funds_amount: (0, _computedCoalesce.default)('calculated.extra_funds_amount', 'order.extra_funds_amount'),
    has_extra_funds: Ember.computed.lt('calculated_balance', 0),
    minimum_balance_total: (0, _computedCoalesce.default)('calculated.minimum_balance_total', 'order.minimum_balance_total')
  });

  _exports.default = _default;
});