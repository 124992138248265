define("shared/utils/fallback-sort", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  _exports.fallbackSort = fallbackSort;

  function fallbackSort() {
    var sort_orders = Array.prototype.slice.call(arguments);
    return function (a, b) {
      // First see if we can sort_order by the sort_order field
      if (Ember.get(a, 'sort_order') != null && Ember.get(b, 'sort_order') != null) {
        // if we have an sort_order, sort_order asc
        if (Ember.get(a, 'sort_order') > Ember.get(b, 'sort_order')) {
          return 1;
        }

        if (Ember.get(a, 'sort_order') < Ember.get(b, 'sort_order')) {
          return -1;
        }
      } // if we have an sort_order and dont, sort_order above


      if (Ember.get(a, 'sort_order') == null && Ember.get(b, 'sort_order') != null) {
        return 1;
      }

      if (Ember.get(a, 'sort_order') != null && Ember.get(b, 'sort_order') == null) {
        return -1;
      } // Fallback to other columns


      for (var i = 0, field; i < sort_orders.length; i++) {
        field = sort_orders[i];

        if (Ember.get(a, field) > Ember.get(b, field)) {
          return 1;
        }

        if (Ember.get(a, field) < Ember.get(b, field)) {
          return -1;
        }
      }

      return 0;
    };
  }

  function _default() {
    let computedProperties = Array.prototype.slice.call(arguments);
    let collectionName = computedProperties.shift();
    computedProperties.unshift('sort_order');
    let computedPropertyName = '';

    if (computedProperties.length === 1) {
      computedPropertyName = '.@each.' + computedProperties.join(',');
    } else {
      computedPropertyName = '.@each.{' + computedProperties.join(',') + '}';
    }

    return Ember.computed.sort(collectionName + computedPropertyName, fallbackSort(...computedProperties));
  }
});