define("authentication/mixins/application-route", ["exports", "@ember-data/adapter/error", "@sentry/browser", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _error, Sentry, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_applicationRouteMixin.default, {
    user: Ember.inject.service('user'),
    session: Ember.inject.service(),
    analytics: Ember.inject.service(),

    init() {
      var _this = this;

      this._super(...arguments);

      this.get('user').on('userLoaded', function () {
        return _this.userLoaded(...arguments);
      });
    },

    beforeModel() {
      return Ember.RSVP.resolve(this._super(...arguments)).then(() => this.get('user').load());
    },

    sessionAuthenticated() {// We can't transition to the next route because the user isn't
      // loaded yet.
      //
      // If we load the user here the promise isn't resolved and the route
      // continues to transition.
      //
      // So we skip it, and it will need to be the responsibility of whoever
      // took the authentication action to do the redirection.
      //
      // This is their suggested usage of sessionAuthenticated based on comments
      // with the latest release of 3.0.0
      //
      // Although it seems that it's still not returning and resolving the user
      // so I'm going to keep this disabled for now.
      //
      // const _super = this._super;
      //
      // this._loadCurrentUser()
      //   .then(() => {
      //     _super.call(this, ...arguments);
      //   });
    },

    sessionInvalidated() {
      this._super(...arguments);

      Sentry.setUser();
    },

    userLoaded() {
      if (window.ga) {
        this.get('analytics').initialize(this);
      }

      if (Ember.isPresent(this.get('user.email'))) {
        Sentry.setUser({
          email: this.get('user.email'),
          id: this.get('user.id')
        });
      }
    },

    actions: {
      error(error, transition) {
        if (error instanceof _error.UnauthorizedError) {
          // Try to capture the UnauthorizedError so
          // we can trace back the network requests that occurred
          // before it
          Sentry.captureException(error, {
            extra: {
              user: this.get('user.content')
            }
          }); // Store the transition and then redirect over to unauthenticated.
          // It will first try to refresh the session and redirect you back
          // to where you wanted to go.
          //
          // If that isn't successful the session gets invalidated and it
          // takes you back to the login page.

          this.get('session').set('attemptedTransition', transition);
          this.replaceWith('unauthenticated');
          return false;
        } else {
          return true;
        }
      },

      invalidateSession() {
        this.get('session').invalidate();
      }

    }
  });

  _exports.default = _default;
});