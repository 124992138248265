define("authentication/authenticators/custom", ["exports", "shared/utils/logger", "ember-concurrency", "ember-simple-auth/authenticators/base", "authentication/services/jwt_session", "authentication/mixins/session-refresh"], function (_exports, _logger, _emberConcurrency, _base, _jwt_session, _sessionRefresh) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const EMPTY_SESSION = {
    "authenticator": "authenticator:custom"
  };

  function transformResult(resourceName, json) {
    // Must transform the response to put everything under the resourceName
    //
    // {
    //   "user": {}
    // }
    //
    if (Ember.isEmpty(json[resourceName])) {
      json[resourceName] = {};
    }

    Object.keys(json).forEach(key => {
      if (key === resourceName) {
        return;
      }

      let name = key.replace(`${resourceName}_`, '');
      json[resourceName][name] = json[key];
      delete json[key];
    });
    return json[resourceName] ? json[resourceName] : json;
  }

  let Restore = Ember.Mixin.create(_sessionRefresh.default, {
    restore: function (data) {
      return this.get('restoreTask').perform(data);
    },
    restoreTask: (0, _emberConcurrency.task)(function* (data) {
      let result = null;

      if (data.type === 'token') {
        result = yield this.get('restoreTokenSession').perform(data);

        if (result.token == null) {
          result = EMPTY_SESSION;
        }
      } else {
        result = EMPTY_SESSION;
      } // This token changes on the backend for each request
      // and will invalidate sessions when it doesn't match.


      delete result.authenticity_token;

      if (Ember.isEmpty(result.id)) {
        throw "Unable to restore";
      }

      return result;
    }),
    restoreTokenSession: (0, _emberConcurrency.task)(function* (data) {
      if (data.type !== 'token' || Ember.isBlank(data.token)) {
        return EMPTY_SESSION;
      }

      const session = _jwt_session.default.create({
        token: data.token
      });

      const now = new Date().getTime();
      const expires = session.exp();

      if (now >= expires) {
        data.token = yield this.get('refreshTokenSessionTask').perform();
      }

      return data;
    })
  });
  let Authenticate = Ember.Mixin.create({
    authenticate(identification, password) {
      return this.get('authenticateTask').perform(identification, password);
    },

    authenticateTask: (0, _emberConcurrency.task)(function* (identification, password) {
      let result = yield this.get('createTokenSessionTask').perform(identification, password); // This token changes on the backend for each request
      // and will invalidate sessions when it doesn't match.

      delete result.authenticity_token;
      result = transformResult(this.get('resourceName'), result);

      if (Ember.isEmpty(result.id)) {
        throw new Error("Unable to authenticate");
      }

      return result;
    }),
    createTokenSessionTask: (0, _emberConcurrency.task)(function* (identification, password) {
      let config = Ember.getOwner(this).resolveRegistration('config:environment');
      let session = yield this.get('ajax').post(`${config.urls.auth}/sessions`, {
        data: {
          username: identification,
          password: password
        }
      });
      let json = yield this.get('ajax').request(`${config.urls.api}/session/me`, {
        headers: {
          'Authorization': `Bearer ${session.result.id_token}`
        }
      }); // We're OK keeping this cookie
      // window.document.cookie = '_crowdox_session=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      // Copy the id_token into the response

      json.token = session.result.id_token;
      json.type = "token";

      if (Ember.isEmpty(json['user_email']) || Ember.isEmpty(json['token'])) {
        throw `Check that server response includes user_email and session response include id_token`;
      }

      return json;
    })
  });

  var _default = _base.default.extend(Restore, Authenticate, {
    ajax: Ember.inject.service(),
    resourceName: 'user',

    init() {
      let config = Ember.getOwner(this).resolveRegistration('config:environment');

      if (!config.urls.api) {
        (0, _logger.logger_error)("missing required url for api");
      }

      if (!config.urls.auth) {
        (0, _logger.logger_error)("missing required url for auth");
      }

      this._super(...arguments);
    },

    invalidate() {
      return Ember.RSVP.Promise.resolve();
    }

  });

  _exports.default = _default;
});