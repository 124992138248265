define("segmentation/components/resync-modal/component", ["exports", "ember-concurrency", "manage/config/environment", "segmentation/components/resync-modal/template", "manage/utils/catch-real", "manage/mixins/ss-modal"], function (_exports, _emberConcurrency, _environment, _template, _catchReal, _ssModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ssModal.default, {
    layout: _template.default,
    api_url: _environment.default.urls.api,
    ajax: Ember.inject.service(),
    resyncTask: (0, _emberConcurrency.task)(function* () {
      let project_id = this.get('project.id');
      let provider = this.get('app.slugWithoutPrefix');
      let api_url = this.get('api_url');
      yield this.get('ajax').post(`${api_url}/api/v2/order-syncs/resync`, {
        data: {
          project_id: project_id,
          provider: provider
        }
      });
    }),
    actions: {
      approve() {
        if (this.get('resyncTask.isRunning')) {
          return;
        }

        this.get('resyncTask').perform().catch((0, _catchReal.default)(error => {
          this.send('open_modal', 'error-modal', {
            error: error,
            description: "Unable to resync provider"
          });
        })).then(() => {
          if (this.get('rescyned')) {
            this.get('rescyned')();
          }

          this.closeModal();
        });
      }

    }
  });

  _exports.default = _default;
});