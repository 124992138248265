define("shared/components/product-configuration-wrapper/component", ["exports", "shared/components/product-configuration-wrapper/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['item', 'product-configuration-wrapper', 'component'],
    submitted: false,
    collapsed: false,
    configureEvents: Ember.inject.service(),

    init() {
      this._super(...arguments); // If the page is loaded again, then we want to collapse


      if (Ember.isPresent(this.get('order_selection.id')) && this.get('order_selection.validations.isValid')) {
        this.set('collapsed', true);
      }
    },

    is_minimized: Ember.computed('order_selection.{is_locked,has_choices,validations.isValid}', 'collapsed', function () {
      if (this.get('order_selection.is_locked')) {
        return true;
      }

      if (!this.get('order_selection.has_choices')) {
        return true;
      }

      if (this.get('collapsed')) {
        if (this.get('order_selection.validations.isValid')) {
          return true;
        }
      }

      return false;
    }),

    didInsertElement() {
      this._super(...arguments);

      this.registerEvent('on');
    },

    willDestroyElement() {
      this._super(...arguments);

      this.registerEvent('off');
    },

    registerEvent(event) {
      this.get('configureEvents')[event]('added', this, this.checkStatus);
    },

    checkStatus() {
      if (!this.get('is_minimized') && this.get('order_selection.validations.isValid')) {
        this.set('collapsed', true);
      }
    },

    actions: {
      set_collapsed(collapsed) {
        this.set('collapsed', collapsed);
      }

    }
  });

  _exports.default = _default;
});