define("shared/utils/product-mapping-order-selection-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(product_mapping) {
    return function (order_selection) {
      if (product_mapping.get('child.is_option')) {
        if (order_selection.get('product_option.id') === product_mapping.get('child.id')) {
          return true;
        }
      } else {
        if (order_selection.get('product.id') === product_mapping.get('child.id') && Ember.isBlank(order_selection.get('product_option.id')) && Ember.isBlank(order_selection.get('product_option_mapping.id'))) {
          return true;
        }
      }

      return false;
    };
  }
});