define("shared/helpers/normalize-errors", ["exports", "shared/utils/has-own-property", "shared/helpers/object-if-present", "jquery", "@ember-data/adapter/error"], function (_exports, _hasOwnProperty, _objectIfPresent, _jquery, _error) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.normalizeErrors = normalizeErrors;

  const friendly = function (text) {
    text = (text || "").trim();
    return text.replace(/_/g, ' ').replace(/\w\S*/g, function (text) {
      return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
    });
  };

  const cleanObject = function (object) {
    for (let property in object) {
      if ((0, _hasOwnProperty.default)(object, property)) {
        if (Ember.isBlank(object[property])) {
          delete object[property];
        }
      }
    }

    return object;
  };

  const replaceIfDefaultAdapterError = function (error, defaultError, replacementMessage) {
    if (Ember.isPresent(error) && Ember.isPresent(error.errors)) {
      if (error.errors.length === 1) {
        let errorItem = error.errors[0];

        if (errorItem.title === defaultError.errors[0].title && errorItem.detail === defaultError.errors[0].detail) {
          delete error.errors;
          error.errors = [{
            title: replacementMessage,
            detail: replacementMessage
          }];
        }
      }
    }
  };

  const getErrorAttribute = function (errorItem) {
    let attribute = null; // if its blank or not an object, return, we can't get anything from that

    if (!Ember.isBlank(errorItem) && typeof errorItem === "object") {
      // Attempt to get name from object now
      if (Ember.isPresent(errorItem.source) && Ember.isPresent(errorItem.source.pointer)) {
        let property_name = errorItem.source.pointer;
        attribute = property_name.substring(property_name.lastIndexOf('/') + 1);
      } else {
        attribute = (0, _objectIfPresent.objectIfPresent)(errorItem.name) || (0, _objectIfPresent.objectIfPresent)(errorItem.property) || (0, _objectIfPresent.objectIfPresent)(errorItem.attribute);
      }
    }

    return attribute;
  };

  const getErrorName = function (errorItem) {
    let attribute = getErrorAttribute(errorItem);

    if (Ember.isPresent(attribute)) {
      return friendly(attribute);
    }

    return null;
  };

  const getErrorMessage = function (errorItem) {
    if (typeof errorItem === "string") {
      return (errorItem || "").trim();
    } // if its blank or not an object, return, we can't get anything from that


    if (Ember.isBlank(errorItem) || typeof errorItem !== "object") {
      return null;
    }

    let message = null; // Try to get from default invalid error first

    if (Ember.isPresent(errorItem.detail)) {
      if (Ember.isPresent(errorItem.detail.error)) {
        if (Ember.isPresent(errorItem.detail.error.message)) {
          message = errorItem.detail.error.message;
        } else {
          message = errorItem.detail.error;
        }
      } else {
        message = errorItem.detail;
      }
    }

    if (Ember.isPresent(errorItem.title)) {
      if (message == null) {
        message = errorItem.title;
      } else {
        // See if the detail message is essentially the attribute and the title combined.
        // If so, just return the title.
        if (message == `${getErrorAttribute(errorItem)} - ${errorItem.title}`) {
          message = errorItem.title;
        }
      }
    }

    if (typeof message === "string" && Ember.isPresent(message)) {
      return (message || "").trim();
    }

    return "There was an unknown error.";
  };

  const getErrorMeta = function (errorItem) {
    let error = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let meta = {};

    if (Ember.isPresent(error.stack)) {
      meta.stack = error.stack;
    }

    if (Ember.isPresent(error.lineNumber)) {
      meta.lineNumber = error.lineNumber;
    }

    if (Ember.isPresent(error.name)) {
      meta.name = error.name;
    }

    if (Ember.isPresent(error.message)) {
      meta.details = error.message;
    } else if (Ember.isPresent(errorItem.detail)) {
      meta.details = errorItem.detail;
    }

    if (Ember.isPresent(error.status)) {
      meta.status = error.status;
    } else if (Ember.isPresent(errorItem.status)) {
      meta.status = errorItem.status;
    }

    if (Ember.isPresent(meta) && !_jquery.default.isEmptyObject(meta)) {
      return cleanObject(meta);
    }

    return null;
  };

  const generateError = function (errorItem, error) {
    let result = {
      name: getErrorName(errorItem),
      message: getErrorMessage(errorItem),
      meta: getErrorMeta(errorItem, error)
    };
    return cleanObject(result);
  };

  const generateErrors = function (error) {
    let results = Ember.A();

    for (let errorItem of error.errors) {
      results.push(generateError(errorItem, error));
    }

    return results;
  };

  const processErrorsWithDefault = function (error, defaultMessage) {
    if (Ember.isPresent(error) && Ember.isPresent(error.errors)) {
      return generateErrors(error);
    }

    if (Ember.isPresent(error) && Ember.isArray(error)) {
      let results = Ember.A();

      for (let errorItem of error) {
        results.push(generateError(errorItem));
      }

      return results;
    }

    return [{
      message: defaultMessage
    }];
  }; // This functions tries to take various error states and return a normalized view reguardless of input.
  // Input could be string, adapter error, json error, devise error, jquery error
  // result always returns an array
  // Items in the array look like
  // {
  //    name (optional): a text friendly name for the error
  //    message: a friendly message for the error
  //    meta (optional): {
  //      lineNumber (optional): The line number it occured on
  //      name: (optional): Non friendly name
  //      details (optional): Non friendly stack trace
  //      stack (optional): a stack trace if available
  //      status: Any status codes present
  //    }
  // }


  function normalizeErrors(_ref) {
    let [error] = _ref;

    if (Ember.isBlank(error) || _jquery.default.isPlainObject(error) && _jquery.default.isEmptyObject(error)) {
      return Ember.A();
    } // If the error is just a string


    if (typeof error === "string") {
      return [{
        message: error
      }];
    } // Specifically look for these three errors first.
    // In the future these errors will also exist.
    //  - UnauthorizedError, ForbiddenError, NotFoundError, ConflictError, ServerError
    // There isn't any contextual information on this error, generate info


    if (error instanceof _error.TimeoutError) {
      // Remove default error messages, they aren't added anyway
      replaceIfDefaultAdapterError(error, new _error.TimeoutError(), 'The adapter operation timed out');
      return processErrorsWithDefault(error);
    } // There isn't any contextual information on this error, generate info


    if (error instanceof _error.AbortError) {
      // Remove default error messages, they aren't added anyway
      replaceIfDefaultAdapterError(error, new _error.AbortError(), 'The adapter operation was aborted');
      return processErrorsWithDefault(error);
    } // We should have actual error information from this,


    if (error instanceof _error.InvalidError) {
      replaceIfDefaultAdapterError(error, new _error.InvalidError(), 'The adapter rejected the commit because it was invalid');
      return processErrorsWithDefault(error);
    } // AdapterError is the base of all ember data errors
    // If the error isn't a match above, and its an adapter error, default to that


    if (error instanceof _error.default) {
      replaceIfDefaultAdapterError(error, new _error.default(), 'Adapter operation failed');
      return processErrorsWithDefault(error);
    } // If we received an error, try to cast those into something


    return processErrorsWithDefault(error, 'There was an unknown error. Please review any changes and try again.');
  }

  var _default = Ember.Helper.helper(normalizeErrors);

  _exports.default = _default;
});