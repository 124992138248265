define("shared/components/product-quantity-selection/component", ["exports", "shared/components/product-quantity-selection/template", "shared/utils/product-mapping-order-selection-filter", "ember-concurrency", "shared/mixins/mobile-detection"], function (_exports, _template, _productMappingOrderSelectionFilter, _emberConcurrency, _mobileDetection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_mobileDetection.default, {
    layout: _template.default,
    classNames: ['item', 'product-quantity-selection', 'component'],
    orderTasks: Ember.inject.service(),
    configureEvents: Ember.inject.service(),
    showTitle: Ember.computed.equal('order_line.type', 'reward'),

    init() {
      if (this.get('product_mapping.required_quantity') <= 1) {
        throw "Product mapping with the child that has to have a quantity greater than 1";
      }

      this._super(...arguments);
    },

    order_selections: Ember.computed('order_line.selections_not_deleted.@each.{product,product_option,product_option_mapping}', 'product_mapping.{child.id,child.is_option}', function () {
      return this.get('order_line.selections_not_deleted').filter((0, _productMappingOrderSelectionFilter.default)(this.get('product_mapping')));
    }),
    actual_order_selections: Ember.computed.filterBy('order_selections', 'is_abstract', false),
    abstract_order_selection: Ember.computed('order_selections.@each.is_abstract', function () {
      return this.get('order_selections').findBy('is_abstract', true);
    }),
    processRunning: Ember.computed.or('orderTasks.{createOrderSelection.isRunning,removeOrderSelection.isRunning}', 'abstractOrderSelectionTask.isRunning'),
    abstractOrderSelectionTask: (0, _emberConcurrency.task)(function* (quantity_to_adjust, if_allowed_callback, error_message) {
      if (quantity_to_adjust === 0) {
        return;
      }

      let abstract_order_selection = this.get('abstract_order_selection'); // We are trying to take away from abstract order selection

      if (quantity_to_adjust < 0) {
        // Check that it exists, can't take away from something not there
        if (Ember.isBlank(abstract_order_selection)) {
          return;
        } // We are removing from abstract order selection


        if (abstract_order_selection.get('quantity') + quantity_to_adjust < 0) {
          // Can't take more away than we have
          return;
        }
      }

      try {
        yield if_allowed_callback(); // We are adding to abstract quantity selection, ensure it exists

        if (quantity_to_adjust > 0) {
          if (Ember.isBlank(abstract_order_selection)) {
            yield this.get('orderTasks.createOrderSelection').perform(this.get('product_mapping'), this.get('order_line'), quantity_to_adjust, {
              abstract: true
            });
          } else {
            abstract_order_selection.set('quantity', abstract_order_selection.get('quantity') + quantity_to_adjust);
          }

          return;
        }

        if (quantity_to_adjust < 0) {
          abstract_order_selection.set('quantity', abstract_order_selection.get('quantity') + quantity_to_adjust);

          if (abstract_order_selection.get('quantity') <= 0) {
            yield this.get('orderTasks.removeOrderSelection').perform(abstract_order_selection);
          }
        }
      } catch (error) {
        this.send('open_modal', 'error-modal', {
          error: error,
          description: error_message
        });
      }
    }),
    actions: {
      create() {
        if (this.get('processRunning')) {
          return;
        } // We want to take one away from abstract order selection to create a new selection


        this.get('abstractOrderSelectionTask').perform(-1, () => {
          return this.get('orderTasks.createOrderSelection').perform(this.get('product_mapping'), this.get('order_line'), 1).then(() => this.get('configureEvents').added());
        }, "Unable to create Order Selection.");
      },

      increment(order_selection) {
        if (this.get('processRunning')) {
          return;
        } // We want to take one away from abstract order selection to incremenet another selection


        this.get('abstractOrderSelectionTask').perform(-1, () => {
          order_selection.incrementProperty('quantity');
        }, "Unable to increase Order Selection.");
      },

      decrement(order_selection) {
        if (this.get('processRunning')) {
          return;
        } // We want to give one to abstract order selection and take one away from another selection


        this.get('abstractOrderSelectionTask').perform(1, () => {
          if (order_selection.get('quantity') === 1) {
            return this.get('orderTasks.removeOrderSelection').perform(order_selection);
          } else {
            order_selection.decrementProperty('quantity');
          }
        }, "Unable to decrease Order Selection.");
      },

      remove(order_selection) {
        if (this.get('processRunning')) {
          return;
        } // Give all the order selections quantity to abstract order selection


        this.get('abstractOrderSelectionTask').perform(order_selection.get('quantity'), () => {
          return this.get('orderTasks.removeOrderSelection').perform(order_selection);
        }, "Unable to remove Order Selection.");
      }

    }
  });

  _exports.default = _default;
});