define("shared/components/show-more-text/component", ["exports", "shared/components/show-more-text/template", "jquery"], function (_exports, _template, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['show-more-text', 'component'],
    attributeBindings: ['style'],
    text: null,
    lines: null,
    show_more: false,
    has_more: false,
    max_height: null,
    display_more_style: null,
    resize: Ember.inject.service(),
    has_more_and_not_showing: Ember.computed('text', 'has_more', 'show_more', function () {
      if (this.get('show_more')) {
        return false;
      }

      if (Ember.isBlank(this.get('text'))) {
        return false;
      }

      return this.get('has_more');
    }),
    style: Ember.computed('has_more_and_not_showing', 'max_height', function () {
      if (this.get('has_more_and_not_showing')) {
        return Ember.String.htmlSafe(`max-height: ${this.get('max_height')}px; overflow-y: hidden;`);
      } else {
        return Ember.String.htmlSafe();
      }
    }),
    actions: {
      show_more() {
        this.toggleProperty('show_more');
      }

    },

    didInsertElement() {
      this._super(...arguments);

      Ember.run.scheduleOnce('afterRender', this, this.checkText);
      this.get('resize').on('resize', this, this.checkText);
    },

    willDestroyElement() {
      this._super(...arguments);

      this.get('resize').off('resize', this, this.checkText);
    },

    checkText() {
      if (this.get('isDestroyed') || this.get('isDestroying')) {
        return;
      }

      let selector = (0, _jquery.default)(this.element);

      if (!selector || selector.length === 0) {
        return;
      }

      this.set('text', selector.text());
      let line_height = selector.css('line-height');
      let font_size = selector.css('font-size');
      let line_height_number = this.getSize(line_height);
      let font_size_number = this.getSize(font_size);
      let height = selector.height();
      let max = Math.max(line_height_number, font_size_number);
      let lines = this.getSize(this.get('lines')); // account for the show more

      let max_height = Math.ceil(max * lines);
      let display_more_style = Ember.String.htmlSafe(`line-height: ${line_height}; font-size: ${font_size};`);

      if (height >= max_height) {
        this.setProperties({
          has_more: true,
          max_height,
          display_more_style
        });
      } else {
        this.setProperties({
          has_more: false,
          max_height: null,
          display_more_style
        });
      }
    },

    getSize(value) {
      value = parseInt(value);

      if (isNaN(value)) {
        return 0;
      } else {
        return value;
      }
    }

  });

  _exports.default = _default;
});