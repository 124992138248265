define("shared/helpers/can-access-feature", ["exports", "shared/utils/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.canAccessFeature = canAccessFeature;
  _exports.default = void 0;

  function canAccessFeature(_ref) {
    let [project, feature, user = null, allow_admin = true] = _ref;

    if (allow_admin && Ember.isPresent(user) && user.get('is_admin')) {
      return true;
    }

    if (!project) {
      (0, _logger.logger_warn)(`Feature access:'${feature}' checked, but project wasn't passed in.`);
      return false;
    } // Features come from installed apps


    if (project.get('features') != null) {
      let features = project.get('features') || [];

      if (features.some(f => f.name === feature)) {
        return true;
      }
    }

    return (project.get('settings.feature_flags') || Ember.A()).indexOf(feature) >= 0;
  }

  var _default = Ember.Helper.extend({
    compute(_ref2) {
      let [project, feature, user = null, allow_admin = true] = _ref2;
      this.set('project', project);
      return canAccessFeature([project, feature, user, allow_admin]);
    },

    feature_flagsDidChange: Ember.observer('project.settings.feature_flags.[]', function () {
      this.recompute();
    })
  });

  _exports.default = _default;
});