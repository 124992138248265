define("shared/components/order-review/component", ["exports", "shared/components/order-review/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['order-review', 'component'],
    order: null,
    zero_balance: false,
    total_text: 'Balance',
    reward_line: Ember.computed.readOnly('order.reward_lines.firstObject'),
    project: Ember.computed.readOnly('order.project')
  });

  _exports.default = _default;
});