define("shared/components/sized-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j5UXbo4k",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"image_src\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"image wrapper\"],[11,\"style\",[22,\"wrapper_style\"]],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"overlay\"],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"icon zoom-in\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"gallery_image_paths\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[11,\"class\",[29,[\"gallery \",[28,\"if\",[[24,[\"show_minimal\"]],\"minimal\"],null]]]],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"icon images\"],[8],[9],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"title\"],[8],[0,\"Images\"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"show_default\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"inline-svg\",[\"svgs/icon-product\"],[[\"class\"],[\"svg icon\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/sized-image/template.hbs"
    }
  });

  _exports.default = _default;
});