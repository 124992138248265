define("segmentation/components/for-app/component", ["exports", "segmentation/components/for-app/template", "ember-concurrency"], function (_exports, _template, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    store: Ember.inject.service(),
    current_user: Ember.inject.service('user'),
    classNames: ['segmentation-addon', 'for-app', 'component'],
    // Paging
    page: 1,
    page_size: 10,
    // Sorting
    sortProperty: 'created_at',
    sortDirection: 'desc',
    sorted_by: Ember.computed('sortProperty', 'sortDirection', function () {
      return [`${this.get('sortProperty')}:${this.get('sortDirection')}`];
    }),

    init() {
      this._super(...arguments);

      this.get('loadTask').perform();
    },

    isRunning: Ember.computed.alias('loadTask.isRunning'),
    loadTask: (0, _emberConcurrency.task)(function* () {
      let project = yield this.get('project');
      let app = yield this.get('app');
      let page = this.get('page');
      let pageSize = this.get('page_size');
      let sort = this.get('sortProperty');

      if (this.get('sortDirection') == 'desc') {
        sort = `-${sort}`;
      }

      let actions = yield this.get('store').query('project-segment-action', {
        filter: {
          project_id: project.get('id'),
          sync_type: app.get('slugWithoutPrefix')
        },
        page: {
          number: page,
          size: pageSize
        },
        sort: sort,
        include: 'segment'
      });
      this.set('segment_actions', actions);
    }),
    actions: {
      change_page(number) {
        this.set('page', number);
        this.get('loadTask').perform();
      },

      reload() {
        this.get('loadTask').perform();
      }

    }
  });

  _exports.default = _default;
});