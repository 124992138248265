define("authentication/session-stores/application", ["exports", "ember-simple-auth/session-stores/cookie"], function (_exports, _cookie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _cookie.default.extend({
    cookieName: '_crowdox-session',
    ajax: Ember.inject.service(),

    persist(data) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        this._super(...arguments).then(() => {
          if (Ember.isPresent(data) && Ember.isPresent(data.authenticated) && data.authenticated.id) {
            resolve();
          } else {
            this.get('ajax').request(`/users/sign_out`).then(() => {
              resolve();
            }).catch(error => {
              reject(error);
            });
          }
        });
      });
    },

    restore() {
      return new Ember.RSVP.Promise((resolve, reject) => {
        this._super(...arguments).then(data => {
          if (Ember.isPresent(data) && Ember.isPresent(data.authenticated) && data.authenticated.id) {
            resolve(data);
          } else {
            this.get('ajax').request(`/session/me`).then(response => {
              resolve(response);
            }).catch(error => {
              reject(error);
            });
          }
        });
      });
    }

  });

  _exports.default = _default;
});