define('ember-prop-types/utils/validators/one-of-type', ['exports', 'ember-prop-types/utils/logger'], function (exports, _logger) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (validators, ctx, name, value, def, logErrors, throwErrors) {
    let valid = false;

    if (Ember.typeOf(def.typeDefs) !== 'array') {
      _logger.default.warn(ctx, 'PropTypes.oneOfType() requires an array of types to be passed in as an argument', throwErrors);

      return valid;
    }

    for (let i = 0, len = def.typeDefs.length; i < len; i++) {
      const typeDef = def.typeDefs[i];

      if (validators[typeDef.type](ctx, name, value, typeDef, false)) {
        valid = true;
        break;
      }
    }

    if (!valid) {
      const types = def.typeDefs.map(typeDef => typeDef.type);
      _logger.default.warn(ctx, `Expected property ${name} to be one of expected types: [${types.join(', ')}] but instead got ${Ember.typeOf(value)}`, throwErrors);
    }

    return valid;
  };
});