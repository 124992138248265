define("shared/tests/helpers", ["exports", "shared/tests/helpers/create-company", "shared/tests/helpers/create-customer", "shared/tests/helpers/authenticate-customer", "shared/tests/helpers/create-state", "shared/tests/helpers/create-country", "shared/tests/helpers/create-product", "shared/tests/helpers/create-zone-location", "shared/tests/helpers/create-configuration", "shared/tests/helpers/create-order"], function (_exports, _createCompany, _createCustomer, _authenticateCustomer, _createState, _createCountry, _createProduct, _createZoneLocation, _createConfiguration, _createOrder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "authenticateCustomer", {
    enumerable: true,
    get: function () {
      return _authenticateCustomer.default;
    }
  });
  Object.defineProperty(_exports, "createCompany", {
    enumerable: true,
    get: function () {
      return _createCompany.default;
    }
  });
  Object.defineProperty(_exports, "createConfiguration", {
    enumerable: true,
    get: function () {
      return _createConfiguration.default;
    }
  });
  Object.defineProperty(_exports, "createCountry", {
    enumerable: true,
    get: function () {
      return _createCountry.default;
    }
  });
  Object.defineProperty(_exports, "createCustomer", {
    enumerable: true,
    get: function () {
      return _createCustomer.default;
    }
  });
  Object.defineProperty(_exports, "createOrder", {
    enumerable: true,
    get: function () {
      return _createOrder.default;
    }
  });
  Object.defineProperty(_exports, "createProduct", {
    enumerable: true,
    get: function () {
      return _createProduct.default;
    }
  });
  Object.defineProperty(_exports, "createState", {
    enumerable: true,
    get: function () {
      return _createState.default;
    }
  });
  Object.defineProperty(_exports, "createZoneLocation", {
    enumerable: true,
    get: function () {
      return _createZoneLocation.default;
    }
  });
  _exports.default = _default;

  function _default() {} // Nothing to do, we just need the imports

});