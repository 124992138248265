define("shared/initializers/ember-data-extension", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function
    /* application */
  initialize() {
    // Referenced these pull requests for changes below
    // https://github.com/emberjs/data/pull/3698/files
    // https://github.com/emberjs/data/pull/4361/files
    // Latest: https://github.com/emberjs/data/compare/v3.12.3...swarmbox:v3.12.3+rollback-relationships?expand=1
    _model.default.reopen({
      // Have to make this volatile or it caches and doesn't properly update
      get isDirty() {
        if (this.get('currentState') == null || this.get('currentState.isEmpty')) {
          return false;
        }

        return this.get('hasDirtyAttributes') || this.get('hasDirtyRelationships');
      },

      // See https://api.emberjs.com/ember-data/3.10/classes/DS.Model/properties/isLoaded?anchor=isDeleted
      isFullyDeleted: Ember.computed('isDeleted', 'isSaving', 'hasDirtyAttributes', function () {
        return this.get('isDeleted') === true && this.get('isSaving') === false && this.get('hasDirtyAttributes') === false;
      }),

      get hasDirtyRelationships() {
        if (this.get('currentState') == null || this.get('currentState.isEmpty')) {
          return false;
        }

        if (this.get('isFullyDeleted')) {
          return false;
        }

        let isDirty = false;
        let internalModel = this.get('_internalModel');
        this.eachRelationship((name, relationshipInfo) => {
          if (isDirty) {
            return;
          }

          if (relationshipInfo.kind !== 'belongsTo') {
            return;
          }

          let relationship = internalModel._recordData._relationships.get(name);

          if (relationship.canonicalState !== relationship.inverseRecordData) {
            isDirty = true;
          }
        });
        return isDirty;
      },

      isRelationshipDirty(name) {
        if (this.get('isFullyDeleted')) {
          return false;
        }

        let internalModel = this.get('_internalModel');

        let relationship = internalModel._recordData._relationships.get(name);

        if (Ember.isBlank(relationship)) {
          return false;
        }

        if (relationship.relationshipMeta.kind !== 'belongsTo') {
          return false;
        }

        if (relationship.canonicalState !== relationship.inverseRecordData) {
          return true;
        }

        return false;
      },

      rollbackRelationships() {
        let internalModel = this.get('_internalModel');
        this.eachRelationship((name, relationshipInfo) => {
          if (relationshipInfo.kind !== 'belongsTo') {
            return;
          }

          let relationship = internalModel._recordData._relationships.get(name);

          if (relationship.canonicalState !== relationship.inverseRecordData) {
            relationship.setRecordData(relationship.canonicalState);
          }
        });
      },

      relationshipsSaved() {
        let internalModel = this.get('_internalModel');
        this.eachRelationship((name, relationshipInfo) => {
          if (relationshipInfo.kind !== 'belongsTo') {
            return;
          }

          let relationship = internalModel._recordData._relationships.get(name);

          if (relationship.canonicalState !== relationship.inverseRecordData) {
            relationship.setCanonicalRecordData(relationship.inverseRecordData);
          }
        });
      },

      markSaved() {
        let internalModel = this.get('_internalModel');
        internalModel.adapterWillCommit();
        internalModel.adapterDidCommit();
        this.relationshipsSaved();
      },

      // Validation helpers
      validationsIsInvalid: Ember.computed.alias('validations.isInvalid'),
      validationsIsValid: Ember.computed.alias('validations.isValid'),
      validationsMessages: Ember.computed.alias('validations.messages')
    });
  }

  var _default = {
    name: 'ember-data-extension',
    initialize
  };
  _exports.default = _default;
});