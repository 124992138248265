define("shared/helpers/t-stage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    onLocaleChange: Ember.observer('intl.primaryLocale', function () {
      this.recompute();
    }),

    // Pass getDefault=true if you want to get the original definition in the intl file
    // and not any overrides that might be present in the project's translation dictionary.
    compute(_ref, _ref2) {
      let [project, key] = _ref;
      let {
        getDefault,
        locale
      } = _ref2;
      // Eventhough we don't use this on the first getDefault check
      // it has to be accessed once for all of them in order for
      // the recompute observer to activate.
      let intl = this.get('intl');

      if (!getDefault) {
        let property = project.get(`t.${key}`);

        if (Ember.isPresent(property)) {
          return property;
        }
      }

      if (locale) {
        return intl.lookup(`purpose.${project.get('purpose')}.stage.${key}`, locale);
      } else {
        return intl.lookup(`purpose.${project.get('purpose')}.stage.${key}`);
      }
    }

  });

  _exports.default = _default;
});