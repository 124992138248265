define("shared/components/product-configuration-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BDk6FuaL",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"show_image\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"image product-configuration-image component\"],[11,\"style\",[22,\"style\"]],[8],[0,\"\\n    \"],[1,[28,\"sized-image\",null,[[\"image_path\",\"gallery_image_paths\",\"width\",\"height\",\"mobile_width\",\"mobile_height\"],[[24,[\"image_path\"]],[24,[\"order_selection\",\"product\",\"gallery_image_paths\"]],[24,[\"image_size\"]],[24,[\"image_size\"]],[24,[\"mobile_image_size\"]],[24,[\"mobile_image_size\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/product-configuration-image/template.hbs"
    }
  });

  _exports.default = _default;
});