define("shared/components/order-shipping-lines/component", ["exports", "shared/components/order-shipping-lines/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: "",
    pluralize: true,
    order_lines_with_shipping: Ember.computed('order_lines.@each.{sort_order,prices_shipping_amount_cents}', function () {
      let order_lines = (this.get('order_lines') || Ember.A()).filter(order_line => order_line.get('prices.shipping.amount_cents') !== 0);
      return order_lines.sortBy('sort_order');
    })
  });

  _exports.default = _default;
});