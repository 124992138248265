define("shared/utils/catch-real", ["exports", "ember-cp-validations/validations/result-collection", "@ember-data/adapter/error"], function (_exports, _resultCollection, _error) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(execute_if_real, execute_if_not) {
    execute_if_not = execute_if_not || function () {};

    return function (error) {
      // Validation error, will show up on the model
      if (error instanceof _error.InvalidError) {
        return execute_if_not('DS_INVALID', error);
      }

      if (error instanceof _resultCollection.default) {
        return execute_if_not('CP_VALIDATION', error);
      }

      if (error.name === 'TransitionAborted') {
        return execute_if_not('TRANSITION_ABORTED', error);
      }

      if (error.name === 'TaskCancelation') {
        return execute_if_not('TASK_CANCELED', error);
      }

      return execute_if_real(error);
    };
  }
});