define("shared/mixins/load-countries", ["exports", "shared/utils/fallback-sort", "shared/utils/logger", "ember-concurrency"], function (_exports, _fallbackSort, _logger, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    loadCountriesTask: (0, _emberConcurrency.task)(function* (_ref) {
      let {
        order_line,
        product_bundle,
        order
      } = _ref;
      order_line = yield order_line;
      product_bundle = yield product_bundle;
      order = yield order;
      let sorter = (0, _fallbackSort.fallbackSort)('name', 'id');
      let all_countries = this.get('store').peekAll('country').toArray();
      let default_return = {
        is_limited: false,
        countries: all_countries.sort(sorter)
      }; // Try to get the bundle from the order line if its not passed in directly

      if (Ember.isPresent(order_line) && Ember.isBlank(product_bundle)) {
        order_line = yield order_line;
        product_bundle = yield order_line.get('product_bundle');
      }

      let zone_locations = Ember.A();

      if (Ember.isPresent(product_bundle)) {
        zone_locations = yield product_bundle.get('shipping_product_based_zone_locations');
      } else {
        (0, _logger.logger_warn)(`No order_line or product_bundle was passed into loadCountriesTask`);
      } // We don't have any location rates, just return everything


      if (zone_locations.get('length') === 0) {
        return default_return;
      } // Check if we have an everywhere location


      for (let zone_location of zone_locations.toArray()) {
        if (zone_location.get('is_everywhere_else')) {
          return default_return;
        }
      } // Disable all and then mark as the opposite


      let limited_countries = Ember.A();

      for (let zone_location of zone_locations.toArray()) {
        let country = yield zone_location.get('country');

        if (Ember.isPresent(country)) {
          limited_countries.addObject(country);
          continue;
        }
      }

      if (Ember.isPresent(order)) {
        let shipping_address = yield order.get('shipping_address');
        let country = yield shipping_address.get('country');

        if (Ember.isPresent(country)) {
          if (!limited_countries.findBy('id', country.get('id'))) {
            // We need to clear the country, since it doesn't belong in the list
            shipping_address.set('country', null);
          }
        }
      }

      return {
        is_limited: true,
        countries: limited_countries.sort(sorter)
      };
    }).restartable()
  });

  _exports.default = _default;
});