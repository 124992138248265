define("shared/mirage/factories/project-address", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name: 'Pick me up',
    address_1: '123 store',
    address_2: 'Building C',
    city: 'Lehi',
    state_text: 'CA',
    postal_code: '12345',
    phone_number: '801-555-5555',
    canada: (0, _emberCliMirage.trait)({
      city: 'Ottawa',
      state_text: 'ON',
      postal_code: 'K1A 0G9',
      phone_number: '905-555-5555'
    })
  });

  _exports.default = _default;
});