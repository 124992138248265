define('ember-prop-types/utils/validators/ember-object', ['exports', 'ember-prop-types/utils/logger'], function (exports, _logger) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (ctx, name, value, def, logErrors, throwErrors) {
    const valid = Ember.typeOf(value) === ('instance' || 'class');

    if (!valid && logErrors) {
      _logger.default.warn(ctx, `Expected property ${name} to be an Ember.Object but instead got: ${Ember.typeOf(value)}`, throwErrors);
    }

    return valid;
  };
});