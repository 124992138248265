define("shared/components/product-option-configuration/component", ["exports", "shared/components/product-option-configuration/template", "shared/mixins/product-variation-selection"], function (_exports, _template, _productVariationSelection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_productVariationSelection.default, {
    classNames: ['item', 'product-option-configuration', 'component'],
    layout: _template.default,
    orderTasks: Ember.inject.service(),
    image_size: 200,
    mobile_image_size: 230,

    init() {
      if (Ember.isBlank(this.get('product_mapping'))) {
        throw "A product_mapping is required";
      }

      if (Ember.isBlank(this.get('order_selection'))) {
        throw "An order_selection is required";
      }

      if (!this.get('product_mapping.child.is_option')) {
        throw "Product mapping with the child that is not an option, is not supported in this component";
      } // Call init after we get order_selection


      this._super(...arguments);
    },

    actions: {
      product_option_mapping_updated(event) {
        let value = event.target.value;
        let product_option_mapping = this.get('product_mapping.child.mappings_sorted').findBy('id', value);
        let order_selection = this.get('order_selection');
        order_selection.set('product_option_mapping', product_option_mapping);
        order_selection.set('product', product_option_mapping.get('child')); // the product changed and the variation is no longer valid

        if (order_selection.get('product_variation.product.id') !== product_option_mapping.get('child.id')) {
          order_selection.set('product_variation', null);
        } // Update preliminary_variation & Questions


        this.get('orderTasks.updateOrderSelection').perform(order_selection) // Ensure product Variation Selection updates for the product
        .then(() => this.send('set_product'));
      }

    }
  });

  _exports.default = _default;
});