define("shared/mirage/factories/order-address", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    address_1: '123 Test',
    city: 'Draper',
    state_text: 'UT',
    postal_code: '84020',
    phone_number: '555-555-5555',
    countryId: 1,
    withCompany: (0, _emberCliMirage.trait)({
      company_name: 'Buymore'
    }),
    withAddress2: (0, _emberCliMirage.trait)({
      company_name: 'Buymore',
      address_2: 'Apt 23'
    }),
    canada: (0, _emberCliMirage.trait)({
      address_1: '234 Laurier Avenue West',
      countryId: 2,
      city: 'Ottawa',
      state_text: 'ON',
      postal_code: 'K2A 0G9'
    }),
    empty: (0, _emberCliMirage.trait)({
      country_only: true
    })
  });

  _exports.default = _default;
});