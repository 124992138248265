define("authentication/services/jwt_session", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function jwt_claims(jwt) {
    return JSON.parse(window.atob(jwt.split('.')[1]));
  }

  var _default = Ember.ObjectProxy.extend({
    init() {
      this._super(...arguments);

      this.claims = jwt_claims(this.token);
    },

    iat() {
      return this.claims.iat * 1000;
    },

    exp() {
      return this.claims.exp * 1000;
    },

    halflife() {
      return (this.exp() - this.iat()) / 2;
    }

  });

  _exports.default = _default;
});