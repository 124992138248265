define("shared/components/product-quantity-selector/component", ["exports", "shared/components/product-quantity-selector/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['item', 'product-quantity-selector', 'component'],
    classNameBindings: ['direction'],
    layout: _template.default,
    direction: "vertical",
    allow_edit: true,
    allow_remove: true,
    allow_minimize: true,
    allow_increment: true,
    allow_decrement: true,
    quantity_text: "Quantity",
    remove_text: "Remove",
    locked_text: "Locked",
    edit_text: "Edit",

    init() {
      this._super(...arguments);

      if (this.get('allow_edit') && Ember.isBlank(this.get('edit'))) {
        throw new Error("allow_edit is true, but an edit action wasn't passed in");
      }

      if (this.get('allow_remove') && Ember.isBlank(this.get('remove'))) {
        throw new Error("allow_remove is true, but a remove action wasn't passed in");
      }

      if (this.get('allow_minimize') && Ember.isBlank(this.get('minimize'))) {
        throw new Error("allow_minimize is true, but a minimize action wasn't passed in");
      }

      if (this.get('allow_increment') && Ember.isBlank(this.get('increment'))) {
        throw new Error("allow_increment is true, but an increment action wasn't passed in");
      }

      if (this.get('allow_decrement') && Ember.isBlank(this.get('decrement'))) {
        throw new Error("allow_decrement is true, but a decrement action wasn't passed in");
      }
    },

    can_edit: Ember.computed('allow_edit', 'order_selection.is_locked', 'order_selection.has_choices', function () {
      if (!this.get('allow_edit')) {
        return false;
      }

      if (this.get('order_selection.is_locked')) {
        return false;
      }

      if (!this.get('order_selection.has_choices')) {
        return false;
      }

      return true;
    }),
    can_minimize: Ember.computed('allow_minimize', 'order_selection.validations.isValid', function () {
      if (!this.get('allow_minimize')) {
        return false;
      }

      if (this.get('order_selection.validations.isValid')) {
        return true;
      }

      return false;
    }),
    can_remove: Ember.computed('allow_remove', 'order_selection.is_locked', function () {
      if (!this.get('allow_remove')) {
        return false;
      }

      if (this.get('order_selection.is_locked')) {
        return false;
      } // If its unmatched, they can remove


      return true;
    }),
    can_change_quantity: Ember.computed('order_selection.is_locked', 'allow_increment', 'allow_decrement', function () {
      if (this.get('order_selection.is_locked')) {
        return false;
      }

      if (this.get('allow_increment') || this.get('allow_decrement')) {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});