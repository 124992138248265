define("order-locking/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.create({
    name: 'order-locking',

    order_filters() {
      return {
        groupName: 'Locking',
        options: [Ember.Object.create({
          id: 'locked',
          title: 'Locked'
        }), Ember.Object.create({
          id: 'not_locked',
          title: 'Not Locked'
        }), Ember.Object.create({
          id: 'partially_locked',
          title: 'Partially Locked'
        }), Ember.Object.create({
          id: 'not_partially_locked',
          title: 'Not Partially Locked'
        })]
      };
    },

    order_actions() {
      return Ember.computed('order.locked', 'order.locked.@each.process', 'order.selections.@each.locked', function () {
        let actions = [];
        let locked = this.get('order.locked');

        if (Ember.isPresent(locked)) {// Order Unlock is part of the main view right now
          //
          // actions.push(Ember.Object.create({
          //   title: 'Unlock',
          //   click: function() {
          //     this.send('open_modal', 'confirm-modal', {
          //       title: 'Unlock',
          //       question: `Are you sure you want to unlock all selections?`,
          //       description: 'This action is irreversible.',
          //       approve: () => {
          //         return this.get('order').unlockAll();
          //       }
          //     });
          //   }
          // }));
        } else if (this.get('order.selections').any(os => {
          return Ember.isPresent(os.get('locked'));
        })) {
          actions.push(Ember.Object.create({
            title: 'Unlock selections',
            click: function () {
              this.send('open_modal', 'confirm-modal', {
                i18nKey: 'unlock-selections',
                approve: () => {
                  return this.get('order').unlockAll();
                }
              });
            }
          }));
        }

        return actions;
      });
    },

    order_selection_actions() {
      return Ember.computed('order_selection.{locked,product.name}', 'order_selection.locked.@each.process', function () {
        let actions = [];
        let locked = this.get('order_selection.locked');

        if (Ember.isBlank(locked)) {
          actions.push(Ember.Object.create({
            title: 'Lock',
            click: function () {
              this.send('open_modal', 'confirm-modal', {
                i18nKey: 'lock-selection',
                i18nParams: {
                  product: this.get('order_selection.product.name')
                },
                approve: () => {
                  return this.get('order_selection').lock('manual');
                }
              });
            }
          }));
        } else if (Ember.isPresent(locked)) {
          for (var i = 0; i < locked.get('length'); i++) {
            let lock = locked.objectAt(i);
            let process = lock.get('process');
            let icon = '';

            if (process === 'ship') {
              icon = 'truck';
            }

            if (process === 'export') {
              icon = 'file excel';
            }

            if (process === 'digital') {
              icon = 'cloud download';
            }

            actions.push(Ember.Object.create({
              title: `Remove ${lock.get('process')} lock`,
              icon: icon,
              click: function () {
                this.send('open_modal', 'confirm-modal', {
                  i18nKey: 'remove-lock',
                  i18nParams: {
                    process: lock.get('process'),
                    product: this.get('order_selection.product.name')
                  },
                  approve: () => {
                    return this.get('order_selection').unlock(lock.get('process'));
                  }
                });
              }
            }));
          }

          if (locked.get('length') > 1) {
            actions.push(Ember.Object.create({
              title: 'Remove all locks',
              icon: 'lock open',
              click: function () {
                this.send('open_modal', 'confirm-modal', {
                  i18nKey: 'remove-all-locks',
                  i18nParams: {
                    product: this.get('order_selection.product.name')
                  },
                  approve: () => {
                    return this.get('order_selection').unlockAll();
                  }
                });
              }
            }));
          }
        }

        return actions;
      });
    }

  });

  _exports.default = _default;
});