define("shared/mirage/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "purpose": {
      "pledge_management": {
        "phrase": {
          "order": "Pledge",
          "customer": "Backer",
          "checkout": "Survey"
        },
        "lines": {
          "reward": "Reward",
          "external": "External Purchase",
          "extra": "Extra",
          "shipping": "Shipping",
          "manual": "Manually Added"
        },
        "source": {
          "kickstarter": "Kickstarter",
          "indiegogo": "Indiegogo",
          "fig": "Fig",
          "celery": "Celery",
          "squarespace": "Squarespace",
          "shopify": "Shopify",
          "other": "{name}",
          "crowdox": "Late Pledge",
          "store": "Online Store",
          "bgg": "BGG Pledge"
        }
      },
      "pre_order": {
        "phrase": {
          "order": "Pre-Order",
          "customer": "Customer",
          "checkout": "Checkout"
        },
        "lines": {
          "reward": "Bundle",
          "external": "External Purchase",
          "extra": "Extra",
          "shipping": "Shipping",
          "manual": "Manually Added"
        },
        "source": {
          "kickstarter": "Kickstarter",
          "indiegogo": "Indiegogo",
          "fig": "Fig",
          "celery": "Celery",
          "squarespace": "Squarespace",
          "shopify": "Shopify",
          "other": "{name}",
          "crowdox": "Pre-Order",
          "store": "Online Store",
          "bgg": "BGG Direct"
        }
      },
      "ecommerce": {
        "phrase": {
          "order": "Order",
          "customer": "Customer",
          "checkout": "Checkout"
        },
        "lines": {
          "reward": "Bundle",
          "external": "External Purchase",
          "extra": "Extra",
          "shipping": "Shipping",
          "manual": "Manually Added"
        },
        "source": {
          "kickstarter": "Kickstarter",
          "indiegogo": "Indiegogo",
          "fig": "Fig",
          "celery": "Celery",
          "squarespace": "Squarespace",
          "shopify": "Shopify",
          "other": "{name}",
          "crowdox": "Online Store",
          "store": "Online Store",
          "bgg": "BGG Direct"
        }
      }
    }
  };
  _exports.default = _default;
});