define("helpdocs/mixins/application-route", ["exports", "manage/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    user: Ember.inject.service(),

    userLoaded() {
      this._super(...arguments);

      this.set('user.lighthouseEnabled', _environment.default.lighthouse.enabled);
    },

    actions: {
      showLighthouse() {
        window.Lighthouse.showButton();
        window.Lighthouse.show();
      }

    }
  });

  _exports.default = _default;
});