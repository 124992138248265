define("shared/mirage/factories/order", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    external_id: '812345679',
    invited: (0, _emberCliMirage.trait)({
      invite_sent_on: () => {
        let date = new Date();
        date.setDate(date.getDate() - 5);
        return date.toISOString();
      }
    }),
    canceled: (0, _emberCliMirage.trait)({
      canceled_on: () => new Date().toISOString()
    })
  });

  _exports.default = _default;
});