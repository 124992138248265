define("shared/components/product-configuration-wrapper/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dsM3XlFD",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n\"],[4,\"unless\",[[24,[\"is_minimized\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n      \"],[1,[24,[\"order_selection\",\"display_name\"]],false],[0,\" Selection \"],[4,\"if\",[[24,[\"configuration_number\"]]],null,{\"statements\":[[0,\"#\"],[1,[22,\"configuration_number\"],false]],\"parameters\":[]},null],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[\"ui items \",[28,\"if\",[[24,[\"is_minimized\"]],\"minimal\"],null]]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"is_minimized\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"product-configuration-view\",null,[[\"order_selection\",\"show_details\"],[[24,[\"order_selection\"]],[24,[\"show_details\"]]]],{\"statements\":[[0,\"\\n        \"],[14,1,[[24,[\"is_minimized\"]],[28,\"action\",[[23,0,[]],\"set_collapsed\"],null]]],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n      \"],[14,1,[[24,[\"is_minimized\"]],[28,\"action\",[[23,0,[]],\"set_collapsed\"],null]]],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/product-configuration-wrapper/template.hbs"
    }
  });

  _exports.default = _default;
});