define("shared/utils/computed-default-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.computedDefaultSafeString = computedDefaultSafeString;
  _exports.computedDefaultValue = computedDefaultValue;
  _exports.getDefault = getDefault;

  function getDefault(value, default_value) {
    if (Ember.isBlank(value)) {
      value = default_value;
    }

    return value;
  }

  function computedDefaultValue(property, default_value) {
    return Ember.computed(property, {
      get() {
        let value = this.get(property);
        return getDefault(value, default_value);
      },

      set(key, value) {
        this.set(property, value);
        return this.get(property);
      }

    });
  }

  function computedDefaultSafeString(property, default_value) {
    return Ember.computed(property, function () {
      let value = this.get(property);
      return Ember.String.htmlSafe(getDefault(value, default_value));
    });
  }
});