define("shared/helpers/a-or-an", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.aOrAn = aOrAn;
  _exports.default = void 0;

  // This function receives the params `params, hash`
  function aOrAn(_ref) {
    let [value] = _ref;
    var ref;

    if (ref = value[0].toLowerCase(), ['a', 'e', 'i', 'o', 'u'].indexOf(ref) >= 0) {
      return 'an';
    } else {
      return 'a';
    }
  }

  var _default = Ember.Helper.helper(aOrAn);

  _exports.default = _default;
});