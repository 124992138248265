define("shared/tests/helpers/model-helpers", ["exports", "ember-data", "ember-inflector", "shared/utils/logger", "@ember-data/model", "@ember-data/adapter/json-api"], function (_exports, _emberData, _emberInflector, _logger, _model, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.load_store = void 0;
  // Hack to get dynamic access to exports
  let exports = null;
  eval('exports = _exports');

  const next_id = function (store, modelName) {
    let ids = store.peekAll(modelName).mapBy('id');
    ids.push(0);
    return Math.max(...ids) + 1;
  };

  const build_record = function (store, modelName) {
    let data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    let merge_data = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    data = Ember.assign(data, merge_data);
    return store.createRecord(modelName, data);
  };

  const create_record = function (store, modelName) {
    let data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    let merge_data = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    let id = next_id(store, modelName);
    data['id'] = id;
    data = Ember.assign(data, merge_data);
    let json_data = {
      id: null,
      type: modelName,
      attributes: {},
      relationships: {}
    };

    for (let key of Object.keys(data)) {
      if (key === "id") {
        json_data.id = data[key];
        continue;
      }

      let value = data[key];

      if (value == null) {
        continue;
      }

      if (Ember.PromiseProxyMixin.detect(value)) {
        value = value.get('content');
      }

      let dasherized_key = Ember.String.dasherize(key);

      if (value instanceof _model.default) {
        if (value.get('_isFragment')) {
          json_data.attributes[dasherized_key] = value.toJSON();
        } else {
          json_data.relationships[dasherized_key] = {
            data: {
              type: (0, _emberInflector.pluralize)(value.get('_internalModel.modelName')),
              id: value.get('id')
            }
          };
        }

        continue;
      }

      if (value instanceof Date) {
        json_data.attributes[dasherized_key] = value.toISOString();
        continue;
      }

      if (["string", "boolean", "number"].includes(typeof value) === false) {
        (0, _logger.logger_warn)(`Unexpected type in create_record of:${typeof value}`);
      }

      json_data.attributes[dasherized_key] = value;
    }

    store.pushPayload(modelName, {
      data: json_data
    });
    return store.peekRecord(modelName, json_data.id);
  };

  const load_store = function () {
    let container = this.owner || this.container;
    let adapterFactory = container.factoryFor('adapter:application');

    if (!adapterFactory) {
      if (window.requirejs.entries['ember-data/adapters/json-api']) {
        adapterFactory = window.require('ember-data/adapters/json-api')['default'];
      } // when ember-data/adapters/json-api is provided via ember-cli shims
      // using Ember Data 1.x the actual JSONAPIAdapter isn't found, but the
      // above require statement returns a bizzaro object with only a `default`
      // property (circular reference actually)


      if (!adapterFactory || !adapterFactory.create) {
        adapterFactory = _jsonApi.default || _emberData.default.FixtureAdapter;
      }

      let registry = this.owner || this.registry;
      registry.register('adapter:application', adapterFactory);
    }

    this.store = container.lookup('service:store') || container.lookup('store:main');
  };

  _exports.load_store = load_store;
  const operations = ['build', 'create'];

  for (const operation of operations) {
    const perform_record = operation === 'build' ? build_record : create_record;

    exports[operation + '_project'] = function (store) {
      let extra_data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return perform_record(store, 'project', {
        unit_of_mass: 'kg',
        shipping_type: 'product_price',
        currency: 'USD',
        purpose: 'pledge_management'
      }, extra_data);
    };

    exports[operation + '_project_translation'] = function (store, project) {
      let extra_data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return perform_record(store, 'project-translation', {
        project,
        locale: 'en'
      }, extra_data);
    };

    exports[operation + '_project_source'] = function (store, project, site) {
      let extra_data = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      return perform_record(store, 'project-source', {
        project,
        site,
        status: 'live'
      }, extra_data);
    };

    exports[operation + '_project_configuration'] = function (store, project, source, product_reward, product_extra) {
      let extra_data = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : {};
      return perform_record(store, 'project-configuration', {
        project,
        source,
        product_reward,
        product_extra
      }, extra_data);
    };

    exports[operation + '_project_address'] = function (store, project) {
      let extra_data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return perform_record(store, 'project-address', {
        project
      }, extra_data);
    };

    exports[operation + '_project_zone'] = function (store, project, location_type, cost_type) {
      let extra_data = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
      return perform_record(store, 'project-zone', {
        project,
        cost_type,
        location_type
      }, extra_data);
    };

    exports[operation + '_project_zone_location'] = function (store, project, zone, cost_type) {
      let extra_data = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
      return perform_record(store, 'project-zone-location', {
        project,
        zone,
        cost_type
      }, extra_data);
    };

    exports[operation + '_customer'] = function (store, project) {
      let extra_data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return perform_record(store, 'customer', {
        project: project
      }, extra_data);
    };

    exports[operation + '_order'] = function (store, project, customer, source) {
      let extra_data = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
      return perform_record(store, 'order', {
        project,
        customer,
        source
      }, extra_data);
    };

    exports[operation + '_order_address'] = function (store, order) {
      let set_default = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      let extra_data = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      let address = perform_record(store, 'order-address', {
        project: order.get('project'),
        order
      }, extra_data);

      if (set_default) {
        order.set('shipping_address', address);
      }

      return address;
    };

    exports[operation + '_order_transaction'] = function (store, order) {
      let extra_data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return perform_record(store, 'order-transaction', {
        project: order.get('project'),
        currency: order.get('project.currency'),
        order
      }, extra_data);
    };

    exports[operation + '_order_line'] = function (store, order) {
      let extra_data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return perform_record(store, 'order-line', {
        project: order.get('project'),
        order,
        line_type: 'reward',
        sort_order: 1
      }, extra_data);
    };

    exports[operation + '_order_selection'] = function (store, order, order_line) {
      let extra_data = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      return perform_record(store, 'order-selection', {
        project: order.get('project'),
        order,
        line: order_line,
        quantity: 1
      }, extra_data);
    };

    exports[operation + '_order_selection_question'] = function (store, order, order_selection) {
      let extra_data = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      return perform_record(store, 'order-selection-question', {
        project: order.get('project'),
        order,
        selection: order_selection
      }, extra_data);
    };

    exports[operation + '_product'] = function (store, project, product_type) {
      let extra_data = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      let skip_variations = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
      let product = perform_record(store, 'product', {
        project,
        product_type
      }, extra_data);

      if (['physical', 'digital', 'shipping'].includes(product_type) && skip_variations === false) {
        exports[operation + '_product_variation'](store, product, {
          is_default: true,
          has_variants: false
        });
      }

      return product;
    };

    exports[operation + '_product_question'] = function (store, product, extra_data) {
      return perform_record(store, 'product-question', {
        project: product.get('project'),
        product,
        required: false,
        sort_order: 1,
        text: 'A Question',
        question_type: 'text',
        sub_type: 'any'
      }, extra_data);
    };

    exports[operation + '_product_variant'] = function (store, product) {
      let extra_data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return perform_record(store, 'product-variant', {
        project: product.get('project'),
        product
      }, extra_data);
    };

    exports[operation + '_product_variant_value'] = function (store, product, variant) {
      let extra_data = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      return perform_record(store, 'product-variant-value', {
        project: product.get('project'),
        product,
        variant
      }, extra_data);
    };

    exports[operation + '_product_variation'] = function (store, product) {
      let extra_data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      let variant_value_combinations = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
      let variation = perform_record(store, 'product-variation', {
        project: product.get('project'),
        product,
        enabled: true,
        is_default: false,
        has_variants: true
      }, extra_data);

      if (variant_value_combinations != null && variant_value_combinations.length > 0) {
        let details_data = store.createFragment('product-details');
        variation.set('details_data', details_data);

        for (let variant_value of variant_value_combinations) {
          let variant = variant_value.get('variant.content') || variant_value.get('variant');
          details_data.get('details').createFragment({
            _variant: variant,
            variant_id: variant.get('id'),
            _variant_value: variant_value,
            variant_value_id: variant_value.get('id')
          });
        }
      }

      return variation;
    };

    exports[operation + '_product_mapping'] = function (store, project, parent, child) {
      let extra_data = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
      return perform_record(store, 'product-mapping', {
        project,
        parent,
        child,
        enabled: true,
        is_default: false,
        required_quantity: 1,
        sort_order: 1
      }, extra_data);
    };

    exports[operation + '_product_mapping_with_bundle'] = function (store, project) {
      let extra_product_mapping_data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      let parent_product = exports[operation + '_product'](store, project, 'bundle');
      let child_product = exports[operation + '_product'](store, project, 'physical');
      return exports[operation + '_product_mapping'](store, project, parent_product, child_product, extra_product_mapping_data);
    };

    exports[operation + '_product_zone'] = function (store, product, location_type, cost_type) {
      let extra_data = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
      return perform_record(store, 'product-zone', {
        project: product.get('project'),
        product,
        cost_type,
        location_type
      }, extra_data);
    };

    exports[operation + '_product_zone_location'] = function (store, product, zone, cost_type) {
      let extra_data = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
      return perform_record(store, 'product-zone-location', {
        project: product.get('project'),
        product,
        zone,
        cost_type
      }, extra_data);
    };

    exports[operation + '_country'] = function (store) {
      let extra_data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return perform_record(store, 'country', extra_data);
    };

    exports[operation + '_state'] = function (store, country) {
      let extra_data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return perform_record(store, 'state', {
        country
      }, extra_data);
    };

    exports[operation + '_vat_category'] = function (store, project, name, public_id) {
      let extra_data = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
      return perform_record(store, 'vat-category', {
        project: project,
        name,
        public_id
      }, extra_data);
    };
  } // Special helpers


  exports.create_order_with_project = function (store) {
    let site = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'kickstarter';
    let extra_order_data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    let project = exports.create_project(store);
    let customer = exports.create_customer(store, project);
    let source = exports.create_project_source(store, project, site);
    let order = exports.create_order(store, project, customer, source, extra_order_data);
    return order;
  };

  exports.build_order_with_project = function (store) {
    let site = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'kickstarter';
    let extra_order_data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    let project = exports.create_project(store);
    let customer = exports.create_customer(store, project);
    let source = exports.create_project_source(store, project, site);
    let order = exports.build_order(store, project, customer, source, extra_order_data);
    return order;
  };
});