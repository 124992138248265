define("ember-promise-tools/utils/smart-resolve", ["exports", "ember-promise-tools/utils/is-promise", "ember-promise-tools/utils/is-fulfilled", "ember-promise-tools/utils/get-promise-content"], function (_exports, _isPromise, _isFulfilled, _getPromiseContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(maybePromise) {
    if (!(0, _isPromise.default)(maybePromise)) {
      // Not a promise, return value
      return maybePromise;
    }

    if (!(0, _isFulfilled.default)(maybePromise)) {
      // Promise is still pending, return promise
      return maybePromise;
    } // Try to unwrap promise and get content;


    return (0, _getPromiseContent.default)(maybePromise);
  }
});