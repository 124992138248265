define("shipment-tracking/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.create({
    name: 'shipment-tracking',

    order_actions() {
      return Ember.computed('order.{id,approved_on}', 'order.locked.@each.process', function () {
        let actions = [];
        actions.push(Ember.Object.create({
          title: 'View Pick List',
          click: function () {
            this.send('open_modal', 'shipment-tracking/pick-list-modal', {
              order: this.get('order')
            });
          }
        }));

        if (Ember.isPresent(this.get('order.approved_on'))) {
          if (Ember.isBlank(this.get('order.locked')) || !this.get('order.locked').isAny('process', 'ship')) {
            actions.push(Ember.Object.create({
              title: 'Mark as Shipped',
              click: function () {
                this.send('open_modal', 'confirm-modal', {
                  i18nKey: 'mark-order-as-shipped',
                  i18nParams: {
                    order_id: this.get('order.id')
                  },
                  approve: () => {
                    let order = this.get('order');
                    return order.lock('ship');
                  }
                });
              }
            }));
          } else if (Ember.isPresent(this.get('order.locked')) && this.get('order.locked').isAny('process', 'ship')) {// Unlocking in part of the Order Locking component
          }
        }

        return actions;
      });
    },

    order_selection_actions() {
      return Ember.computed('order_selection.{name,locked,order.approved_on}', 'order.locked.@each.process', function () {
        let actions = [];

        if (Ember.isPresent(this.get('order_selection.order.approved_on'))) {
          if (Ember.isBlank(this.get('order_selection.locked')) || !this.get('order_selection.locked').isAny('process', 'ship')) {
            actions.push(Ember.Object.create({
              title: 'Mark as Shipped',
              click: function () {
                this.send('open_modal', 'confirm-modal', {
                  i18nKey: 'mark-as-shipped',
                  i18nParams: {
                    product: this.get('order_selection.name')
                  },
                  approve: () => {
                    let orderSelection = this.get('order_selection');
                    return orderSelection.lock('ship');
                  }
                });
              }
            }));
          } else if (Ember.isPresent(this.get('order_selection.locked')) && this.get('order_selection.locked').isAny('process', 'ship')) {// Unlocking in part of the Order Locking component
          }
        }

        return actions;
      });
    },

    order_filters() {
      return {
        groupName: 'Shipment',
        options: [Ember.Object.create({
          id: 'shipment_tracking_shipped',
          title: 'Shipped'
        }), Ember.Object.create({
          id: 'shipment_tracking_not_shipped',
          title: 'Not Shipped'
        })]
      };
    }

  });

  _exports.default = _default;
});