define("shared/components/ui-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WdF3MRLH",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[24,[\"is_error\"]]]],[0,\"\\n\"],[4,\"if\",[[24,[\"show_error\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"is_error\"]]],null,{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"ui basic red \",[28,\"if\",[[24,[\"pointing\"]],\"pointing\"],null],\" prompt label transition visible\"]]],[8],[0,\"\\n    \"],[1,[28,\"get\",[[28,\"get\",[[28,\"get\",[[28,\"get\",[[24,[\"model\"]],\"validations\"],null],\"attrs\"],null],[24,[\"property\"]]],null],\"message\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/ui-field/template.hbs"
    }
  });

  _exports.default = _default;
});