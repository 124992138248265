define("shared/tests/helpers/create-zone-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(project, product, location_type, cost_type, shipping_cents) {
    let country = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : null;
    let state = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : null;
    let zone = server.create('product-zone', {
      shipping_cents: shipping_cents,
      project: project,
      product: product,
      location_type,
      cost_type
    });
    return server.create('product-zone-location', {
      project: project,
      product: product,
      zone: zone,
      cost_type,
      country: country,
      state: state
    });
  }
});