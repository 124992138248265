define("shared/components/product-configuration-variations/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NP2n/wLs",
    "block": "{\"symbols\":[\"variant\"],\"statements\":[[4,\"if\",[[24,[\"order_selection\",\"product\",\"variants\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"item product-configuration-variations component\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"order_selection\",\"product\",\"variants_sorted\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"field\"],[11,\"data-test-product-variant\",[29,[[23,1,[\"id\"]]]]],[8],[0,\"\\n\"],[4,\"unless\",[[23,1,[\"use_inline_name\"]]],null,{\"statements\":[[0,\"            \"],[7,\"label\",true],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"          \"],[1,[28,\"product-configuration-variant\",null,[[\"variant\",\"order_selection\",\"selected_variant_values\",\"submitted\",\"disabled\",\"allow_disabled\",\"onChange\"],[[23,1,[]],[24,[\"order_selection\"]],[24,[\"selected_variant_values\"]],[24,[\"submitted\"]],[24,[\"disabled\"]],[24,[\"allow_disabled\"]],[28,\"action\",[[23,0,[]],[24,[\"set_variant_value\"]]],null]]]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/product-configuration-variations/template.hbs"
    }
  });

  _exports.default = _default;
});