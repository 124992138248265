define("authentication/mixins/session-refresh", ["exports", "ember-concurrency", "@sentry/browser", "jquery", "authentication/services/jwt_session"], function (_exports, _emberConcurrency, Sentry, _jquery, _jwt_session) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    session: Ember.inject.service('session'),
    maintainSession: false,

    maintainTokenSession() {
      if (this.get('session.data.authenticated.type') !== 'token') {
        return;
      }

      this.set('maintainSession', true);
      this.get('maintainTokenSessionTask').perform();

      _jquery.default.ajaxPrefilter(Ember.run.bind(this, this.ajaxPrefilter));
    },

    ajaxPrefilter(options, originalOptions, jqXHR) {
      let e = this;
      let defaultOptions = {
        maxRetries: 1
      }; // Don't infinitely recurse

      originalOptions._retry = (isNaN(originalOptions._retry) ? defaultOptions.maxRetries : originalOptions._retry) - 1; // Store _maxRetries, defaulting to the value of _retry

      originalOptions._maxRetries = originalOptions._maxRetries || originalOptions._retry; // Save the original error callback for later

      if (originalOptions.error) {
        originalOptions._error = originalOptions.error;
      } // Overwrite *current request* error callback


      options.error = _jquery.default.noop(); // Setup our own deferred object to also support promises that are only invoked
      // once all of the retry attempts have been exhausted

      var dfd = _jquery.default.Deferred();

      jqXHR.done(dfd.resolve); // if the request fails, do something else yet still resolve

      jqXHR.fail(function () {
        var args = Array.prototype.slice.call(arguments);
        var retriesRemaining = originalOptions._retry;
        var retries = defaultOptions.maxRetries - retriesRemaining - 1;
        var httpStatus = jqXHR.status;

        if (e.get('maintainSession') && httpStatus === 401 && retriesRemaining > 0) {
          return Ember.run.later(() => {
            e.get('refreshAndPersistSessionTask').perform().then(() => {
              _jquery.default.ajax(originalOptions).then(dfd.resolve, dfd.reject);
            });
          }, 1000 * retries);
        } else {
          // Add our _error callback to our promise object
          if (originalOptions._error) {
            dfd.fail(originalOptions._error);
          }

          dfd.rejectWith(jqXHR, args);
        }
      }); // Override the jqXHR's promise functions with our deferred

      return dfd.promise(jqXHR);
    },

    refreshAt: Ember.computed('session.data.authenticated.token', 'session.data.authenticated.type', function () {
      const data = this.get('session.data');

      if (Ember.isEmpty(data.authenticated) || data.authenticated.type !== 'token' || Ember.isBlank(data.authenticated.token)) {
        return Infinity;
      }

      const session = _jwt_session.default.create({
        token: data.authenticated.token
      });

      return session.iat() + session.halflife();
    }),

    get maintainTokenNextRefresh() {
      const refreshAt = this.get('refreshAt');
      const now = new Date().getTime();
      return refreshAt - now;
    },

    maintainRefreshCount: 0,
    maintainTokenSessionTask: (0, _emberConcurrency.task)(function* () {
      do {
        if (this.get('session.data.authenticated.type') !== 'token') {
          this.set('maintainSession', false);
          break;
        }

        let refreshIn = this.get('maintainTokenNextRefresh');

        if (refreshIn <= 0) {
          refreshIn = yield this.get('refreshAndPersistSessionTask').perform(); // This should never happen. But if it does it means we could
          // get caught in an infinite loop. In those cases we just break
          // the loop.

          if (refreshIn <= 0) {
            this.set('maintainSession', false);
            break;
          }
        }

        if (!Ember.testing) {
          yield (0, _emberConcurrency.timeout)(refreshIn);
        }
      } while (this.get('maintainSession') && !Ember.testing);
    }).drop(),
    refreshTokenSessionTask: (0, _emberConcurrency.task)(function* () {
      const config = Ember.getOwner(this).resolveRegistration('config:environment');

      try {
        const response = yield this.get('ajax').request(`${config.urls.auth}/session/refresh`);
        return (response.result || {}).id_token;
      } catch (error) {
        // This mixin is mixed into the user service where the user object is set to 'content'
        Sentry.captureException(error, {
          extra: {
            user: this.get('content')
          }
        });
        return null;
      }
    }),
    refreshAndPersistSessionTask: (0, _emberConcurrency.task)(function* () {
      const token = yield this.get('refreshTokenSessionTask').perform();
      this.set('session.data.authenticated.token', token);
      let newSession = this.get('session.data');
      this.get('session.store').persist(newSession);
      return this.get('maintainTokenNextRefresh');
    })
  });

  _exports.default = _default;
});