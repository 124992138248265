define("shared/tests/helpers/create-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(name, abbr, country) {
    return server.create('state', {
      name: name,
      abbr: abbr,
      country: country
    });
  }
});