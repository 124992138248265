define("shared/helpers/t-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute(_ref, args) {
      let [key] = _ref;

      if (Ember.isEmpty(key)) {
        return null;
      }

      if (typeof key !== 'string') {
        return null;
      }

      return this.get('intl').formatMessage(key, args);
    }

  });

  _exports.default = _default;
});