define("shared/utils/load-geography", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = loadGeography;

  function loadGeography(store) {
    return Ember.RSVP.all([store.query('country', {
      page: {
        size: 250
      }
    }), store.query('state', {
      page: {
        size: 250
      }
    })]);
  }
});