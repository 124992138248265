define("shared/components/show-more-text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cuB1RYt3",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"has_more_and_not_showing\"]]],null,{\"statements\":[[0,\"  \"],[7,\"a\",false],[12,\"class\",\"display more\"],[12,\"style\",[22,\"display_more_style\"]],[3,\"action\",[[23,0,[]],\"show_more\"]],[8],[0,\"\\n    Show more\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/show-more-text/template.hbs"
    }
  });

  _exports.default = _default;
});