define("shipment-tracking/components/ship-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yWVKEsS/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  Shipment Tracking\\n  \"],[7,\"div\",false],[12,\"class\",\"ui close button\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"ui form basic segment content \",[28,\"if\",[[24,[\"saveTask\",\"isRunning\"]],\"loading\"],null]]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"error\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"ui negative message\"],[8],[0,\"\\n      \"],[1,[22,\"error\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  Are you sure you wish to mark Order #\"],[1,[24,[\"order\",\"id\"]],false],[0,\" as shipped?\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"ui button cancel\"],[12,\"data-test-cancel\",\"\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[0,\"Cancel\"],[9],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"ui button positive\"],[12,\"data-test-ok\",\"\"],[3,\"action\",[[23,0,[]],\"approve\"]],[8],[0,\"Shipped!\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shipment-tracking/components/ship-modal/template.hbs"
    }
  });

  _exports.default = _default;
});