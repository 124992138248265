define("shared/services/resize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    _oldWidthDebounced: null,
    _oldHeightDebounced: null,

    init() {
      this._super(...arguments);

      this._onResizeHandler = evt => {
        Ember.run.debounce(this, this._fireDebouncedResizeNotification, evt, 500);
      };

      window.addEventListener('resize', this._onResizeHandler);
    },

    destory() {
      this._super(...arguments);

      window.removeEventListener('resize', this._onResizeHandler);
    },

    _hasWindowSizeChanged(w, h) {
      return w !== this.get('_oldWidthDebounced') || h !== this.get('_oldHeightDebounced');
    },

    _fireDebouncedResizeNotification(evt) {
      const {
        innerWidth,
        innerHeight
      } = window;

      if (this._hasWindowSizeChanged(innerWidth, innerHeight)) {
        this.trigger('resize', evt);
        this.setProperties({
          _oldWidthDebounced: innerWidth,
          _oldHeightDebounced: innerHeight
        });
      }
    }

  });

  _exports.default = _default;
});