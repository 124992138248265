define("shared/mixins/common-theme", ["exports", "shared/utils/image", "shared/utils/computed-default-value", "@ember-data/model"], function (_exports, _image, _computedDefaultValue, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // Text
    name: (0, _model.attr)('string'),
    tagline: (0, _model.attr)('string'),
    // Images (Strings)
    logo_image_path: (0, _model.attr)('string'),
    hero_image_path: (0, _model.attr)('string'),
    project_name_image_path: (0, _model.attr)('string'),
    // Image Sources
    // Possibly convert to png through imgix?
    logo_image_src: (0, _image.default)('logo_image_path'),
    // Possibly convert to jpg from imgix?
    hero_image_src: (0, _image.default)('hero_image_path'),
    project_name_image_src: (0, _image.default)('project_name_image_path'),
    // Styles (Ints)
    hero_background_offset: (0, _model.attr)('number'),
    // desktop
    hero_background_mobile_offset: (0, _model.attr)('number'),
    hero_background_tablet_offset: (0, _model.attr)('number'),
    // Styles Defaults
    default_hero_background_offset: (0, _computedDefaultValue.computedDefaultValue)('hero_background_offset', 350),
    default_hero_background_tablet_offset: Ember.computed('hero_background_tablet_offset', 'default_hero_background_offset', function () {
      return (0, _computedDefaultValue.getDefault)(this.get('hero_background_tablet_offset'), this.get('default_hero_background_offset') + 100);
    }),
    default_hero_background_mobile_offset: Ember.computed('hero_background_mobile_offset', 'default_hero_background_offset', function () {
      return (0, _computedDefaultValue.getDefault)(this.get('hero_background_mobile_offset'), this.get('default_hero_background_offset') + 50);
    }),
    // Styles (Strings)
    hero_background_size: (0, _model.attr)('string'),
    // Styles Defaults
    default_hero_background_size: (0, _computedDefaultValue.computedDefaultSafeString)('hero_background_size', 'auto 100%'),
    // Colors (Strings)
    hero_background_color: (0, _model.attr)('string'),
    branding_background_color: (0, _model.attr)('string'),
    branding_border_color: (0, _model.attr)('string'),
    header_text_color: (0, _model.attr)('string'),
    header_subtext_color: (0, _model.attr)('string'),
    // Colors Default
    default_hero_background_color: (0, _computedDefaultValue.computedDefaultSafeString)('hero_background_color', '#0B090A'),
    default_branding_background_color: (0, _computedDefaultValue.computedDefaultSafeString)('branding_background_color', '#0B090A'),
    default_branding_border_color: (0, _computedDefaultValue.computedDefaultSafeString)('branding_border_color', '#3B3B3B'),
    default_header_text_color: (0, _computedDefaultValue.computedDefaultSafeString)('header_text_color', '#FFFFFF'),
    default_header_subtext_color: (0, _computedDefaultValue.computedDefaultSafeString)('header_subtext_color', '#D7A88D'),
    // Custom
    custom_css: (0, _model.attr)('string')
  });

  _exports.default = _default;
});