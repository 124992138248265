define("order-balances/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.create({
    name: 'order-balances',

    order_filters() {
      return {
        groupName: 'Balances',
        options: [Ember.Object.create({
          id: 'balance_zero',
          title: 'Zero Balance'
        }), Ember.Object.create({
          id: 'balance_positive',
          title: 'Balance: Owes'
        }), Ember.Object.create({
          id: 'balance_negative',
          title: 'Balance: Excess Funds'
        })]
      };
    }

  });

  _exports.default = _default;
});