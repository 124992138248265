define("shared/utils/logger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.logger_error = logger_error;
  _exports.logger_info = logger_info;
  _exports.logger_warn = logger_warn;

  function logger_info() {
    if (window.console != null && window.console.info != null) {
      window.console.info.apply(null, arguments);
    }
  }

  function logger_warn() {
    if (window.console != null && window.console.warn != null) {
      window.console.warn.apply(null, arguments);
    } else {
      logger_info.apply(arguments);
    }
  }

  function logger_error() {
    if (window.console != null && window.console.error != null) {
      window.console.error.apply(null, arguments);
    } else {
      logger_warn.apply(arguments);
    }
  }
});