define("shared/utils/monetize", ["exports", "big.js", "shared/utils/currencies", "shared/utils/logger"], function (_exports, _big, _currencies, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.convert_dollars_to_cents = convert_dollars_to_cents;
  _exports.convert_from_whole = convert_from_whole;
  _exports.convert_to_whole = convert_to_whole;
  _exports.default = void 0;
  _exports.get_place_from_subunit = get_place_from_subunit;
  _exports.monetize = monetize;
  _exports.subunit_to_unit = subunit_to_unit;

  function subunit_to_unit(currency) {
    return _currencies.default[currency.toLowerCase()].subunit_to_unit;
  }

  function get_place_from_subunit(subunit) {
    let log10 = Math.log10(subunit);
    let base10 = Math.round(log10);
    return Math.pow(10, base10);
  }

  const get_currency_or_forced = function (self, currency_field, forced_base) {
    if (Ember.isPresent(forced_base)) {
      if (isNaN(forced_base)) {
        throw new Error('forced_base is not a number and is present');
      }

      return forced_base;
    }

    if (Ember.isBlank(currency_field)) {
      throw new Error(`currency_field is blank on class: ${self.toString()}`);
    }

    let currency = self.get(currency_field);

    if (Ember.isBlank(currency)) {
      throw new Error(`currency is blank on class: ${self.toString()}, with field: ${currency_field}`);
    }

    let subunit_conversion = subunit_to_unit(currency);
    return get_place_from_subunit(subunit_conversion);
  };

  function convert_to_whole(value, place) {
    if (Ember.isPresent(value)) {
      return parseFloat((0, _big.default)(value || 0).div(place).toFixed(2));
    }

    return null;
  }

  function convert_from_whole(value, place) {
    if (Ember.isPresent(value)) {
      return parseInt(value.times(place), 10);
    }

    return null;
  } // "dollars" and "cents" in the loose sense of the term. This converts the amount
  // from whatever the primary denomination of the currency to its subunit.


  function convert_dollars_to_cents(value, currency) {
    let bigValue = (0, _big.default)(value || 0);
    let subunit_conversion = subunit_to_unit(currency);
    let place = get_place_from_subunit(subunit_conversion);
    return convert_from_whole(bigValue, place);
  }

  function monetize(field) {
    let currency_field = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    let forced_base = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    return Ember.computed(field, 'isFullyDeleted', {
      get() {
        if (this.get('isFullyDeleted') === true) {
          // Its been deleted and would throw an error
          return null;
        }

        let place = get_currency_or_forced(this, currency_field, forced_base);
        return convert_to_whole(this.get(field), place);
      },

      set(name, value) {
        let place = get_currency_or_forced(this, currency_field, forced_base);

        if (Ember.isBlank(value)) {
          this.set(field, null);
        } else {
          let number;

          if (value) {
            try {
              number = (0, _big.default)(value || 0);
            } catch (e) {
              (0, _logger.logger_warn)(`The value: ${value} didn't convert properly to Big number`);
              number = (0, _big.default)(0);
            }
          } else {
            number = (0, _big.default)(0);
          }

          this.set(field, convert_from_whole(number, place));
        }

        return convert_to_whole(this.get(field), place);
      }

    });
  }

  var _default = monetize;
  _exports.default = _default;
});