define("shared/utils/computed-coalesce", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  var slice = Ember.A().slice;

  function _default() {
    var args = arguments;

    var method = function () {
      for (var i = 0, arg; i < args.length; i++) {
        arg = args[i];

        if (!Ember.isEmpty(this.get(arg))) {
          return this.get(arg);
        }
      }

      return null;
    };

    var to_computed = slice.call(args).concat([method]);
    return Ember.computed.apply(this, to_computed);
  }
});