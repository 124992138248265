define("shared/services/intl", ["exports", "ember-intl/services/intl"], function (_exports, _intl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _intl.default.extend({
    formatNumber(value, options, formats) {
      options = this._normalizeOptions(options, value);
      let show_credit = options.show_credit === true; // Intl renders a value of 'true' as 1, which is definitely not what we want here.
      // We set it to null so that later logic treats it as an invalid input.

      if (value === true) {
        value = null;
      }

      if (isNaN(parseInt(value, 10)) && options.empty !== undefined) {
        return options.empty;
      }

      if (show_credit) {
        value = Math.abs(value);
      }

      let result = this._super(value, options, formats);

      if (result === "NaN") {
        return options.empty || "0";
      }

      if (show_credit) {
        return '(' + result + ')';
      } else {
        return result;
      }
    },

    _normalizeOptions(options, value) {
      options = options || {};

      if (options.style === undefined && options.currency !== undefined) {
        options = Object.assign({}, options, {
          style: 'currency'
        });
      } // When rendering currency values, if the value is a whole number, by default render without the fractional part (i.e. without cents)


      if (options.style == 'currency' && options.minimumFractionDigits === undefined && Math.trunc(value) == value) {
        options = Object.assign({}, options, {
          minimumFractionDigits: 0
        });
      }

      return options;
    }

  });

  _exports.default = _default;
});