define("shared/mirage/fixtures/states", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    abbr: "AL",
    name: "Alabama",
    country_iso3: "USA",
    countryId: 1
  }, {
    id: 2,
    abbr: "AK",
    name: "Alaska",
    country_iso3: "USA",
    countryId: 1
  }, {
    id: 40,
    abbr: "NV",
    name: "Nevada",
    country_iso3: "USA",
    countryId: 1
  }, {
    id: 59,
    abbr: "UT",
    name: "Utah",
    country_iso3: "USA",
    countryId: 1
  }, {
    id: 69,
    abbr: "MB",
    name: "Manitoba",
    country_iso3: "CAN",
    countryId: 2
  }];
  _exports.default = _default;
});