define("shared/components/ui-field/component", ["exports", "shared/components/ui-field/template", "jquery"], function (_exports, _template, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['field', 'ui-field-component'],
    classNameBindings: ['is_error:error', 'dynamic:dynamic'],
    attributeBindings: ['style'],
    model: null,
    // Model object being passed in
    property: null,
    // The specific property on the model to look for
    submitted: false,
    show_error: true,
    dynamic: false,
    pointing: true,

    init() {
      this._super(...arguments);

      Ember.defineProperty(this, 'is_error', Ember.computed('submitted', `model.validations.attrs.${this.get('property')}.isTruelyValid`, function () {
        if (this.get('submitted')) {
          let valid = this.get(`model.validations.attrs.${this.get('property')}.isTruelyValid`);

          if (typeof valid === "boolean") {
            return !valid;
          }
        }

        return false;
      }));
    },

    didInsertElement() {
      this._super(...arguments);

      if (this.get('dynamic')) {
        (0, _jquery.default)(this.element).on(`mouseenter.${this.get('elementId')}`, Ember.run.bind(this, this._mouseenter));
        (0, _jquery.default)(this.element).on(`mouseleave.${this.get('elementId')}`, Ember.run.bind(this, this._mouseleave));
      }
    },

    willDestroyElement() {
      this._super(...arguments);

      if (this.get('dynamic')) {
        (0, _jquery.default)(this.element).off(`.${this.get('elementId')}`);
      }
    },

    _mouseenter() {
      (0, _jquery.default)(this.element).addClass('focused');
    },

    _mouseleave() {
      (0, _jquery.default)(this.element).removeClass('focused');
    }

  });

  _exports.default = _default;
});