define("shared/tasks/save-task", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // Need to make this a mixin so we can reference it below by name.
    _saveModelAndChildren: (0, _emberConcurrency.task)(function* (model) {
      let ignore = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Ember.A();
      let delete_only = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      if (Ember.isBlank(ignore)) {
        ignore = Ember.A();
      }

      if (delete_only == null) {
        delete_only = false;
      } // If we aren't deleting only, then save if new


      if (delete_only !== true) {
        if (model.get('isNew')) {
          yield model.save();
        }
      } // This is for the child errors, we always need to throw the first up, so in the end we have a final result


      let firstError = null; // Dynamically save all child relationships

      let hasManyRelationships = Ember.A();
      model.eachRelationship((name, descriptor) => {
        if (descriptor.kind === "hasMany" && !ignore.includes(name)) {
          hasManyRelationships.push(name);
        }
      });

      for (let relationship of hasManyRelationships) {
        let collection = yield model.get(relationship);
        let result = yield this.get('_saveCollection').perform(collection, ignore, delete_only);

        if (Ember.isPresent(result) && Ember.isBlank(firstError)) {
          firstError = result;
        }
      } // Only delete if it is deleted


      if (delete_only === true && model.get('isDeleted') === true || delete_only !== true && model.get('isDirty')) {
        yield model.save().catch(result => {
          // Ignore 404 errors when all we're trying to do is delete the record
          if (model.get('isDeleted') === true && result.errors && result.errors[0] && result.errors[0].status == 404) {
            model.markSaved();
          } else {
            throw result;
          }
        });
      }

      if (Ember.isPresent(firstError)) {
        throw firstError;
      }
    }),
    _saveCollection: (0, _emberConcurrency.task)(function* (collection) {
      let ignore = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Ember.A();
      let delete_only = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      collection = yield collection;
      let collectionResults = Ember.A();

      for (let item of collection.toArray()) {
        collectionResults.push(this.get('_saveModelAndChildren').perform(item, ignore, delete_only));
      }

      let results = yield (0, _emberConcurrency.allSettled)(collectionResults);
      let errors = results.filter(result => result.state !== 'fulfilled').mapBy('reason');

      if (errors.length > 0) {
        throw errors[0];
      }
    }),
    _saveInOrder: (0, _emberConcurrency.task)(function* (entity, relations) {
      let delete_only = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      let firstError = null;

      for (let relation of relations) {
        let collection = yield entity.get(relation.name);
        let result = yield this.get('_saveCollection').perform(collection, relation.ignore, delete_only);

        if (Ember.isPresent(result) && Ember.isBlank(firstError)) {
          firstError = result;
        }
      }

      if (Ember.isPresent(firstError)) {
        throw firstError;
      }
    }),
    _isCollectionDirtry: (0, _emberConcurrency.task)(function* (parentModel, relationName, childRelationName) {
      let collection = yield parentModel.get(relationName);

      for (let item of collection.toArray()) {
        if (item.get('isDirty')) {
          return true;
        }

        if (Ember.isPresent(childRelationName)) {
          let isDirty = yield this.get('_isCollectionDirtry').perform(item, childRelationName);

          if (isDirty) {
            return true;
          }
        }
      }

      return false;
    }),
    _rollbackCollection: (0, _emberConcurrency.task)(function* (parentModel, relationName, childRelationName) {
      let collection = yield parentModel.get(relationName);

      for (let item of collection.toArray()) {
        if (Ember.isPresent(childRelationName)) {
          yield this.get('_rollbackCollection').perform(item, childRelationName);
        }

        item.rollbackAttributes();
        item.rollbackRelationships();
      }
    })
  });

  _exports.default = _default;
});