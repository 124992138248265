define("shared/services/order-tasks", ["exports", "shared/helpers/can-access-feature", "shared/mixins/project-total", "ember-concurrency", "big.js", "shared/utils/has-own-property", "shared/utils/product-mapping-order-selection-filter", "shared/tasks/save-task"], function (_exports, _canAccessFeature, _projectTotal, _emberConcurrency, _big, _hasOwnProperty, _productMappingOrderSelectionFilter, _saveTask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let defaultVATCategory = Ember.Object.create({
    name: "default",
    public_id: "default"
  });
  let shippingVATCategory = Ember.Object.create({
    name: "shipping",
    public_id: "shipping"
  });

  var _default = Ember.Service.extend(_saveTask.default, {
    store: Ember.inject.service(),
    save: (0, _emberConcurrency.task)(function* (order, options) {
      if (Ember.isBlank(options)) {
        options = {};
      } // Set defaults


      if (Ember.isBlank(options.save_non_extras)) {
        options.save_non_extras = true;
      }

      if (Ember.isBlank(options.save_extras)) {
        options.save_extras = true;
      }

      if (Ember.isBlank(options.shipping_address_country_only)) {
        options.shipping_address_country_only = false;
      }

      if (Ember.isBlank(options.save_shipping_address)) {
        options.save_shipping_address = true;
      }

      if (Ember.isBlank(options.validate)) {
        options.validate = true;
      }

      order = yield order;

      if (Ember.isBlank(order)) {
        throw new Error("Order can't be blank");
      }

      let shipping_address;
      let saved_shipping_address = false;

      if (options.save_shipping_address) {
        // If we are saving the address, then reset the country_only to the value passed in
        shipping_address = yield order.get('shipping_address');

        if (Ember.isPresent(shipping_address)) {
          shipping_address.set('country_only', options.shipping_address_country_only);
        } else if (!options.validate) {
          // If we aren't validating (which would show the error), throw an error so its apparent that you are trying to save a shipping address without having one
          throw new Error("Unable to save shipping address because it doesn't exist");
        }
      }

      if (options.validate) {
        let validation_options = {
          validate_non_extras: options.save_non_extras,
          validate_extras: options.save_extras,
          validate_shipping_address: options.save_shipping_address
        };
        let validation_results = yield this.get('validate').perform(order, validation_options); // If we get a validation result, it was invalid

        if (Ember.isPresent(validation_results)) {
          throw validation_results;
        }
      } // If its a test, end here


      if (order.get('is_test')) {
        if (Ember.isPresent(options.step)) {
          order.advance_step(options.step);
        }

        return;
      }

      let wasNew = false;

      if (order.get('isNew')) {
        // If its new, we need to save it before doing anything else
        wasNew = true; // We need to save again below for default trait

        yield order.save();
      } // If the order wasn't new i.e. we just saved, and the current_configuration changed, we need to save first
      // Because the reward_line must match current configuration when we save


      if (!wasNew && order.isRelationshipDirty('current_configuration')) {
        yield order.save();
      }

      let tasks = Ember.A();

      if (options.save_shipping_address) {
        if (shipping_address.get('isDirty')) {
          // a little bit of a hack here, we are trying to determine if the only change is country_only
          if (shipping_address.get('isNew') || shipping_address.get('hasDirtyRelationships')) {
            saved_shipping_address = true;
            tasks.push(shipping_address.save());
          } else {
            // if its not new and it doesn't have dirtry relationships, then check the specific attributes
            let changedAttributes = shipping_address.changedAttributes();
            delete changedAttributes.country_only;

            if (Object.keys(changedAttributes).length > 0) {
              saved_shipping_address = true;
              tasks.push(shipping_address.save());
            }
          }
        }
      }

      let order_lines = yield order.get('lines');
      let filtered_order_lines = order_lines.filter(order_line => !order_line.get('price_data.is_generated'));

      for (let order_line of filtered_order_lines.toArray()) {
        if (!order_line.get('isDeleted')) {
          // Rewards, External, Manual
          if (!options.save_non_extras && !order_line.get('is_extra')) {
            continue;
          }

          if (!options.save_extras && order_line.get('is_extra')) {
            continue;
          }
        } // _saveModelAndChildren comes from the mixin


        tasks.push(this.get('_saveModelAndChildren').perform(order_line));
      }

      let results = yield (0, _emberConcurrency.allSettled)(tasks); // If we're using name-components the full name is built from the backend
      // so we need to reload it. Once we move APIs it should get refreshed automatically

      if (options.save_shipping_address && saved_shipping_address) {
        yield shipping_address.reload();
      }

      if (options.save_shipping_address) {
        // Hack 3, reset the country only to the value passed in. Its cleared after an update
        shipping_address.set('country_only', options.shipping_address_country_only);
      } // If any errors were returned, throw the first one to stop the progress


      let errors = results.filter(result => result.state !== 'fulfilled').mapBy('reason');

      if (errors.length === 0) {
        // Only proceed to the next step, if everything cleared above
        if (Ember.isPresent(options.step)) {
          order.advance_step(options.step);
        }
      } // Attempt to save any remaining order data


      if (wasNew || order.get('isDirty')) {
        yield order.save();
      } // if there was an error somewhere, throw it, so it goes to catch instead of then


      if (errors.length > 0) {
        throw errors[0];
      }
    }),
    validate: (0, _emberConcurrency.task)(function* (order, options) {
      if (Ember.isBlank(options)) {
        options = {};
      }

      if (Ember.isBlank(options.validate_non_extras)) {
        options.validate_non_extras = true;
      }

      if (Ember.isBlank(options.validate_extras)) {
        options.validate_extras = true;
      }

      if (Ember.isBlank(options.validate_shipping_address)) {
        options.validate_shipping_address = true;
      }

      order = yield order;

      if (Ember.isBlank(order)) {
        throw new Error("Order can't be blank");
      }

      let order_lines = yield order.get('lines');
      let filtered_order_lines = order_lines.filterBy('isDeleted', false).filter(order_line => !order_line.get('price_data.is_generated'));
      let order_lines_to_validate = Ember.A();
      let selections_to_validate = Ember.A();
      let questions_to_validate = Ember.A();

      for (let order_line of filtered_order_lines.toArray()) {
        // Rewards, External, Manual
        if (!options.validate_non_extras && !order_line.get('is_extra')) {
          continue;
        }

        if (!options.validate_extras && order_line.get('is_extra')) {
          continue;
        }

        order_lines_to_validate.push(order_line);
        let selections = yield order_line.get('selections');

        for (let selection of selections.filterBy('isDeleted', false).toArray()) {
          selections_to_validate.push(selection);
          let questions = yield selection.get('questions');

          for (let question of questions.filterBy('isDeleted', false).toArray()) {
            questions_to_validate.push(question);
          }
        }
      } // Questions


      let questions_validating = Ember.A();

      for (let question of questions_to_validate) {
        questions_validating.push(this.get('_validateModel').perform(question));
      }

      let question_results = yield (0, _emberConcurrency.all)(questions_validating);
      question_results = Ember.A(question_results).compact(); // Selections

      let selections_validating = Ember.A();

      for (let selection of selections_to_validate) {
        selections_validating.push(this.get('_validateModel').perform(selection));
      }

      let selection_results = yield (0, _emberConcurrency.all)(selections_validating);
      selection_results = Ember.A(selection_results).compact(); // Lines

      let order_lines_validating = Ember.A();

      for (let order_line of order_lines_to_validate) {
        order_lines_validating.push(this.get('_validateModel').perform(order_line));
      }

      let line_results = yield (0, _emberConcurrency.all)(order_lines_validating);
      line_results = Ember.A(line_results).compact(); // Order

      let validation_options = {
        excludes: Ember.A()
      };
      let shipping_address_result;

      if (options.validate_shipping_address) {
        let shipping_address = yield order.get('shipping_address');

        if (Ember.isPresent(shipping_address)) {
          // If its not present, it will get caught on order validation
          shipping_address_result = yield this.get('_validateModel').perform(shipping_address);
        }
      } else {
        validation_options.excludes.push('shipping_address');
      }

      if (!options.validate_non_extras || !options.validate_extras) {
        validation_options.excludes.push('lines');
      }

      let order_result = yield this.get('_validateModel').perform(order, validation_options);
      let all_results = [order_result, shipping_address_result, ...line_results, ...selection_results, ...question_results].compact();

      if (all_results.length > 0) {
        return all_results[0];
      }

      return;
    }),
    _validateModel: (0, _emberConcurrency.task)(function* (model) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (!model.get('isValid')) {
        // Clear the DS errors before validating so we re-hit the backend
        model.get('errors').clear();
      }

      let {
        validations: validations
      } = yield model.validate(options);

      if (!validations.get('isValid')) {
        return validations;
      }

      return;
    }),
    removeEmptyOrderLines: (0, _emberConcurrency.task)(function* (order) {
      order = yield order;

      if (Ember.isBlank(order)) {
        throw new Error("Order can't be blank");
      }

      let order_lines = yield order.get('lines');

      for (let order_line of order_lines.filterBy('isDeleted', false).toArray()) {
        // Never look at generated lines
        if (order_line.get('price_data.is_generated')) {
          continue;
        } // Never look at fixed shipping lines


        if (order_line.get('price_data.is_fixed') && order_line.get('is_shipping')) {
          continue;
        }

        if (order_line.get('is_bundle')) {
          // Don't remove bundle lines
          continue;
        }

        let order_selection = yield order_line.get('selection');

        if (Ember.isBlank(order_selection) || order_selection.get('isDeleted') === true) {
          order_line.deleteRecord();
        }
      }
    }),
    // Move to configuration container possibly once we move to js and can use generator functions
    removeOrderInvalid: (0, _emberConcurrency.task)(function* (order) {
      order = yield order;

      if (Ember.isBlank(order)) {
        throw new Error("Order can't be blank");
      }

      yield this.get('removeEmptyOrderLines').perform(order);
      let current_configuration = yield order.get('current_configuration');
      let configuration_extras = yield current_configuration.get('product_extra');
      let order_lines = yield order.get('lines');

      for (let order_line of order_lines.filterBy('isDeleted', false).toArray()) {
        // Never look at generated lines
        if (order_line.get('price_data.is_generated')) {
          continue;
        } // Clean up every bundle first


        if (order_line.get('is_bundle')) {
          // Since bundles are so rare to be mapped to extras, we aren't checking if the bundle is mapped to extras
          // Just if the bundles selections are valid
          let product_bundle = yield order_line.get('product_bundle');
          yield this.get('removeOrderSelections').perform(product_bundle, order_line, {
            check_quantity: true
          });
        } // If its an extra, ensure its allowed to be mapped up (bundle or individual selection)
        // Never removed fixed selections, only non fixed price data types.


        if (order_line.get('is_extra')) {
          // If its a bundle, ensure the bundle is not fixed before we check to remove
          if (order_line.get('is_bundle')) {
            if (!order_line.get('price_data.is_fixed')) {
              // Its a bundle and not fixed, if its not allowed in configuration_extras, remove bundle from extras
              let mappings = yield configuration_extras.get('mappings');
              let found = mappings.any(mapping => {
                if (mapping.get('child.id') === order_line.get('product_bundle.id')) {
                  if (!mapping.get('enabled') && order_line.get('isNew')) {
                    return false;
                  }

                  return true;
                }

                return false;
              });

              if (!found) {
                yield this.get('removeOrderLine').perform(order_line);
              }
            }
          } else {
            // Its not a bundle, so we need to check the order selection. Ensure the order selection isn't fixed
            let order_selection = yield order_line.get('selection');

            if (!order_selection.get('price_data.is_fixed')) {
              yield this.get('removeOrderSelections').perform(configuration_extras, order_line, {
                remove_empty_order_lines: true,
                allow_empty_product: true
              });
            }
          }
        }
      }
    }),
    // Enqueued so we ensure only generate one at a time for sort order
    generateOrderLine: (0, _emberConcurrency.task)(function* (order, type, product_bundle) {
      order = yield order;

      if (Ember.isBlank(order)) {
        throw new Error("Order can't be blank");
      }

      if (Ember.isBlank(type)) {
        throw new Error("Type can't be blank");
      }

      let lines = yield order.get('lines');
      let ids = lines.filterBy('isDeleted', false).filterBy('line_type', type).mapBy('sort_order').compact();
      ids.push(0);
      let sort_order = Math.max(...ids) + 1;
      let project = yield order.get('project');

      if (Ember.isPresent(product_bundle)) {
        product_bundle = yield product_bundle;
      }

      let order_line = this.get('store').createRecord('order-line', {
        project: project,
        order: order,
        line_type: type,
        product_bundle: product_bundle,
        sort_order: sort_order
      });
      order.get('lines').pushObject(order_line);
      return order_line;
    }).enqueue(),
    createGeneratedLines: (0, _emberConcurrency.task)(function* (order) {
      order = yield order;
      yield this.get('generateOrderCapLine').perform(order);
      yield this.get('generateOrderLocalPickupLine').perform(order);
      yield this.get('generateOrderShippingWeightLine').perform(order);
      yield this.get('generateOrderTaxLine').perform(order, 'sales_tax');
      yield this.get('generateOrderTaxLine').perform(order, 'vat');
      let project = yield order.get('project'); // VAT Categories

      if ((0, _canAccessFeature.canAccessFeature)([project, 'vat-categories'])) {
        let categories = yield order.get('project.vat_categories');

        for (let category of categories.toArray()) {
          yield this.get('generateOrderTaxLine').perform(order, 'vat', category);
        }
      } // VAT on Shipping


      if ((0, _canAccessFeature.canAccessFeature)([project, 'vat-charge-on-shipping'])) {
        yield this.get('generateOrderTaxLine').perform(order, 'vat', shippingVATCategory);
      }

      yield this.get('generateOrderTaxLine').perform(order, 'customs');
    }),
    generateOrderCapLine: (0, _emberConcurrency.task)(function* (order) {
      order = yield order; // Only generate an order cap line if order isn't completed or canceled

      if (order.get('is_completed') || order.get('is_canceled')) {
        // Don't generate, we'll have a backend generated line if one exists
        return;
      }

      let project = yield order.get('project');

      if (project.get('shipping_is_price_based') === false) {
        return;
      }

      let order_lines = yield order.get('lines');

      if (order_lines.any(order_line => {
        return order_line.get('is_shipping') && order_line.get('price_data.is_generated') && order_line.get('price_data.settings.symbol') === 'extra_shipping_cap' && order_line.get('price_data.live') === true;
      })) {
        // Don't re-generate one
        return;
      } // If we change how extras work, and we could only generate a cap line if extras has caps


      let cap_line = yield this.get('generateOrderLine').perform(order, 'shipping'); // Now that we have an instance, manually override specific properties

      cap_line.setProperties({
        price_data: this.get('store').createFragment('price-data', {
          type: 'generated',
          settings: this.get('store').createFragment('price-data-settings', {
            symbol: 'extra_shipping_cap',
            calculation_type: 'discount'
          }),
          live: true
        })
      });
      Ember.defineProperty(cap_line, 'live_product_amount_cents', Ember.computed(function () {
        return 0;
      }));
      Ember.defineProperty(cap_line, 'live_additional_amount_cents', Ember.computed(function () {
        return 0;
      }));
      Ember.defineProperty(cap_line, 'live_shipping_amount_cents', Ember.computed('order.content', 'order.extra_lines_to_cap.@each.live_shipping_amount_cents', 'order.shipping_address.{country,state}', 'order.current_configuration.product_extra.extra_cap_zone_locations.@each.{country,state,shipping_cents}', function () {
        let product_extra = this.get('order.current_configuration.product_extra');

        if (Ember.isBlank(product_extra) || Ember.isBlank(product_extra.get('id'))) {
          return 0;
        }

        let order = this.get('order.content') || this.get('order');
        let country = order.get('shipping_address.country');
        let state = order.get('shipping_address.state');
        let extra_cap_zone_location = (0, _projectTotal.find_zone_location)(product_extra, 'extra_cap_zone_locations', country, state);

        if (Ember.isBlank(extra_cap_zone_location)) {
          return 0;
        }

        let shipping_cap_cents = extra_cap_zone_location.get('shipping_cents') || 0;
        let extra_shipping_total_cents = order.sum_lines('live_shipping_amount_cents', 'extra_lines_to_cap');

        if (shipping_cap_cents < extra_shipping_total_cents) {
          return shipping_cap_cents - extra_shipping_total_cents;
        }

        return 0;
      }));
      return cap_line;
    }),
    generateOrderLocalPickupLine: (0, _emberConcurrency.task)(function* (order) {
      order = yield order; // Only generate an order pickup line if order isn't completed or canceled

      if (order.get('is_completed') || order.get('is_canceled')) {
        // Don't generate, we'll have a backend generated line if one exists
        return;
      }

      let project = yield order.get('project');
      let addresses = yield project.get('addresses');

      if (addresses.get('length') === 0) {
        return;
      }

      let order_lines = yield order.get('lines');

      if (order_lines.any(order_line => {
        return order_line.get('is_shipping') && order_line.get('price_data.is_generated') && order_line.get('price_data.settings.symbol') === 'shipping_local_pickup' && order_line.get('price_data.live') === true;
      })) {
        // Don't re-generate one
        return;
      }

      let local_pickup_line = yield this.get('generateOrderLine').perform(order, 'shipping'); // Now that we have an instance, manually override specific properties

      local_pickup_line.setProperties({
        price_data: this.get('store').createFragment('price-data', {
          type: 'generated',
          settings: this.get('store').createFragment('price-data-settings', {
            symbol: 'shipping_local_pickup',
            calculation_type: 'discount'
          }),
          live: true
        })
      });
      Ember.defineProperty(local_pickup_line, 'live_product_amount_cents', Ember.computed(function () {
        return 0;
      }));
      Ember.defineProperty(local_pickup_line, 'live_additional_amount_cents', Ember.computed(function () {
        return 0;
      }));
      Ember.defineProperty(local_pickup_line, 'live_shipping_amount_cents', Ember.computed('order.content', 'order.lines_for_local_pickup.@each.live_shipping_amount_cents', function () {
        let order = this.get('order.content') || this.get('order');
        let shipping_total_cents = order.sum_lines('live_shipping_amount_cents', 'lines_for_local_pickup');

        if (shipping_total_cents > 0) {
          return shipping_total_cents * -1;
        }

        return 0;
      }));
      return local_pickup_line;
    }),
    generateOrderShippingWeightLine: (0, _emberConcurrency.task)(function* (order) {
      order = yield order; // Only generate an order shipping weight line if order isn't completed or canceled

      if (order.get('is_completed') || order.get('is_canceled')) {
        // Don't generate, we'll have a backend generated line if one exists
        return;
      }

      let project = yield order.get('project');

      if (project.get('shipping_is_weight_based') === false) {
        return;
      }

      let order_lines = yield order.get('lines');

      if (order_lines.any(order_line => {
        return order_line.get('is_shipping') && order_line.get('price_data.is_generated') && order_line.get('price_data.settings.symbol') === 'shipping_weight_based' && order_line.get('price_data.live') === true;
      })) {
        // Don't re-generate one
        return;
      }

      let shipping_weight_line = yield this.get('generateOrderLine').perform(order, 'shipping'); // Now that we have an instance, manually override specific properties

      shipping_weight_line.setProperties({
        // standard
        price_data: this.get('store').createFragment('price-data', {
          type: 'generated',
          settings: this.get('store').createFragment('price-data-settings', {
            symbol: 'shipping_weight_based',
            calculation_type: 'cost'
          }),
          live: true
        }),

        get_shipping_price(zone_location, total_weight) {
          let prices = zone_location.get('shipping.prices'); // First try to find a price thats exactly in the range

          return prices.find(price => {
            return total_weight >= price.get('starting_weight') && total_weight <= price.get('ending_weight_with_max');
          });
        }

      }); // custom properties

      Ember.defineProperty(shipping_weight_line, 'live_zone_location', Ember.computed('order.content', 'project.shipping_weight_based_zone_locations.@each.{country,state,percent}', 'order.shipping_address.{state,country}', function () {
        let order = this.get('order.content') || this.get('order'); // We've eliminated all options, now calculate based on product

        let country = order.get('shipping_address.country');
        let state = order.get('shipping_address.state');
        return (0, _projectTotal.find_zone_location)(this.get('project'), `shipping_weight_based_zone_locations`, country, state);
      }));
      Ember.defineProperty(shipping_weight_line, 'live_total_weight', Ember.computed('order.content', 'order.lines_for_weight_based_shipping.@each.product_total_weight', function () {
        let order = this.get('order.content') || this.get('order');
        return order.sum_lines('product_total_weight', 'lines_for_weight_based_shipping');
      }));
      Ember.defineProperty(shipping_weight_line, 'live_custom_name', Ember.computed('live_zone_location', 'live_total_weight', 'project.unit_of_mass', function () {
        let zone_location = this.get('live_zone_location');

        if (Ember.isBlank(zone_location)) {
          return "";
        }

        let price = this.get_shipping_price(zone_location, this.get('live_total_weight'));

        if (Ember.isBlank(price)) {
          return "";
        }

        if (Ember.isBlank(price.get('ending_weight'))) {
          return `Total Cost by Weight (${price.get('starting_weight')} ${this.get('project.unit_of_mass')}s or more)`;
        } else {
          return `Total Cost by Weight (${price.get('starting_weight')} - ${price.get('ending_weight')} ${this.get('project.unit_of_mass')}s)`;
        }
      }));
      Ember.defineProperty(shipping_weight_line, 'live_product_amount_cents', Ember.computed(function () {
        return 0;
      }));
      Ember.defineProperty(shipping_weight_line, 'live_additional_amount_cents', Ember.computed(function () {
        return 0;
      }));
      Ember.defineProperty(shipping_weight_line, 'live_shipping_amount_cents', Ember.computed('live_zone_location', 'live_total_weight', function () {
        let zone_location = this.get('live_zone_location');

        if (Ember.isBlank(zone_location)) {
          return 0;
        } // We've eliminated all options, now calculate based on product


        let price = this.get_shipping_price(zone_location, this.get('live_total_weight'));
        let amount_cents = 0;

        if (Ember.isPresent(price)) {
          amount_cents = price.get('amount_cents') || 0;
        }

        return amount_cents;
      }));
      return shipping_weight_line;
    }),
    generateOrderTaxLine: (0, _emberConcurrency.task)(function* (order, tax_type) {
      let category = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : defaultVATCategory;
      order = yield order; // Only generate an order shipping weight line if order isn't completed or canceled

      if (order.get('is_completed') || order.get('is_canceled')) {
        // Don't generate, we'll have a backend generated line if one exists
        return;
      }

      let project = yield order.get('project'); // load all rates

      yield project.get('zone_locations');
      let tax_zone_locations = project.get(`${tax_type}_zone_locations`);

      if (tax_zone_locations.get('length') === 0) {
        return;
      }

      let order_lines = yield order.get('lines');

      if (order_lines.any(order_line => {
        return order_line.get('is_tax') && order_line.get('price_data.is_generated') && order_line.get('price_data.settings.symbol') === tax_type && (category.get('public_id') === "default" || order_line.get('price_data.settings.category.public_id') === category.get('public_id')) && order_line.get('price_data.live') === true;
      })) {
        // Don't re-generate one
        return;
      }

      let tax_line = yield this.get('generateOrderLine').perform(order, 'tax'); // Now that we have an instance, manually override specific properties

      tax_line.setProperties({
        // Standard
        price_data: this.get('store').createFragment('price-data', {
          type: 'generated',
          settings: this.get('store').createFragment('price-data-settings', {
            category: {
              public_id: category.get('public_id'),
              name: category.get('name')
            },
            symbol: tax_type,
            calculation_type: 'cost'
          }),
          live: true
        }),

        get_percent_number(zone_location) {
          let for_category = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "default";
          let percent;

          if (for_category === "default") {
            percent = zone_location.get('percent') || 0;
          } else if (for_category === "shipping") {
            percent = zone_location.get(`zone.vat.prices.${for_category}`) || zone_location.get('percent') || 0;
          } else {
            percent = zone_location.get(`zone.vat.prices.${for_category}`) || 0;
          }

          let percent_number = (0, _big.default)(percent || 0);
          return percent_number.div(10000);
        }

      }); // custom properties

      Ember.defineProperty(tax_line, 'live_zone_location', Ember.computed('order.content', `project.${tax_type}_zone_locations.@each.{country,state,percent}`, 'order.shipping_address.{state,country}', function () {
        let order = this.get('order.content') || this.get('order'); // We've eliminated all options, now calculate based on product

        let country = order.get('shipping_address.country');
        let state = order.get('shipping_address.state');
        return (0, _projectTotal.find_zone_location)(this.get('project'), `${tax_type}_zone_locations`, country, state);
      }));
      Ember.defineProperty(tax_line, 'live_custom_name', Ember.computed('intl.locale', 'live_zone_location', function () {
        let zone_location = this.get('live_zone_location');

        if (Ember.isBlank(zone_location)) {
          return "";
        }

        let category = tax_line.get('price_data.settings.category.public_id') || 'default';
        let percent_number = this.get_percent_number(zone_location, category); // intl service is on the order line, not on orderTasks

        let percent_text = this.get('intl').formatNumber(percent_number, {
          style: 'percent',
          minimumFractionDigits: 2
        });

        if (tax_type === 'sales_tax') {
          return `${project.get('t.checkout.summary.sales_tax') || this.get('intl').lookup(`purpose.${project.get('purpose')}.stage.checkout.summary.sales_tax`)} at ${percent_text}`;
        } else if (tax_type === 'vat') {
          return `${project.get('t.checkout.summary.value_added_tax') || this.get('intl').lookup(`purpose.${project.get('purpose')}.stage.checkout.summary.value_added_tax`)}${category === "default" ? "" : " for " + tax_line.get('price_data.settings.category.name')} at ${percent_text}`;
        } else if (tax_type === 'customs') {
          return `${project.get('t.checkout.summary.customs_duty') || this.get('intl').lookup(`purpose.${project.get('purpose')}.stage.checkout.summary.customs_duty`)} at ${percent_text}`;
        }

        return "";
      }));
      Ember.defineProperty(tax_line, 'live_product_amount_cents', Ember.computed(function () {
        return 0;
      }));
      Ember.defineProperty(tax_line, 'live_additional_amount_cents', Ember.computed(function () {
        return 0;
      }));
      Ember.defineProperty(tax_line, 'live_shipping_amount_cents', Ember.computed(function () {
        return 0;
      }));
      Ember.defineProperty(tax_line, 'live_tax_amount_cents', Ember.computed('order.content', 'live_zone_location', 'order.{prices_product_amount_cents,prices_additional_amount_cents,prices_product_vat_amount_cents}', function () {
        let zone_location = this.get('live_zone_location');

        if (Ember.isBlank(zone_location)) {
          return 0;
        }

        let order = this.get('order.content') || this.get('order');
        let category = tax_line.get('price_data.settings.category.public_id') || 'default';
        let total_cost;

        if (category === "default") {
          total_cost = (0, _big.default)(order.get('prices.product.amount_cents') + order.get('prices.additional.amount_cents') - order.get('prices.product_vat.amount_cents'));
        } else if (category === "shipping") {
          total_cost = (0, _big.default)(order.get('prices.shipping.amount_cents'));
        } else {
          total_cost = (0, _big.default)(order.get('prices.product_vat_category').call(order, category));
        }

        let amount_cents = 0; // Now find weight based on zone_location shipping

        let percent_number = this.get_percent_number(zone_location, category);

        if (percent_number > 0) {
          total_cost = total_cost.times(percent_number);
          amount_cents = parseInt(total_cost.toFixed(0));
        }

        return amount_cents;
      }));
      return tax_line;
    }),
    // Enqueued so we ensure only generate one at a time for sort order
    generateOrderSelection: (0, _emberConcurrency.task)(function* (order_line, product, quantity, product_variation, product_option, product_option_mapping) {
      order_line = yield order_line;

      if (Ember.isBlank(order_line)) {
        throw new Error("Order Line can't be blank");
      }

      let project = yield order_line.get('project');
      let order = yield order_line.get('order');

      if (Ember.isPresent(product)) {
        product = yield product;
      }

      if (Ember.isPresent(product_variation)) {
        product_variation = yield product_variation;
      }

      if (Ember.isPresent(product_option)) {
        product_option = yield product_option;
      }

      if (Ember.isPresent(product_option_mapping)) {
        product_option_mapping = yield product_option_mapping;
      }

      let order_selection = this.get('store').createRecord('order-selection', {
        project: project,
        order: order,
        line: order_line,
        product: product,
        quantity: quantity,
        // Optional params
        product_variation: product_variation,
        product_option: product_option,
        product_option_mapping: product_option_mapping
      });
      order_line.get('selections').pushObject(order_selection);
      return order_selection;
    }).enqueue(),
    generateOrderSelectionQuestion: (0, _emberConcurrency.task)(function* (order_selection, product, product_question) {
      order_selection = yield order_selection;
      product = yield product;
      product_question = yield product_question;

      if (Ember.isBlank(order_selection)) {
        throw new Error("Order Selection can't be blank");
      }

      if (Ember.isBlank(product)) {
        throw new Error("Product can't be blank");
      }

      if (Ember.isBlank(product_question)) {
        throw new Error("Product Question can't be blank");
      }

      let project = yield order_selection.get('project');
      let order = yield order_selection.get('order');
      let order_selection_question = this.get('store').createRecord('order-selection-question', {
        project: project,
        order: order,
        product: product,
        product_question: product_question,
        selection: order_selection
      });
      order_selection.get('questions').pushObject(order_selection_question);

      if (product_question.get('is_text')) {// nothing else to do
      } else if (product_question.get('is_multiple')) {
        if (product_question.get('is_multiple_single')) {
          order_selection_question.set('quantity', 1);
        } else {
          // when we hit this, probably need to add / remove question choices based on the ui, not in here
          throw new Error("Select multiple not single needs to be implemented");
        }
      } else {
        throw new Error(`Question Type ${product_question.get('question_type')} is not supported at the moment`);
      }
    }),
    generateOrderShippingAddress: (0, _emberConcurrency.task)(function* (order, country) {
      order = yield order;

      if (Ember.isBlank(order)) {
        throw new Error("Order can't be blank");
      }

      let project = yield order.get('project');

      if (Ember.isPresent(country)) {
        country = yield country;
      }

      let shipping_address = this.get('store').createRecord('order-address', {
        project: project,
        order: order,
        country: country
      });
      order.get('addresses').pushObject(shipping_address);
      return shipping_address;
    }),
    // This creates / modifies all selections for an order line
    // ensures that the selections under a order line matches the product bundle
    updateOrderSelections: (0, _emberConcurrency.task)(function* (product_with_mappings, order_line) {
      product_with_mappings = yield product_with_mappings;
      order_line = yield order_line;

      if (Ember.isBlank(product_with_mappings)) {
        throw new Error("Product with Mappings can't be blank");
      }

      if (Ember.isBlank(order_line)) {
        throw new Error("Order Line can't be blank");
      } // pass in true and remove rewards that don't match current configuration


      yield this.get('removeOrderSelections').perform(product_with_mappings, order_line, {
        check_quantity: true
      });
      let product_mappings = yield product_with_mappings.get('mappings');
      let order_selections = yield order_line.get('selections');
      let creations_or_updates = Ember.A();

      for (let product_mapping of product_mappings.toArray()) {
        // Don't create selections that aren't enabled
        // Leave any that were previously created and not removed on removed selections above
        if (!product_mapping.get('enabled')) {
          continue;
        } // Setting defaults here, so if we need to, we can override it in the found selections


        let other_quantity = 0;
        let required_quantity = product_mapping.get('required_quantity');
        let quantity = required_quantity;
        let product_child = yield product_mapping.get('child'); // Don't use reward selections actual, need to compare everything
        // Quantity is checked above on remove Selections for quantity over the allowed, we only need to check on under quantity

        let found_selections = order_selections.filterBy('isDeleted', false).filter((0, _productMappingOrderSelectionFilter.default)(product_mapping)).toArray();

        if (found_selections.length > 0) {
          for (let order_selection of found_selections) {
            creations_or_updates.push(this.get('updateOrderSelection').perform(order_selection));
          }

          let current_quantity = found_selections.reduce(function (value, order_selection) {
            return value + order_selection.get('quantity');
          }, 0); // If the quantity matches 100%, then we continue, otherwise, we need to add the missing items
          // This is only valid on a less than case, not when we have more than we should

          if (current_quantity === required_quantity) {
            continue;
          } // We override quantity here to be remainder, follow the rest of the logic as it should be


          quantity = required_quantity - current_quantity;
        } // If we have a product with choices, create a quantity of one and split the rest into an unknown category


        if (quantity > 1 && product_child.get('has_choices')) {
          other_quantity = quantity - 1;
          quantity = 1;
        }

        creations_or_updates.push(this.get('createOrderSelection').perform(product_mapping, order_line, quantity));

        if (other_quantity > 0) {
          creations_or_updates.push(this.get('createOrderSelection').perform(product_mapping, order_line, other_quantity, {
            abstract: true
          }));
        }
      }

      yield (0, _emberConcurrency.all)(creations_or_updates);
    }),
    updateOrderSelection: (0, _emberConcurrency.task)(function* (order_selection) {
      // Ensure preliminary variation is set
      order_selection = yield order_selection;
      let product = yield order_selection.get('product');
      let product_variation = yield order_selection.get('product_variation');

      if (product_variation != null) {
        let product_variation_product = yield product_variation.get('product'); // If the product doesn't match the variation, reset it

        if (product.get('id') !== product_variation_product.get('id')) {
          order_selection.set('product_variation', null);
          product_variation = null;
        }
      }

      if (product_variation == null && product != null) {
        let preliminary_variation = yield product.get('preliminary_variation');

        if (preliminary_variation != null) {
          order_selection.set('product_variation', preliminary_variation);
        }
      }

      yield this.get('updateOrderSelectionQuestions').perform(order_selection);
    }),
    updateOrderSelectionQuestions: (0, _emberConcurrency.task)(function* (order_selection) {
      order_selection = yield order_selection;

      if (Ember.isBlank(order_selection)) {
        throw new Error("Order Selection can't be blank");
      }

      let product = yield order_selection.get('product');
      let order_selection_questions = yield order_selection.get('questions'); // 1. If we don't have a product, remove all questions

      if (Ember.isBlank(product)) {
        for (let order_selection_question of order_selection_questions.filterBy('isDeleted', false).toArray()) {
          order_selection_question.deleteRecord();
        }

        return;
      } // We have a product, ensure questions match product


      let product_questions = yield product.get('questions_sorted'); // 2. Remove any order selections questions that don't match product. The order selection product changed

      for (let order_selection_question of order_selection_questions.filterBy('isDeleted', false).toArray()) {
        let found = false;

        for (let product_question of product_questions.toArray()) {
          if (order_selection_question.get('product.id') === product.get('id') && order_selection_question.get('product_question.id') === product_question.get('id')) {
            found = true;
            break;
          }
        }

        if (!found) {
          order_selection_question.deleteRecord();
        }
      }

      let add_question_quantity = function (grouped_question, order_selection_question) {
        if (order_selection_question.get('product_question.is_text')) {
          grouped_question.quantity += 1;
        } else {
          grouped_question.quantity += order_selection_question.get('quantity') || 0;
        }
      }; // 3. Now group the order selection questions by product and question id, we need to check quantities


      let grouped_questions = {};

      for (let order_selection_question of order_selection_questions.filterBy('isDeleted', false).toArray()) {
        let product_question_key = order_selection_question.get('product.id') + '-' + order_selection_question.get('product_question.id');

        if ((0, _hasOwnProperty.default)(grouped_questions, product_question_key)) {
          // this should only be for bundles
          grouped_questions[product_question_key].questions.push(order_selection_question); // Quantity only exists for multiple choice, so default text to 1

          add_question_quantity(grouped_questions[product_question_key], order_selection_question);
        } else {
          grouped_questions[product_question_key] = {
            product_id: order_selection_question.get('product.id'),
            product_question_id: order_selection_question.get('product_question.id'),
            questions: Ember.A([order_selection_question]),
            quantity: 0
          };
          add_question_quantity(grouped_questions[product_question_key], order_selection_question);
        }
      } // Function to avoid js hint and saved loop variables


      let question_exists = function (question_group) {
        return function (product_question) {
          if (product_question.get('product.id') === question_group.product_id && product_question.get('id') === question_group.product_question_id) {
            // If the question matches the product, check quantities
            if (product_question.get('is_text')) {
              if (question_group.quantity === 1) {
                return true;
              }

              return false;
            } else {
              if (product_question.get('quantity') === question_group.quantity) {
                return true;
              }

              return false;
            }
          }

          return false;
        };
      }; // 4. Remove any questions that don't match the quantity


      for (let key in grouped_questions) {
        let question_group = grouped_questions[key];
        let found = product_questions.any(question_exists(question_group));

        if (!found) {
          for (let order_selection_question of question_group.questions) {
            order_selection_question.deleteRecord();
          }
        }
      } // 5. Now that all that all the invalid questions are removed, add any missing


      let new_questions = Ember.A(); // Add any missing questions now

      for (let product_question of product_questions.toArray()) {
        let found = false;

        for (let order_selection_question of order_selection_questions.filterBy('isDeleted', false).toArray()) {
          if (order_selection_question.get('product.id') === product.get('id') && order_selection_question.get('product_question.id') === product_question.get('id')) {
            found = true;
            break;
          }
        }

        if (!found) {
          new_questions.push(this.get('generateOrderSelectionQuestion').perform(order_selection, product, product_question));
        }
      }

      yield (0, _emberConcurrency.all)(new_questions);
    }),
    // This creates / modifies one selection for an order line.
    // ensures that an order line exists for the product_mapping passed in
    createOrderSelection: (0, _emberConcurrency.task)(function* (product_mapping, order_line, quantity, options) {
      if (Ember.isBlank(options)) {
        options = {};
      }

      if (Ember.isBlank(options.abstract)) {
        options.abstract = false;
      }

      product_mapping = yield product_mapping;
      order_line = yield order_line;

      if (Ember.isBlank(product_mapping)) {
        throw new Error("Product Mapping can't be blank");
      }

      if (Ember.isBlank(order_line)) {
        throw new Error("Order Line can't be blank");
      }

      if (Ember.isBlank(quantity)) {
        throw new Error("Quantity can't be blank");
      }

      let product_child = yield product_mapping.get('child');
      let product = null;
      let product_variation = null;
      let product_option = null;
      let product_option_mapping = null;

      if (product_child.get('is_option')) {
        product_option = product_child; // If it exists, unwrap it

        if (Ember.isPresent(options.product_option_mapping)) {
          product_option_mapping = yield options.product_option_mapping;
        } // If we have a product_option_mapping, setup the variables


        if (Ember.isPresent(product_option_mapping)) {
          product = yield product_option_mapping.get('child');
          product_variation = yield product.get('preliminary_variation');
        }
      } else {
        product = yield product_mapping.get('child');
        product_variation = yield product.get('preliminary_variation');
      }

      let order_selection = yield this.get('generateOrderSelection').perform(order_line, product, quantity, product_variation, product_option, product_option_mapping);

      if (options.abstract) {
        order_selection.set('is_abstract', options.abstract);
      }

      yield this.get('updateOrderSelectionQuestions').perform(order_selection);
      return order_selection;
    }),
    removeOrderSelections: (0, _emberConcurrency.task)(function* (product_with_mappings, order_line, options) {
      if (Ember.isBlank(options)) {
        options = {};
      } // Set defaults


      if (Ember.isBlank(options.check_quantity)) {
        options.check_quantity = false;
      }

      if (Ember.isBlank(options.remove_empty_order_lines)) {
        options.remove_empty_order_lines = false;
      }

      if (Ember.isBlank(options.allow_empty_product)) {
        options.allow_empty_product = false;
      } // Future handle this
      // if (Ember.isBlank(options.remove_new_order_selections)) {
      //   options.remove_new_order_selections = false;
      // }


      product_with_mappings = yield product_with_mappings;
      order_line = yield order_line;

      if (Ember.isBlank(product_with_mappings) && !options.allow_empty_product) {
        throw new Error("Product with Mappings can't be blank");
      }

      if (Ember.isBlank(order_line)) {
        throw new Error("Order Line can't be blank");
      }

      if (Ember.isBlank(product_with_mappings)) {
        product_with_mappings = Ember.Object.create({
          mappings: Ember.A()
        });
      } // Sum up the selections into this hash which contains
      // one entry per reward/addon. This is setup so we can
      // compare quantities with total quantity below


      let order_selections = yield order_line.get('selections');

      if (order_selections.get('length') === 0) {
        if (options.remove_empty_order_lines) {
          order_line.deleteRecord();
        }

        return;
      } // Remove any order selections with null products


      let delete_empty_tasks = Ember.A();

      for (let order_selection of order_selections.filterBy('isDeleted', false).toArray()) {
        if (Ember.isBlank(order_selection.get('product.id')) || order_selection.get('is_abstract')) {
          delete_empty_tasks.push(this.get('removeOrderSelection').perform(order_selection, options.remove_empty_order_lines));
        }
      }

      yield (0, _emberConcurrency.all)(delete_empty_tasks); // Now group selections up

      let grouped_selections = {};

      for (let order_selection of order_selections.filterBy('isDeleted', false).toArray()) {
        // If its a bundle we want the key to be on option,
        // if its not a bundle, use product
        let product_key = order_selection.get('product_option.id') || order_selection.get('product.id');

        if ((0, _hasOwnProperty.default)(grouped_selections, product_key)) {
          // this should only be for bundles
          grouped_selections[product_key].selections.push(order_selection);
          grouped_selections[product_key].quantity += order_selection.get('quantity') || 0;
        } else {
          grouped_selections[product_key] = {
            product_option: null,
            product: null,
            selections: Ember.A([order_selection]),
            quantity: order_selection.get('quantity') || 0
          }; // We need to fake out the object to have the mappingOrderSelectionFilter Work

          if (Ember.isPresent(order_selection.get('product_option.id'))) {
            grouped_selections[product_key].product_option = {
              id: order_selection.get('product_option.id')
            };
          } else {
            grouped_selections[product_key].product = {
              id: order_selection.get('product.id')
            };
          }
        }
      }

      let mappings = yield product_with_mappings.get('mappings');
      let to_delete = Ember.A(); // Function to avoid js hint and saved loop variables

      let mapping_exists = function (selection_group, check_quantity) {
        return function (mapping) {
          if ((0, _productMappingOrderSelectionFilter.default)(mapping)(Ember.Object.create(selection_group))) {
            // If the mapping isn't enabled and any of the grouped_selections are new, remove to be safe
            if (!mapping.get('enabled') && selection_group.selections.isAny('isNew', true)) {
              return false;
            }

            if (check_quantity) {
              return selection_group.quantity <= mapping.get('required_quantity');
            }

            return true;
          } // If the child id doesn't match the selection, it wasn't found in the method


          return false;
        };
      };

      for (let product_key in grouped_selections) {
        let selection_group = grouped_selections[product_key];
        let found = mappings.any(mapping_exists(selection_group, options.check_quantity));

        if (!found) {
          to_delete.push(...selection_group.selections);
        }
      }

      let delete_tasks = Ember.A();

      for (let order_selection of to_delete) {
        delete_tasks.push(this.get('removeOrderSelection').perform(order_selection, options.remove_empty_order_lines));
      }

      yield (0, _emberConcurrency.all)(delete_tasks);
    }),
    removeOrderLine: (0, _emberConcurrency.task)(function* (order_line) {
      order_line = yield order_line;

      if (Ember.isBlank(order_line)) {
        throw new Error("Order line can't be blank");
      }

      let delete_empty_tasks = Ember.A();
      let order_selections = yield order_line.get('selections');

      for (let order_selection of order_selections.filterBy('isDeleted', false).toArray()) {
        delete_empty_tasks.push(this.get('removeOrderSelection').perform(order_selection, false));
      }

      yield (0, _emberConcurrency.all)(delete_empty_tasks);
      order_line.deleteRecord();
    }),
    removeOrderSelection: (0, _emberConcurrency.task)(function* (order_selection, remove_empty_order_lines) {
      order_selection = yield order_selection;

      if (Ember.isBlank(order_selection)) {
        throw new Error("Order Selection can't be blank");
      }

      let selection_questions = yield order_selection.get('questions');

      for (let selection_question of selection_questions.filterBy('isDeleted', false).toArray()) {
        selection_question.deleteRecord();
      }

      let order_line;

      if (remove_empty_order_lines) {
        order_line = yield order_selection.get('line');
      }

      order_selection.deleteRecord(); // Check lines with no selections now and remove?

      if (remove_empty_order_lines) {
        let selections = yield order_line.get('selections');

        if (selections.filterBy('isDeleted', false).toArray().length === 0) {
          order_line.deleteRecord();
        }
      }
    })
  });

  _exports.default = _default;
});