define("shared/tests/helpers/create-country", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(name, iso3, iso2) {
    return server.create('country', {
      name: name,
      iso3: iso3,
      iso2: iso2
    });
  }
});