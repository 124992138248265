define("shared/components/product-question-text/component", ["exports", "shared/components/product-question-text/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['product-question-text', 'component'],
    layout: _template.default
  });

  _exports.default = _default;
});