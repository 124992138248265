define('ember-prop-types/utils/validators/date', ['exports', 'ember-prop-types/utils/logger'], function (exports, _logger) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (ctx, name, value, def, logErrors, throwErrors) {
    const valid = Ember.typeOf(value) === 'date';

    if (!valid && logErrors) {
      _logger.default.warn(ctx, `Expected property ${name} to be a date but instead got: ${Ember.typeOf(value)}`, throwErrors);
    }

    return valid;
  };
});