define("shared/mixins/zone-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // Alias to have existing properties continue to work
    shipping_cents: Ember.computed.readOnly('zone.shipping_cents'),
    shipping: Ember.computed.readOnly('zone.shipping'),
    sort_order: Ember.computed('country.id', 'state.id', function () {
      if (Ember.isPresent(this.get('country.id')) && Ember.isPresent(this.get('state.id'))) {
        // State Level Targeting
        return 0;
      }

      if (Ember.isPresent(this.get('country.id'))) {
        // Country Level Targeting
        return 1;
      } // Everywhere else


      return 10;
    }),
    // Matches location zone type
    is_everywhere_else: Ember.computed('country.id', 'state.id', function () {
      return this.get('country.id') == null && this.get('state.id') == null;
    }),
    is_countries: Ember.computed('country.id', 'state.id', function () {
      return this.get('country.id') != null && this.get('state.id') == null;
    }),
    is_states: Ember.computed('country.id', 'state.id', function () {
      return this.get('country.id') != null && this.get('state.id') != null;
    })
  });

  _exports.default = _default;
});