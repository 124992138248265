define("shared/utils/array-intersect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    var i;
    var shortest;
    var nShortest;
    var n;
    var len;
    var ret = Ember.A();
    var obj = {};
    var nOthers;
    nOthers = arguments.length - 1;
    nShortest = arguments[0].length;
    shortest = 0;
    i = 0;

    while (i <= nOthers) {
      n = arguments[i].length;

      if (n < nShortest) {
        shortest = i;
        nShortest = n;
      }

      i++;
    }

    i = 0;

    while (i <= nOthers) {
      n = i === shortest ? 0 : i || shortest; //Read the shortest array first. Read the first array instead of the shortest

      len = arguments[n].length;
      var j = 0;

      while (j < len) {
        var elem = arguments[n][j];

        if (obj[elem] === i - 1) {
          if (i === nOthers) {
            ret.push(elem);
            obj[elem] = 0;
          } else {
            obj[elem] = i;
          }
        } else if (i === 0) {
          obj[elem] = 0;
        }

        j++;
      }

      i++;
    }

    return ret;
  }
});